<script lang="ts">
  import { curTempText } from "@/hmi";
  import { secondsTicker } from "@/stores";
  import { durationLongString } from "@/utils";
  import { _, locale } from "svelte-i18n";

  //
  export let status: DTO.DefrostStatus;

  $: elapsedString = durationLongString($secondsTicker - status.startTime);

  function phaseString(phase: number) {
    switch (phase) {
      case 1:
        return $_("$$defrost.pre-heating");
      case 2:
        return $_("$$defrost.heat-high");
      case 3:
        return $_("$$defrost.heat-low");
      case 4:
        return $_("$$defrost.drip-time");
      case 5:
        return $_("$$defrost.pre-cooling");
    }
  }
</script>

<section>
  <div class="defrost left">
    <img src="/img/defrost/DefrostBackground.png" width="240" height="240" draggable="false" />
    <img src="/img/defrost/DefrostDrop.webp" width="240" height="240" draggable="false" />
  </div>

  <!-- <div class="defrost right">
    <img src="/img/defrost/DefrostBackground.png" width="240" height="240" draggable="false" />
    <img src="/img/defrost/DefrostDrop.webp" width="240" height="240" draggable="false" />
  </div> -->

  <h1 class="uppercase">{$_("$$defrost.active_defrost")}</h1>
  <br />
  <div class="phase">
    {$_("$$defrost.elapsed_time")}: {elapsedString}
    <br />
    {$_("$$defrost.phase")}
    {status.phase}/5:
    {#key $locale}
      {phaseString(status.phase)}
    {/key}
  </div>

  <div class="cur-temp">{$curTempText}</div>
</section>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  section {
    position: relative;
    font-size: 4rem;
    padding-top: 3rem;
  }

  .defrost {
    position: absolute;
    bottom: 0;
    width: 240px;
    height: 240px;
    scale: 1.5;
    opacity: 0.7;

    &.left {
      left: 60px;
    }

    /*
    &.right {
      transform: scaleX(-1);
      animation-delay: 5s;
      right: 60px;
    }
    */

    > img {
      position: absolute;
    }
  }

  .cur-temp {
    position: absolute;
    opacity: 0.5;
    line-height: 1;
    right: 2rem;
    bottom: 2rem;
    font-size: 2rem;
  }
</style>
