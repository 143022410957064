<script lang="ts">
  import defs from "@/config/equipment/equipment-defs.json";
  import hmi, { client, equipment } from "@/hmi";
  import Led from "@/lib/Led.svelte";
  import { numFmt } from "@/stores";
  import { debounce } from "lodash-es";
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import type { Unsubscriber, Writable } from "svelte/store";
  import { get } from "svelte/store";
  import ConfigDialog from "../ConfigDialog.svelte";

  type Digital = { label?: string; id: string; store?: Writable<number> };

  const mode = hmi.getValueStore("set.MODE");
  const steamAmps = hmi.getValueStore("cur.steampot_amps");

  let config: DTO.EquipmentConfig;
  let outputs: Digital[];
  let outputBits = 0;
  let errorText: string = undefined;

  const clearErrorLater = debounce(() => (errorText = undefined), 2000);

  onMount(() => {
    const unsub: Unsubscriber[] = [];

    client
      .invoke<DTO.EquipmentConfig>("Load", "equipment")
      .then((response) => {
        config = response;
        outputs = buildDigital("DO", unsub, (bit, value) => {
          if (value) outputBits |= 1 << bit;
          else outputBits &= ~(1 << bit);
        });
      })
      .catch((e) => {
        alert(e);
      });

    return () => {
      clearErrorLater.cancel();
      unsub.forEach((x) => x());
    };
  });

  function buildDigital(type: string, unsub: Unsubscriber[], set: (n: number, value: boolean) => void): Digital[] {
    let a: Digital[] = [];
    for (let i = 0; i < 10; i++) {
      const mapping = config.mappings?.find((x) => x.type == type && x.num == i + 1);
      const def = mapping ? defs.find((x) => x.id === mapping.id) : undefined;
      if (def) {
        let e = { label: $_(`defs.${def.id}`), id: def.id } as Digital;
        if (def.hmi_id) {
          const store = def.hmi_id ? hmi.getValueStore(def.hmi_id) : undefined;
          if (!store) {
            console.warn(`No store for ${def.hmi_id}`);
            continue;
          }
          let ic = i;
          unsub.push(
            store.subscribe((value) => {
              set(ic, !!value);
            })
          );
          e.store = store;
        } else {
          e.label = "*" + def.text;
        }
        a.push(e);
      } else {
        a.push({ id: `unused-${type}-${i}` });
      }
    }
    return a;
  }

  function onPointerAction(e: PointerEvent) {
    e.stopPropagation();
    const button = e.target as HTMLButtonElement;
    if (button.tagName !== "BUTTON") return;
    let index = parseInt(button.dataset.index);
    console.assert(!isNaN(index));
    if (e.type === "pointerdown") {
      errorText = "";
      client
        .invoke("SetOutput", index)
        .then(() => {
          outputBits |= 1 << index;
        })
        .catch((e) => {
          errorText = e.message;
        });
    } else {
      clearErrorLater();
      client.invoke("UnsetOutput", index).then(() => {
        let state = false;
        let e = outputs[index];
        if (e.store && get(e.store) === 1) state = true;
        if (state) outputBits |= 1 << index;
        else outputBits &= ~(1 << index);
      });
    }
  }
</script>

<ConfigDialog title={$_("menu.manual_operation")}>
  <section on:pointerdown={onPointerAction} on:pointerup={onPointerAction}>
    {#if config}
      <div class="box">
        <div class="header" style="grid-column: span 2">OUTPUT</div>
        <div class="header pad-l">EQUIPMENT</div>
        <div class="header">SET</div>

        {#each outputs.slice(0, 5) as led, i (led.id)}
          {@const opacity = led.label ? 1 : 0.3}
          <div style:opacity>DO {i + 1}</div>
          <Led on={!!(outputBits & (1 << i))} />
          <div class="pad-l" style:opacity>{led.label || ""}</div>
          <div class="button-group">
            <button data-index={i} disabled={!led.label}>SET</button>
          </div>
        {/each}
      </div>

      <div class="box">
        <div class="header" style="grid-column: span 2">OUTPUT</div>
        <div class="header pad-l">EQUIPMENT</div>
        <div class="header">SET</div>

        {#each outputs.slice(5) as led, i (led.id)}
          {@const opacity = led.label ? 1 : 0.3}
          <div style:opacity>DO {i + 6}</div>
          <Led on={!!(outputBits & (1 << (i + 5)))} />
          <div class="pad-l" style:opacity>{led.label || ""}</div>
          <div class="button-group">
            <button data-index={i + 5} disabled={!led.label}>SET</button>
          </div>
        {/each}
      </div>
      {#if $equipment.includes("CURRENT_MEASUREMENT_COIL")}
        <div class="box analog-in">
          Steampot current: &nbsp;&nbsp;{$numFmt($steamAmps, -2)}A
        </div>
      {/if}
    {/if}

    <!-- {#if $mode !== Modes.OFF}
      <div class="error whole-row">NOTE: Need to be in OFF mode to control outputs!</div>
    {/if} -->
    {#if errorText}
      <div class="error whole-row">{errorText}</div>
    {/if}
  </section>
</ConfigDialog>

<style lang="scss">
  @use "../../styles/variables.scss" as *;

  section {
    max-height: 100%;
    font-family: hmiFont;
    font-size: 2.2rem;
    display: grid;
    padding: 1rem;
    grid-template-columns: 1fr 1fr;
    align-content: start;
    gap: 1.5rem;
  }

  .header {
    margin-bottom: 2rem;
    font-family: hmiFontBold;
  }

  .pad-l {
    padding-left: 0.75rem;
  }

  .box {
    display: grid;
    align-content: start;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 2rem;
    border-radius: 1rem;
    grid-template-columns: auto auto 1fr auto;
    gap: 2rem;
    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  button {
    min-width: 5rem;
    min-height: 4rem;
  }

  .button-group {
    gap: 2rem;
  }

  .error {
    text-align: center;
    font-size: 3rem;
  }
</style>
