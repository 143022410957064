<script lang="ts">
  import { autorepeat } from "@/actions";
</script>

<button use:autorepeat on:click|stopPropagation>
  <slot />
</button>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  button {
    all: initial;
    height: 100%;
    text-align: center;
    width: auto;

    :global(> *) {
      margin: auto;
      pointer-events: none;
      max-height: 70%;
    }

    aspect-ratio: 1;
    background-color: transparent;
    color: $primary-dimmed;
    line-height: 1;
    border-left: 1px solid $primary-dimmed;

    &:global(.active) {
      background-color: $primary-dimmed;
      color: white;
    }
  }
</style>
