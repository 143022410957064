<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { cubicInOut } from "svelte/easing";
  import { fly } from "svelte/transition";

  export let title: string;
  export let position: "left" | "right";

  const showTitlebar = true;
  const dispatch = createEventDispatcher();

  function onClose() {
    dispatch("close");
  }
</script>

<div class="edit-frame {position}" transition:fly={{ duration: 200, easing: cubicInOut, x: position === "left" ? -100 : 100 }}>
  {#if showTitlebar}
    <div class="header">{title}</div>
  {/if}
  <div class="frame-content">
    <slot />
  </div>
  <div class="frame-close" aria-label="Close" on:click={onClose} tabindex="-1">&#x2715</div>
</div>

<style lang="scss">
  @use "../../../styles/variables.scss" as *;

  .edit-frame {
    --clr-input-field-background: rgba(var(--bk-color), 1);

    display: flex;
    flex-direction: column;

    position: fixed;
    top: 0;
    height: 100%;
    background-color: $menu-background;

    isolation: isolate;
    z-index: 1000;

    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }

  .edit-frame.left {
    filter: drop-shadow(0.5rem 0.5rem 1rem rgba(0, 0, 0, 1));

    .frame-content {
      direction: rtl; // Move scrollbar to the left
    }

    .frame-content {
      border-right: 2px solid rgba(255, 255, 255, 0.2);
    }
    .frame-close {
      left: calc(100% - 2px);
      border-left: none;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }
  .edit-frame.right {
    filter: drop-shadow(-0.5rem 0.5rem 1rem rgba(0, 0, 0, 1));

    .frame-content {
      border-left: 2px solid rgba(255, 255, 255, 0.2);
    }
    .frame-close {
      right: calc(100% - 2px);
      border-right: none;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
  }

  .frame-close {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 1.4rem 1.3rem 1.1rem 1.3rem;
    line-height: 1;
    background-color: inherit;
    border: 2px solid rgba(255, 255, 255, 0.2);
    font-size: 2.5rem;
    z-index: 1;
    cursor: pointer;
    &:active {
      color: var(--clr-company);
    }
  }

  .header {
    line-height: 1.6;
    background-color: $company;
    color: black;
    align-items: center;
    font-family: hmiFontBold;
    font-size: 2.2rem;
    padding-top: 0.2rem;
    text-align: center;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .frame-content {
    font-size: 2.4rem;
    flex-grow: 1;
    overflow-y: auto;

    :global(> *) {
      padding: 1rem;
      direction: ltr;
    }

    :global(hr) {
      grid-column: 1 / -1;
    }
  }
</style>
