<script lang="ts">
  import { autorepeat } from "@/actions";
  import { clamp } from "@/utils.js";
  import { createEventDispatcher } from "svelte";

  export let value: number;
  export let min = 0;
  export let max = 100;
  export let show = true;

  let hasBeenShown = false;

  const dispatch = createEventDispatcher();

  $: if (!hasBeenShown && show) hasBeenShown = true;

  function change(d: number) {
    let prev = value;
    value = clamp(value + d, min, max);
    if (value !== prev) dispatch("change", value);
  }
</script>

<div class="incdec" class:show class:hide={!show && hasBeenShown} {...$$restProps} tabindex="-1">
  <div use:autorepeat role="button" tabindex="-1" class="btn left" on:click={() => change(-1)}>-</div>
  <img draggable="false" tabindex="-1" src="/img/numpad.png" on:click={() => dispatch("numpad-click")} />
  <div use:autorepeat role="button" tabindex="-1" class="btn right" on:click={() => change(1)}>+</div>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  $height: 7.5rem;
  $collapsed-width: $height;
  $expanded-width: 28rem;

  @keyframes showing {
    0% {
      transform: scale(0);
      opacity: 0;
      width: $collapsed-width;
      color: $background;
    }
    50% {
      transform: scale(1);
      opacity: 1;
      width: $collapsed-width;
      color: $background;
    }
    100% {
      opacity: 1;
      width: $expanded-width;
      color: $primary;
    }
  }

  @keyframes hiding {
    0% {
      transform: scale(0);
      opacity: 0;
      width: $collapsed-width;
      color: $background;
    }
    50% {
      transform: scale(1);
      opacity: 1;
      width: $collapsed-width;
      color: $background;
    }
    100% {
      opacity: 1;
      width: $expanded-width;
      color: $primary;
    }
  }

  .incdec {
    color: $company;
    height: $height;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 5rem;
    border-radius: calc($height/2);
    padding: 0;
    display: flex;
    justify-content: stretch;
    border: 0.4rem solid $primary-dimmed;
    opacity: 0;
    background-color: transparent;
    box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.5);
    color: $primary;
    pointer-events: none;

    img {
      overflow: hidden;
      pointer-events: all;
      height: 65%;
      align-self: center;
      opacity: 0.5;

      &:active {
        outline: 0.2rem solid rgba(255, 255, 255, 0.3);
      }
    }

    .btn {
      flex-grow: 1;
      display: flex;
      align-items: center;
      color: inherit;
      pointer-events: none;
      padding: 0 2rem;
      overflow: hidden;

      &.left {
        text-align: left;
        border-top-left-radius: calc($height/2);
        border-bottom-left-radius: calc($height/2);
      }

      &.right {
        text-align: right;
        border-top-right-radius: calc($height/2);
        border-bottom-right-radius: calc($height/2);
      }

      &:global(.active.left) {
        background: linear-gradient(-90deg, $background, $btn-active-background);
      }
      &:global(.active.right) {
        background: linear-gradient(90deg, $background, $btn-active-background);
      }
    }

    &.hide {
      animation: hiding var(--animation-duration) ease reverse;
    }
    &.show {
      animation: showing var(--animation-duration) ease forwards;
      .btn {
        pointer-events: all;
      }
    }
  }
</style>
