<script lang="ts">
  import { pad2 } from "@/utils";
  import momentumSlider from "momentum-slider";

  const LINE_HEIGHT = 60;

  export let value: number;
  export let min: number;
  export let max: number;
  export let wrap = false;
  export let changing = false;

  let lastMin: number;
  let lastMax: number;
  let lastValue: number = value;

  let el: HTMLDivElement;
  let ms: momentumSlider;

  $: if (el && (min !== lastMin || max !== lastMax)) {
    lastMin = min;
    lastMax = max;

    el.innerHTML = "";
    ms = new momentumSlider({
      el: el,
      vertical: true,
      range: [min, max],
      loop: wrap ? 2 : 0,
      style: {
        // Styles to interpolate as we move the slider
        // The first value corresponds to the adjacent elements
        // The second value corresponds to the current element
        // transform: [{ scale: [0.7, 1] }],
        opacity: [0.2, 1],
      },
      currentIndex: value - min,
      rangeContent: (v: number) => {
        if (v < 100) return pad2(v);
        else return v;
      },
      change: (i: number) => {
        if (i < 0 || i > max - min) return;
        value = i + min;
      },
    });
  }

  $: if (ms && !changing && value !== lastValue) {
    if (wrap && lastValue === max && value === min) ms.next();
    else ms.select(value - min);
    lastValue = value;
  }
</script>

<div class="wheel" style:--line-height="{LINE_HEIGHT}px" tabindex="-1">
  <div class="clip" on:pointerdown={() => (changing = true)}>
    <div bind:this={el}></div>
    <div class="framing" />
  </div>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .wheel {
    overflow: hidden;
    font-family: hmiFontBold;
    font-size: 3.2rem;
    line-height: var(--line-height);

    .clip {
      position: relative;
      height: calc(var(--line-height) * 3);

      .framing {
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: var(--line-height);
        top: var(--line-height);
        border-block: 3px solid $company;
      }
    }
  }

  :global(.ms-slide) {
    transform: translateY(var(--line-height));
  }
</style>
