<script lang="ts">
  import { _ } from "svelte-i18n";

  import { config, DEFAULT_DEBOUNCED_SAVE_MS, mutateConfig } from "@/hmi";

  import CheckBox from "@/lib/CheckBox.svelte";
  import { debounce } from "lodash-es";
  import { onDestroy } from "svelte";
  import ConfigDialog from "./ConfigDialog.svelte";

  let auto = false;

  let storage_k = false;
  let storage_ck = false;
  let storage_fbuv = false;
  let storage_tk = false;
  let storage_dc = false;
  let storage_kf = false;
  let storage_sf = false;
  let storage_hdc = false;

  let proofing_r = false;
  let proofing_rc = false;
  let proofing_rno = false;

  if ($config?.system?.accessiblePrograms) {
    let ap = $config.system.accessiblePrograms;
    auto = ap.includes("AUTO");
    proofing_r = ap.includes("PROOFING_R");
    proofing_rc = ap.includes("PROOFING_RC");
    proofing_rno = ap.includes("PROOFING_RNO");

    storage_k = ap.includes("STORAGE_K");
    storage_ck = ap.includes("STORAGE_CK");
    storage_fbuv = ap.includes("STORAGE_FBUV");
    storage_tk = ap.includes("STORAGE_TK");
    storage_dc = ap.includes("STORAGE_DC");
    storage_kf = ap.includes("STORAGE_KF");
    storage_sf = ap.includes("STORAGE_SF");
    storage_hdc = ap.includes("STORAGE_HDC");
  }

  const delaySave = debounce(save, DEFAULT_DEBOUNCED_SAVE_MS);

  onDestroy(delaySave.flush);

  function save() {
    mutateConfig((c) => {
      let a: string[] = [];
      if (auto) a.push("AUTO");

      if (proofing_r) a.push("PROOFING_R");
      if (proofing_rc) a.push("PROOFING_RC");
      if (proofing_rno) a.push("PROOFING_RNO");

      if (storage_k) a.push("STORAGE_K");
      if (storage_ck) a.push("STORAGE_CK");
      if (storage_fbuv) a.push("STORAGE_FBUV");
      if (storage_tk) a.push("STORAGE_TK");
      if (storage_dc) a.push("STORAGE_DC");
      if (storage_kf) a.push("STORAGE_KF");
      if (storage_sf) a.push("STORAGE_SF");
      if (storage_hdc) a.push("STORAGE_HDC");

      c.system.accessiblePrograms = a;
    });
  }
</script>

<ConfigDialog title="Accessible Programs">
  <div class="content">
    <div class="group">
      <CheckBox bind:checked={auto} label={$_("Auto")} on:change={delaySave} />
    </div>
    <div class="group">
      <h1>{$_("Storage")}</h1>
      <CheckBox bind:checked={storage_k} label="K" on:change={delaySave} />
      <CheckBox bind:checked={storage_ck} label="CK" on:change={delaySave} />
      <CheckBox bind:checked={storage_fbuv} label="FBUV" on:change={delaySave} />
      <CheckBox bind:checked={storage_tk} label="TK" on:change={delaySave} />
      <CheckBox bind:checked={storage_dc} label="DC" on:change={delaySave} />
      <CheckBox bind:checked={storage_kf} label="KF" on:change={delaySave} />
      <CheckBox bind:checked={storage_sf} label="SF" on:change={delaySave} />
      <CheckBox bind:checked={storage_hdc} label="HDC" on:change={delaySave} />
    </div>
    <div class="group">
      <h1>{$_("Proofing")}</h1>
      <CheckBox bind:checked={proofing_r} label="R" on:change={delaySave} />
      <CheckBox bind:checked={proofing_rc} label="RC" on:change={delaySave} />
      <CheckBox bind:checked={proofing_rno} label="RNO" on:change={delaySave} />
    </div>
  </div>
</ConfigDialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .content {
    padding: $dialog-padding;
    font-family: hmiFont;
    font-size: 2.6rem;
    display: flex;
    margin-block: auto;
    justify-content: space-evenly;
  }

  .group {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  h1 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--clr-company);
  }
</style>
