<script lang="ts">
  import { onDestroy } from "svelte";
  import { _, locale, locales } from "svelte-i18n";
  import { client, config, mutateConfig } from "@/hmi";
  import ConfigDialog from "./ConfigDialog.svelte";
  import CheckBox from "@/lib/CheckBox.svelte";

  const sortedLocales = [...$locales].sort();
  let dirty = false;
  let qs = sortedLocales.map((e) => ({ name: e, selected: $config?.system.quickLanguages.includes(e) }));

  onDestroy(() => {
    if (!dirty) return;
    mutateConfig((config) => {
      config.system.quickLanguages = qs.filter((e) => e.selected).map((e) => e.name);
    });
  });

  function selectLanguage(name: string) {
    locale.set(name);
  }
</script>

<ConfigDialog title={$_("language")}>
  <div class="content">
    <p>{$_("Select language for the application")}:</p>
    <div class="flags">
      {#each sortedLocales as name}
        <img src="/flags/80x60/{name}.png" class:active={name === $locale} on:click={() => selectLanguage(name)} />
      {/each}
    </div>
    <br />
    <br />
    <p>{$_("quick_language_select")}:</p>
    <div class="quick-select">
      {#each qs as e}
        <div>
          <CheckBox bind:checked={e.selected} on:change={() => (dirty = true)}>
            <img src="/flags/80x60/{e.name}.png" />
          </CheckBox>
        </div>
      {/each}
    </div>
  </div>
</ConfigDialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .content {
    margin: 20px;
    text-align: center;

    p {
      font-size: 3rem;
    }

    .flags {
      display: flex;
      gap: 2rem;
      justify-content: center;
      .active {
        outline: 0.3rem solid $company;
        outline-offset: 0.5rem;
      }
    }

    .quick-select {
      margin-inline: auto;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 1rem 5rem;
      width: fit-content;
      max-height: 360px;
    }
  }
</style>
