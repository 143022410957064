<script lang="ts">
  import Field from "./Field.svelte";
</script>

<div class="fields">
  <slot {Field} />
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .fields {
    padding: $dialog-padding;
    font-family: hmiFont;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 2rem;
  }
</style>
