<script lang="ts">
  import ConfigDialog from "@/config/ConfigDialog.svelte";
  import EditFrame from "./EditFrame.svelte";

  import { api } from "@/api";
  import dialog from "@/dialog";
  import { DEFAULT_DEBOUNCED_SAVE_MS } from "@/hmi";
  import { usesFarenheight } from "@/stores";
  import { CtoF, Events, FtoC, eventBus } from "@/utils";
  import { debounce, isEqual } from "lodash-es";
  import { onDestroy } from "svelte";
  import { _, locale } from "svelte-i18n";
  import EditFrameContent from "./_EditFrameContent.svelte";
  import { Phases, type DefrostProgram, type PhaseName } from "./defrost";
  import DefrostPhases from "./DefrostPhases.svelte";

  const debounceSave = debounce(save, DEFAULT_DEBOUNCED_SAVE_MS);

  let data: DefrostProgram | undefined = undefined;
  let selectedPhase: PhaseName;
  let phaseTitle: string | undefined;
  $: {
    $locale; // update when locale changes
    phaseTitle = selectedPhase ? $_("$$defrost." + selectedPhase) : "?";
  }

  let backup: DefrostProgram = null;
  let canRevert = false;
  const debounceUpdateRevert = debounce(() => {
    canRevert = !isEqual(backup, data);
  }, 100);

  loadData();

  function toUserTempUnit(p: DefrostProgram) {
    if (!$usesFarenheight) return;

    p.preHeating.t = CtoF(p.preHeating.t);
    p.heatHigh.maxTemp = CtoF(p.heatHigh.maxTemp);
    p.heatLow.evapStopTemp = p.heatLow.evapStopTemp.map((t) => CtoF(t));
    p.preCooling.evapTemp = p.preCooling.evapTemp.map((t) => CtoF(t));
  }

  function toServerTempUnit(p: DefrostProgram) {
    if (!$usesFarenheight) return;

    p.preHeating.t = FtoC(p.preHeating.t);
    p.heatHigh.maxTemp = FtoC(p.heatHigh.maxTemp);
    p.heatLow.evapStopTemp = p.heatLow.evapStopTemp.map((t) => FtoC(t));
    p.preCooling.evapTemp = p.preCooling.evapTemp.map((t) => FtoC(t));
  }

  async function loadData() {
    try {
      const response = await api.loadProgram<DefrostProgram>("defrost", "default");
      console.assert(response, "Defrost program is not defined");
      data = response;
      toUserTempUnit(data);
      backup = structuredClone(data);
    } catch (err) {
      console.error(err);
    }
  }

  onDestroy(() => {
    debounceSave.flush(); // Save immediately if there is a pending save
    debounceUpdateRevert.cancel();
    eventBus.emit(Events.reloadAutoProgram);
  });

  async function save() {
    if (!data) return;
    let saveData = structuredClone(data);
    toServerTempUnit(saveData);
    api.saveProgram("defrost", "default", saveData).catch((err) => {
      console.error(err);
    });
  }

  function onChange() {
    debounceUpdateRevert();
    debounceSave();
  }

  function onCloseEdit() {
    selectedPhase = undefined;
  }

  async function revert() {
    if (!(await dialog.confirm("Are you sure you want to revert all changes?"))) return;
    data = structuredClone(backup);
    debounceSave();
    debounceUpdateRevert();
  }

  function pickSide(phase: PhaseName) {
    switch (phase) {
      case Phases.preHeating:
      case Phases.heatHigh:
      case Phases.heatLow:
        return "right";
      default:
        return "left";
    }
  }
</script>

<ConfigDialog title={$_("Defrost")} helpId="DEFROST_PROGRAM" {revert} {canRevert}>
  <div class="content">
    {#if data}
      <DefrostPhases bind:data bind:selectedPhase />
      {@const panelSide = pickSide(selectedPhase)}
      {#if selectedPhase && panelSide == "left"}
        <EditFrame title={phaseTitle} on:close={onCloseEdit} position="left">
          <EditFrameContent bind:data {selectedPhase} on:change={onChange} />
        </EditFrame>
      {/if}
      {#if selectedPhase && panelSide == "right"}
        <EditFrame title={phaseTitle} on:close={onCloseEdit} position="right">
          <EditFrameContent bind:data {selectedPhase} on:change={onChange} />
        </EditFrame>
      {/if}
    {/if}
  </div>
</ConfigDialog>

<style lang="scss">
  @use "../../../styles/variables.scss" as *;

  .content {
    font-size: 2.6rem;
    //margin: $dialog-padding;
    height: 100%;
    font-family: hmiFont;
    display: grid;

    :global(.uv-light) {
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }
</style>
