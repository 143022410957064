<script lang="ts">
  import { Phases, type DefrostProgram, type PhaseName } from "./defrost";
  import EditDripTime from "./EditDripTime.svelte";
  import EditHeatHigh from "./EditHeatHigh.svelte";
  import EditHeatLow from "./EditHeatLow.svelte";
  import EditPreCooling from "./EditPreCooling.svelte";
  import EditPreHeating from "./EditPreHeating.svelte";

  export let selectedPhase: PhaseName;
  export let data: DefrostProgram;
</script>

{#if selectedPhase === Phases.preHeating}
  <EditPreHeating bind:data on:change />
{:else if selectedPhase === Phases.heatHigh}
  <EditHeatHigh bind:data on:change />
{:else if selectedPhase === Phases.heatLow}
  <EditHeatLow bind:data on:change />
{:else if selectedPhase === Phases.dripTime}
  <EditDripTime bind:data on:change />
{:else if selectedPhase === Phases.preCooling}
  <EditPreCooling bind:data on:change />
{/if}
