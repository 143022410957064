<script lang="ts">
  import KeypadIcon from "@/svg/KeypadIcon.svelte";
  import PauseIcon from "@/svg/PauseIcon.svelte";
  import PlayIcon from "@/svg/PlayIcon.svelte";
  import StopIcon from "@/svg/StopIcon.svelte";
  import TrashIcon from "@/svg/TrashIcon.svelte";
  import { createEventDispatcher } from "svelte";
  import { quintOut } from "svelte/easing";
  import { fly } from "svelte/transition";
  import Button from "./Button.svelte";
  import RotaryInput from "./RotaryInput.svelte";

  export let id: number;
  export let duration: number;
  export let state: string;

  const fire = createEventDispatcher();
  let updated = false;
  let originalDuration = duration;
  $: if (originalDuration !== duration) updated = true; // This timer needs to be saved

  function onPlayPause() {
    if (state === "running") {
      fire("command", "PAUSE");
    } else {
      fire("command", "START");
    }
  }

  function checkUpdated() {
    if (updated) {
      fire("updated", id);
      updated = false;
    }
  }

  $: if (duration) localStorage.setItem("lastCountdownTimerDuration_s", duration.toString());

  function absorb(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }
</script>

<!-- NOTE: The autofocus attribute here is important so focus returns to this element if a child button is disabled -->
<div
  class="countdown-edit"
  tabindex="-1"
  autofocus
  on:blur={checkUpdated}
  on:click={absorb}
  on:pointerdown={absorb}
  on:pointerup={absorb}
  transition:fly|local={{ duration: 200, easing: quintOut, y: 100 }}
>
  <div class="rotary">
    <RotaryInput bind:value={duration} min={1} max={999} unit="m">
      <Button naked on:click={onPlayPause}>
        {#if state === "running"}
          <PauseIcon height="6rem" />
        {:else}
          <PlayIcon height="6rem" />
        {/if}
      </Button>
    </RotaryInput>
  </div>
  <div class="commands">
    <Button naked on:click={() => fire("command", "STOP")} disabled={state === "stopped"}><StopIcon /></Button>
    <Button naked on:click={() => fire("numpad")}><KeypadIcon /></Button>
    <Button naked on:click={() => fire("delete")}><TrashIcon /></Button>
  </div>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .countdown-edit {
    --border-width: 0.2rem;
    background-color: $background;
    position: absolute;
    bottom: calc(100% + 1.5rem);
    left: 50%;
    border: var(--border-width) solid $clr-popup-border;
    box-shadow: 0px 0 4rem 1rem rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    padding: 10px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    place-items: center;
    z-index: 10;

    // Draw a notch at the bottom of the countdown edit box
    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      bottom: var(--border-width);
      left: 50%;
      border: 1.5rem solid transparent;
      transform-origin: bottom;
      transform: translateX(-50%) rotate(180deg);
      border-bottom-color: $background;
      filter: drop-shadow(0 calc(-1 * var(--border-width)) 0 $clr-popup-border);
    }

    .rotary {
      width: 24rem;
    }

    .commands {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
</style>
