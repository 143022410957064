<script lang="ts">
  export let program: DTO.ProgramInfo;
  export let selectedProgram: string;
  export let countdown: number | undefined = undefined;
</script>

<button
  type="button"
  data-kind="program"
  data-id={program.slot}
  class:selected={program.slot === selectedProgram}
  data-countdown={!countdown ? undefined : `${countdown}`}
>
  <div class="slot">{program.slot}<span class="program-type">{program.type}</span></div>
  <div class="name">{program.name}</div>
  {#if program.slot === selectedProgram}
    <div class="program-active-indicator" />
  {/if}
</button>

<style lang="scss">
  button {
    max-width: 16rem;
    position: relative;

    > * {
      line-height: 1.1;
    }

    &.selected::before {
      content: attr(data-countdown);
      position: absolute;
      top: auto;
      bottom: 100%;
      left: 0;
      width: 100%;
      color: var(--clr-menu-background);
      background-color: var(--clr-company);
      opacity: 1;
    }
  }

  .name {
    margin-top: 0.5rem;
    font-size: 1.6rem;
    font-family: hmiFont;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  .slot {
    white-space: nowrap !important;
  }
  .program-type {
    font-family: hmiFontThin;
    font-size: 60%;
  }
</style>
