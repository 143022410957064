<script lang="ts">
  import Button from "@/lib/Button.svelte";
  import ConfigDialog from "./ConfigDialog.svelte";
  import ListInput from "@/lib/ListInput.svelte";
  import dialog from "@/dialog";
  import { config, client } from "@/hmi";

  let selectedProfile: string | undefined = undefined;
  let profiles = [];

  client
    .invoke<string[]>("GetProfiles")
    .then((result) => {
      profiles.push(...result.map((p, i) => ({ value: i, text: p })));
      selectedProfile = $config.system.profile;
    })
    .catch((e) => {
      alert(e);
    });

  async function loadProfile() {
    let result = await dialog.confirm(`Are you sure you want to load profile ${selectedProfile}?`);
    if (!result) return;
    client.invoke("LoadProfile", selectedProfile).catch((e) => {
      alert(e);
    });
  }

  async function saveProfile() {
    let name = await dialog.keyboard("Enter profile name", "");
    if (!name) return;
    let exists = profiles.find((p) => p.text === name);
    if (exists) {
      let overwrite = await dialog.confirm(`Profile ${name} already exists. Overwrite?`);
      if (!overwrite) return;
    }
    client
      .invoke("SaveProfile", name)
      .catch((e) => {
        alert(e);
      })
      .then(() => {
        if (!exists) {
          profiles.push({ value: profiles.length, text: name });
          profiles = profiles; // force update
        }
        selectedProfile = name;
      });
  }
</script>

<ConfigDialog title="Load/Save Profiles">
  <div class="content">
    <p class="flex">
      <ListInput width="50rem" bind:items={profiles} label="Profile" bind:selectedText={selectedProfile} />
      <Button on:click={loadProfile} disabled={!selectedProfile}>Load</Button>
      <Button on:click={saveProfile}>Save</Button>
    </p>
  </div>
</ConfigDialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .content {
    display: grid;
    align-content: start;
    padding: 2rem;
    height: 100%;
    font-family: hmiFont;
    font-size: 2.6rem;
  }
</style>
