<script lang="ts">
  import Button from "@/lib/Button.svelte";
  import Dialog from "./Dialog.svelte";

  export let title: string;
  export let message: string;

  let dlg: Dialog;
</script>

<Dialog {title} bind:this={dlg}>
  <div class="message-body first-upper">{@html message}</div>
  <div slot="actions" class="button-group center">
    <Button on:click={() => dlg.close(true)}>OK</Button>
  </div>
</Dialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .message-body {
    padding: $dialog-padding;
    text-align: center;
    min-width: 40rem;
    min-height: 10rem;
    max-width: 400px;
    font-family: hmiFont;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
