<script lang="ts">
  import { Events, eventBus } from "@/utils";
  import { onMount } from "svelte";
  import type { SwiperContainer } from "swiper/element";
  import { Pagination, Virtual } from "swiper/modules";
  import type { SwiperOptions } from "swiper/types";

  export let virtual = false;
  export let paginationClass: string;

  let ref: SwiperContainer;
  let curSlide = 0;

  onMount(() => {
    Object.assign(ref, {
      longSwipesRatio: 0.1,
      modules: [Pagination, Virtual],
      pagination: {
        el: "." + paginationClass,
        clickable: false,
      },
    } as SwiperOptions);
    ref.initialize();

    const handleSlideChange = () => {
      curSlide = +ref.swiper.activeIndex;
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (!e.ctrlKey) return;

      if (e.key === "ArrowLeft") {
        ref.swiper.slidePrev();
      } else if (e.key === "ArrowRight") {
        ref.swiper.slideNext();
      } else if (e.key === "Home") {
        ref.swiper.slideTo(0);
      } else if (e.key === "End") {
        let slides = ref.swiper.virtual.slides.length || ref.swiper.slides.length;
        ref.swiper.slideTo(slides - 1);
      }
    };

    const handleResetView = () => ref.swiper.slideTo(0);

    ref.swiper.on("slideChange", handleSlideChange);
    document.addEventListener("keydown", handleKeydown);
    eventBus.on(Events.inputIdle, handleResetView);
    return () => {
      ref.swiper.off("slideChange", handleSlideChange);
      document.removeEventListener("keydown", handleKeydown);
      eventBus.detach(Events.inputIdle, handleResetView);
    };
  });

  // Move focus to swiper container when clicking on it
  // This is needed to dismiss popups when clicking outside of them
  function onSwiperClick(e: MouseEvent) {
    ref?.focus();
  }
</script>

<swiper-container
  re
  init="false"
  pagination="true"
  tabindex="-1"
  bind:this={ref}
  on:click|self={onSwiperClick}
  virtual={{ enabled: virtual }}
>
  <slot {curSlide} />
</swiper-container>

<style lang="scss">
</style>
