<script lang="ts">
  export let label: string;
  export let disabled: boolean = false;
</script>

<label style:opacity={disabled ? 0.5 : 1}>{label}:</label>
<div style:opacity={disabled ? 0.5 : 1} class:disabled>
  <slot />
</div>

<style lang="scss">
  .disabled {
    opacity: 0.5;

    :global(*) {
      pointer-events: none !important;
    }
  }

  label {
    white-space: nowrap;
  }
</style>
