<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";

  export let height: string = "100%";
  export let items: any[];
  export let noborder: boolean = false;
  export let selected: any = undefined;
  export let columns: string = undefined;
  export let rowDisabled = (item: any) => false;
  export let rowSelected = (item: any) => item === selected;
  export let canDeselect = false;

  const dispatch = createEventDispatcher();

  let ref: HTMLUListElement;

  onMount(() => {
    setTimeout(() => {
      let selected = ref.querySelector("li.selected");
      if (selected) selected.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }, 50);
  });

  function select(item: any) {
    if (rowSelected(item)) {
      if (!canDeselect) return;
      selected = undefined;
      dispatch("select", undefined);
      return;
    }
    selected = item;
    dispatch("select", item);
  }
</script>

<div class="list-view scrollable-wrapper" style:height class:show-border={!noborder} style:--columns={columns} class:columns={!!columns}>
  <ul class="body scrollable-child" bind:this={ref}>
    {#each items as item, i}
      <li role="button" class:selected={rowSelected(item)} on:click={() => select(item)} class:disabled={rowDisabled(item)}>
        <slot {item} index={i} />
      </li>
    {/each}
  </ul>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .list-view.show-border {
    border: 1px solid $primary-dimmed;
  }

  .columns li {
    display: grid;
    grid-template-columns: var(--columns);
  }

  li {
    padding: 0.5rem;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .selected {
    background-color: $company;
    color: $background;
  }

  .disabled {
    color: $primary-dimmed;
  }
</style>
