<script lang="ts">
  import { api } from "@/api";
  import dialog from "@/dialog";
  import { config } from "@/hmi";
  import Button from "@/lib/Button.svelte";
  import ListInput from "@/lib/ListInput.svelte";
  import TextInput from "@/lib/TextInput.svelte";
  import { fullScreenComponent } from "@/stores";
  import { dateNow, pad2 } from "@/utils";
  import { onMount } from "svelte";

  let targets: ListItem[];
  let selectedTarget: string = "";
  let now = dateNow();
  let busy = false;
  let filename: string =
    "backups/" +
    ($config.general.orderNumber || $config.general.name) +
    `/${now.getFullYear()}-${pad2(now.getMonth())}-${pad2(now.getDay())}.zip`;

  onMount(async () => {
    targets = (await api.findBackupTargets())?.map((x) => ({ text: x, value: x }));
    if (targets && targets.length && targets[0].value) {
      selectedTarget = targets[0].value;
    }
    // if (location.hostname !== "localhost" && location.hostname !== "127.0.0.1") {
    //   targets.push({ text: "Download", value: "__download" });
    //   selectedTarget = "__download";
    // }
    if (!targets?.length) {
      targets = [{ text: "None found!", value: "__none" }];
      selectedTarget = "__none";
    }
  });

  $: if (!filename.endsWith(".zip")) filename += ".zip";

  function close() {
    $fullScreenComponent = null;
  }

  async function backup() {
    busy = true;
    let result = await api.backupToFile(selectedTarget, filename);
    busy = false;
    if (result) {
      await dialog.message("Backup result", "Backup complete!");
      close();
    }
  }
</script>

<section>
  <div class="columns">
    <label>Destination:</label>
    <ListInput width="50rem" items={targets} bind:selectedValue={selectedTarget} disabled={busy} />

    <label class:disabled={selectedTarget === "__download" || selectedTarget === "__none"}>Filename:</label>
    <TextInput width="50rem" bind:value={filename} disabled={selectedTarget === "__download" || selectedTarget === "__none"} />
  </div>

  <br />
  <br />
  <div class="buttons">
    <Button on:click={close} disabled={busy}>Back</Button>
    <Button on:click={backup} disabled={busy || !filename || !targets?.length || selectedTarget === "__none"}>Backup</Button>
  </div>
</section>

<style lang="scss">
  section {
    --full-width: calc(100% + var(--menu-width));

    width: var(--full-width);
    height: 100%;
    font-size: 2.5rem;
  }

  .columns {
    label {
      white-space: nowrap;
    }
    display: grid;
    grid-template-columns: auto auto;
    gap: 2rem;
    align-items: center;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
</style>
