<script lang="ts">
  import hmi from "@/hmi";
  import ListInput from "@/lib/ListInput.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import { numFmt } from "@/stores";
  import type { Writable } from "svelte/store";

  export let all: DTO.Equipment[];
  export let item: DTO.Equipment;
  export let changeCounter: number = 0;
  export let disabled = false;

  let hmiValue: Writable<number>;
  let hmiValueRaw: Writable<number>;

  const deviceSelection = [
    { value: 0, text: "-" },
    { value: 1, text: "1: FlexIO" },
  ];

  function changeDetected() {
    changeCounter++;
    update();
  }

  $: {
    item;
    update();
  }

  // Checks scaling is set to something valid
  // Also setup value display if hmi_id is set
  function update() {
    hmiValue = item.hmi_id ? hmi.getValueStore(item.hmi_id) : undefined;
    if (item.type == "AI" || item.type == "AO" || item.type == "PT") {
      hmiValueRaw = item.hmi_id ? hmi.getValueStore(item.hmi_id + "_raw") : undefined;
      if ($hmiValueRaw === null) hmiValueRaw = undefined;
    }

    if (item.num === undefined || item.num === 0) {
      item.num = 1;
      changeCounter++;
    }
    if (item.settings) {
      for (let i = 0; i < item.settings.length; i++) {
        if (item.settings[i].type === "select") {
          const options = item.settings[i].options;
          if (!options.includes(item.settings[i].value)) {
            console.warn("Invalid option for " + item.settings[i].id + " in " + item.text + " - resetting to default");
            item.settings[i].value = options[0];
            changeCounter++;
          }
        }
      }
    }

    if (item.type === "PT") {
      item.offset = item.offset || 0;
    }

    if (item.type === "AO") {
      // AO has fixed scaling 0-100% -> 0-200 (0-10V)
      item.scaling = item.scaling || { external: [0, 100], internal: [0, 100] };
    }
    if (item.type === "AI") {
      if (!item.scaling) {
        item.scaling = { external: [0, 100], internal: [0, 100] };
        changeCounter++;
      }
    }
  }

  function getIOTypeText(type: string) {
    return type;
    /*
    switch (type) {
      case "DI":
        return "Digital Input";
      case "DO":
        return "Digital Output";
      case "AI":
        return "Analog Input";
      case "AO":
        return "Analog Output";
      default:
        return "INVALID!";
    }
    */
  }

  function usage(type: string, num: number): string {
    let found = all.find((e) => e.dev === item.dev && e.type === type && e.num === num && e !== item);
    if (found) {
      // FIXME: This font-size style doesn't work!
      return ` - ${found.text}`;
    }
    return "";
  }

  function getItems(type: string) {
    let count = 0;
    switch (type) {
      case "DI":
      case "DO":
        count = 10;
        break;
      case "AI":
      case "AO":
        count = 2;
        break;
      case "PT":
        count = 3;
        break;
    }
    let a = new Array<{ text: string; value: number | undefined }>(count);
    for (let i = 1; i <= count; i++) a[i - 1] = { value: i, text: `${i}${usage(type, i)}` };
    if (type === "AI") a.push({ value: 3, text: "3 (Power input)" });
    return a;
  }

  function optionsItems(options: string[]) {
    return options?.map((o, i) => ({ value: i, text: o }));
  }
</script>

<div class="details" class:disabled>
  <div class="dim">ID:</div>
  <div class="dim">{item.id}</div>

  <div>Device/IO:</div>
  <div class="device-io">
    <ListInput width="100%" items={deviceSelection} bind:selectedValue={item.dev} on:change={changeDetected} />
    <ListInput
      width="100%"
      items={item.dev > 0 ? getItems(item.type) : []}
      bind:selectedValue={item.num}
      on:change={changeDetected}
      disabled={!item.dev}
    />
  </div>

  {#if item.settings}
    {#each item.settings as setting}
      <div>{setting.text}:</div>
      {#if setting.type === "select"}
        <ListInput width="50%" items={optionsItems(setting.options)} on:change={changeDetected} bind:selectedText={setting.value} />
      {/if}
    {/each}
  {/if}

  {#if item.type === "PT"}
    <div>Offset:</div>
    <div class="flex-line">
      <NumberInput width="12rem" bind:value={item.offset} digit negative min={-100} max={100} on:change={changeDetected} />
      <span>°C</span>
    </div>
  {/if}
  {#if item.type === "AI"}
    <div>Scaling:</div>
    <div class="scaling">
      <NumberInput width="10rem" bind:value={item.scaling.external[0]} on:change={changeDetected} />
      <NumberInput width="10rem" bind:value={item.scaling.external[1]} on:change={changeDetected} />
      <span>=&gt;</span>
      <NumberInput width="10rem" digit negative bind:value={item.scaling.internal[0]} on:change={changeDetected} />
      <NumberInput width="10rem" digit negative bind:value={item.scaling.internal[1]} on:change={changeDetected} />
    </div>
  {/if}
  {#if item.type === "AO"}
    <div>Scaling:</div>
    <div class="scaling">
      <NumberInput width="10rem" digit negative bind:value={item.scaling.internal[0]} on:change={changeDetected} />
      <NumberInput width="10rem" digit negative bind:value={item.scaling.internal[1]} on:change={changeDetected} />
      <span>=&gt;</span>
      <NumberInput width="10rem" bind:value={item.scaling.external[0]} on:change={changeDetected} />
      <NumberInput width="10rem" bind:value={item.scaling.external[1]} on:change={changeDetected} />
    </div>
  {/if}

  <div class="item-info">
    {#if item.dev && hmiValue}
      <div class="item-value dim">
        Current value:
        {#if item.type === "DI" || item.type === "DO"}
          <span style:color={$hmiValue ? "limegreen" : "white"}>{$hmiValue ? "ON" : "OFF"}</span>
        {:else}
          <span>{$numFmt($hmiValue, -2)}</span>
          {#if hmiValueRaw !== undefined}
            <span>({$hmiValueRaw})</span>
          {/if}
        {/if}
      </div>
    {/if}
    {item.info}.
  </div>
</div>

<style lang="scss">
  @use "../../styles/variables.scss" as *;

  .dim {
    opacity: 0.5;
  }

  .details {
    position: relative;

    display: grid;
    grid-template-columns: auto 1fr;
    align-content: start;
    align-items: center;
    gap: 2rem;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .item-info {
      position: relative;
      border-top: 2px solid $primary-dimmed;
      padding: $dialog-padding 0;
      position: absolute;
      inset: auto 0 0 0;
    }
    .item-value {
      position: absolute;
      top: -3.5rem;
    }

    .device-io {
      display: grid;
      grid-template-columns: 50% auto;
      gap: 2rem;
    }
  }

  .scaling {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
