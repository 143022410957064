<script lang="ts">
  export let color: string = undefined;
  export let opacity: number = undefined;
</script>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...$$restProps} style:color style:opacity>
  {@html `
  <path fill="currentColor" d="M17 22v-8h-4v2h2v6h-3v2h8v-2h-3zM16 8a1.5 1.5 0 1 0 1.5 1.5A1.5 1.5 0 0 0 16 8z" /><path
    fill="currentColor"
    d="M16 30a14 14 0 1 1 14-14a14 14 0 0 1-14 14Zm0-26a12 12 0 1 0 12 12A12 12 0 0 0 16 4Z"
  />
  `}
</svg>
