<script lang="ts">
  import { _ } from "svelte-i18n";

  import { Fields } from "@/ui";

  import Dialog from "@/dialog/Dialog.svelte";
  import Button from "@/lib/Button.svelte";
  import ListInput from "@/lib/ListInput.svelte";
  import { buildSlots, type ProgramType } from "./manager";

  export let title = "Copy Program";
  export let programsList: ListItem[];
  export let type: ProgramType;

  let dlg: Dialog;
  let result: boolean | null = null;
  let { targetProgram, availableSlots } = buildSlots(type, programsList);
</script>

<Dialog {title} returnValue={result} bind:this={dlg}>
  <Fields let:Field>
    <Field label={$_("target_slot")}>
      <ListInput items={availableSlots} bind:selectedValue={targetProgram} width="25rem" />
    </Field>
  </Fields>

  <div slot="actions" class="button-group space-between">
    <Button on:click={() => dlg.close(null)}>{$_("cancel")}</Button>
    <Button on:click={() => dlg.close({ targetProgram })}>{$_("ok")}</Button>
  </div>
</Dialog>
