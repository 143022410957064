<script lang="ts">
  import SectionLine from "./_SectionLine.svelte";

  export let header: string;
  export let entries: DTO.Equipment[];
  export let conflicts: Set<string>;
  export let changeCounter: number;
  export let expanded: boolean;
  export let selectedId: string | undefined;

  $: anyConflictsInSection = entries.some((e) => conflicts.has(e.id));

  // We need to delay the changeCounter to avoid Svelte updates the DOM to early
  let changeCounterDelayed = changeCounter;
  $: if (changeCounterDelayed !== changeCounter) {
    queueMicrotask(() => (changeCounterDelayed = changeCounter));
  }

  function select(id: string) {
    if (selectedId === id) {
      selectedId = undefined;
      return;
    }
    selectedId = id;
  }
</script>

<tr class="section" class:conflict={anyConflictsInSection}>
  <td colspan="2" on:click={() => (expanded = !expanded)}>
    <div class="expand" class:expanded />
    {@html header}
  </td>
</tr>
{#if expanded}
  {#key changeCounterDelayed}
    {#each entries as e, i}
      <SectionLine
        entry={e}
        selected={selectedId === e.id}
        conflict={conflicts.has(e.id)}
        last={i === entries.length - 1}
        on:select={(e) => select(e.detail)}
      />
    {/each}
  {/key}
{/if}

<style lang="scss">
  @use "../../styles/variables.scss" as *;

  .expand {
    display: inline-block;
    aspect-ratio: 1/1;
    width: 2rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='3 2 20 20' fill='none' stroke='lightgray' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(2px);
    &.expanded {
      transform: rotate(180deg) translateY(-1px);
    }
  }

  .section {
    font-family: hmiFontBold;
    color: $company;
    background-color: $background;
    border: none;
    border-bottom: 3px solid $menu-background;
  }

  tr {
    &:active {
      background-color: lighten($background, 15%);
    }

    &.conflict {
      color: red;
      text-shadow:
        -1px -1px 0 #222,
        1px -1px 0 #222,
        -1px 1px 0 #222,
        1px 1px 0 #222;
    }
  }

  td {
    padding: 0.5rem;
  }
</style>
