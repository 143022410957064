<script lang="ts">
  import ListInput from "@/lib/ListInput.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import { json, locale } from "svelte-i18n";

  export let value: DTO.Defrost;
  export let prop: PropItem;
  export let localePrefix: string | undefined = undefined;

  let locText = $json(localePrefix, $locale) ?? {};
  function T(key: string) {
    return locText[key] ?? key;
  }

  if (value === undefined && prop.default !== undefined) {
    value = structuredClone(prop.default);
  }

  const defrostTypes = [
    { text: T("none"), value: 0 },
    { text: T("natural_time"), value: 1 },
    { text: T("natural_sensor"), value: 2 },
    { text: T("active"), value: 3 },
  ];
</script>

<section>
  <label>{T("defrost_type")}:</label>
  <ListInput width="24rem" items={defrostTypes} bind:selectedValue={value.type} on:change />

  {#if value.type === 1}
    <!-- Natural - time -->
    <label>{T("minutes")}:</label>
    <NumberInput width="20rem" incdec bind:value={value.time} on:change min={1} max={999} />
  {:else if value.type === 2}
    <!-- Natural - sensor -->
    <label>{T("stop_temp")}:</label>
    <NumberInput width="20rem" incdec bind:value={value.stop_at} on:change min={-20} max={50} negative digit />

    <label>{T("seconds")}:</label>
    <NumberInput width="20rem" incdec bind:value={value.time} on:change min={1} max={9999} />
  {/if}
</section>

<style lang="scss">
  section {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 2rem 1rem;
    align-items: center;
  }
</style>
