<script lang="ts">
  export let label: string | undefined = undefined;
  export let checked: boolean = false;
  export let disabled = false;
  export let color: string | undefined = undefined;
  export let size: "xsmall" | "small" | "medium" | "large" | "xlarge" = "medium";
</script>

<label class:disabled class={size}>
  <input type="checkbox" bind:checked on:change on:input {disabled} />
  <div class={`switch ${size}`} style={color ? `--on-color: ${color}` : ""}>
    <span class="state">ON</span>
  </div>
  {#if label}
    <span class="label">{label}</span>
  {/if}
</label>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  $sizes: xsmall, small, medium, large, xlarge;

  label {
    font-family: hmiFont;
    width: fit-content;

    @each $size in $sizes {
      &.#{$size} {
        font-size: #{1.2rem + 0.5rem * (index($sizes, $size) - 1)};
      }
    }

    &.disabled {
      filter: brightness(0.6);
    }
  }

  .switch {
    @each $size in $sizes {
      &.#{$size} {
        --size: #{2.25rem + 1rem * (index($sizes, $size) - 1)};
      }
    }
    --on-color: #{$company};
    --thumb-padding: calc((var(--size) - var(--size) * 0.75) / 2);

    display: inline-block;
    background-color: $primary-dimmed;
    width: calc(var(--size) * 1.75);
    height: var(--size);
    border-radius: calc(var(--size) / 2);
    position: relative;
    vertical-align: middle;
    transition: background-color var(--animation-duration);

    &:before {
      content: "";
      display: block;
      background-color: $primary;
      border-radius: 50%;
      border: 1px solid $background;
      width: calc(var(--size) * 0.75);
      height: calc(var(--size) * 0.75);
      position: absolute;
      top: var(--thumb-padding);
      left: var(--thumb-padding);
      transition: left var(--animation-duration);
      z-index: 1;
      //box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.5);
    }
  }

  input:checked + .switch {
    background: var(--on-color);
  }
  input:checked + .switch:before {
    left: calc(calc(var(--size) * 1.75) - var(--thumb-padding) - var(--size) * 0.75);
  }

  input:disabled + .switch {
    pointer-events: none;
    filter: brightness(0.6);
  }

  input {
    position: absolute;
    visibility: hidden;
  }

  .label {
    vertical-align: middle;
    margin-left: 1rem;
  }

  .state {
    position: absolute;
    padding-top: 1px;
    line-height: var(--size);
    color: black;
    font-size: 75%;
    left: calc(var(--thumb-padding) * 1.2);
  }
</style>
