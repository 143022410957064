<!-- NOTE: currentColor was #bebebe -->
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.873736 43.266033" version="1.1" {...$$restProps}>
  {@html `
  <g id="layer1" transform="translate(-69.926227,-106.87888)">
    <g id="g1715" transform="matrix(0.10282449,0,0,-0.10282449,-58.827823,391.2064)">
      <g id="g1658">
        <path
          d="m 1254.173,2347.862 v 393.904 l 6.535,0.957 V 2348.62 l 0.422,-0.073 z"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path162-6"
        />
        <path
          d="m 1282.069,2745.85 106.583,15.6 v -399.419 l 0.832,-0.153 1.798,0.177 2.566,0.036 1.726,-0.318 -113.505,-11.167 z m 89.124,-46.883 -35.596,-4.955 v -179.015 l 8.345,0.982 27.251,3.203 v 176.485 z"
          style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path164-7"
        />
        <path
          d="m 1254.173,2347.862 6.957,0.685 -0.422,0.073 20.941,2.061 0.42,-0.075 113.505,11.167 8.295,-1.526 -141.283,-13.859 z"
          style="fill:#434343;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path166-6"
        />
        <path
          d="m 1403.869,2360.247 -8.295,1.526 -1.726,0.318 -2.566,-0.036 -1.798,-0.177 -0.832,0.153 1.438,0.141 3.935,0.078 10.525,-1.936 z"
          style="fill:#b8c8d2;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path168-5"
        />
        <path
          d="m 1282.069,2745.85 v -395.244 l -0.42,0.075 v 395.279 z"
          style="fill:#bbbbbb;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path170-1"
        />
        <path
          d="m 1260.708,2742.895 20.941,3.065 v -395.279 l -20.941,-2.061 v 394.103 z m 0.298,-86.695 20.312,2.759 v 7.02 l -20.312,-2.776 z"
          style="fill:#5b5b5b;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path172-6"
        />
        <path
          d="m 1390.09,2362.172 -1.438,-0.141 v 399.419 1.396 l 1.438,0.211 z"
          style="fill:#5b5b5b;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path174-7"
        />
        <path
          d="m 1394.025,2362.25 -3.935,-0.078 v 400.885 l 3.935,0.117 z"
          style="fill:#606060;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path176-4"
        />
        <path
          d="m 1404.55,2360.314 -10.525,1.936 v 400.924 l 10.525,-2.881 z"
          style="fill:#3b3b3b;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path178-7"
        />
        <path
          d="m 1335.597,2694.012 35.596,4.955 v -3.3 l -27.251,-3.782 z"
          style="fill:#434343;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path180-8"
        />
        <path
          d="m 1335.597,2514.997 v 179.015 l 8.345,-2.127 v -175.906 z"
          style="fill:#7c7c7c;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path182-3"
        />
        <path
          d="m 1281.318,2658.959 -20.312,-2.759 v 7.003 l 20.312,2.776 z"
          style="fill:#5b5b5b;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path184-1"
        />
        <path
          d="M 1254.173,2741.766 V 2347.862"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path778-1"
        />
        <path
          d="m 1388.652,2761.45 -106.583,-15.6"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path780-8"
        />
        <path
          d="m 1254.173,2347.862 6.957,0.685"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path782-1"
        />
        <path
          d="m 1262.586,2346.388 141.283,13.859"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path784-9"
        />
        <path
          d="m 1254.173,2347.862 8.413,-1.474"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path786-2"
        />
        <path
          d="m 1395.574,2361.773 8.295,-1.526"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path788-9"
        />
        <path
          d="m 1371.193,2698.967 -35.596,-4.955"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path790-1"
        />
        <path
          d="M 1335.597,2694.012 V 2514.997"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path792-6"
        />
        <path
          d="m 1371.193,2695.667 -27.251,-3.782"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path794-4"
        />
        <path
          d="M 1343.942,2691.885 V 2515.979"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path796-9"
        />
        <path
          d="m 1335.597,2694.012 8.345,-2.127"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path798-7"
        />
        <path
          d="m 1282.069,2350.606 113.505,11.167"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path800-5"
        />
        <path
          d="M 1282.069,2745.85 V 2350.606"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path802-8"
        />
        <path
          d="m 1260.708,2742.723 -6.535,-0.957"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path804-2"
        />
        <path
          d="m 1281.649,2745.96 -20.941,-3.065"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path806-7"
        />
        <path
          d="m 1260.708,2348.62 20.941,2.061"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path808-2"
        />
        <path
          d="M 1281.649,2745.96 V 2350.681"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path810-2"
        />
        <path
          d="m 1282.069,2745.85 -0.42,0.11"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path812-3"
        />
        <path
          d="m 1261.13,2348.547 -0.422,0.073"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path814-3"
        />
        <path
          d="m 1282.069,2350.606 -0.42,0.075"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path816-9"
        />
        <path
          d="m 1395.574,2361.773 -1.726,0.318"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path818-6"
        />
        <path
          d="m 1389.484,2361.878 -0.832,0.153"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path820-7"
        />
        <path
          d="m 1388.652,2362.031 1.438,0.141"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path822-5"
        />
        <path
          d="m 1390.09,2362.172 3.935,0.078"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path824-6"
        />
        <path
          d="m 1391.282,2362.055 -1.798,-0.177"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path826-5"
        />
        <path
          d="m 1391.282,2362.055 2.566,0.036"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path828-0"
        />
        <path
          d="m 1394.025,2362.25 10.525,-1.936"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path830-0"
        />
        <path
          d="m 1404.55,2360.314 -0.681,-0.067"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path832-6"
        />
        <path
          d="m 1394.025,2763.174 10.525,-2.881"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path834-1"
        />
        <path
          d="m 1390.09,2763.057 3.935,0.117"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path836-9"
        />
        <path
          d="m 1388.652,2762.846 1.438,0.211"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path838-3"
        />
        <path
          d="m 1404.55,2360.314 v 399.979"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path840-5"
        />
        <path
          d="m 1394.025,2362.25 v 400.924"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path842-5"
        />
        <path
          d="m 1390.09,2362.172 v 400.885"
          style="fill:none;stroke:#000000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path844-5"
        />
        <path
          d="m 1335.597,2514.997 8.345,0.982"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path870-1"
        />
        <path
          d="m 1343.942,2515.979 27.251,3.203"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path872-4"
        />
        <path
          d="m 1371.193,2519.182 v 176.485"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path874-9"
        />
        <path
          d="m 1371.193,2695.667 v 3.3"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path876-4"
        />
        <path
          d="m 1260.708,2348.62 v 394.103"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path878-5"
        />
        <path
          d="m 1260.708,2742.723 v 0.172"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path880-1"
        />
        <path
          d="M 1388.652,2362.031 V 2761.45"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path882-2"
        />
        <path
          d="m 1388.652,2761.45 v 1.396"
          style="fill:none;stroke:#000000;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path884-5"
        />
      </g>
    </g>
  </g>
  `}
</svg>
