<script lang="ts">
  import DurationInput from "@/lib/DurationInput.svelte";
  import ListInput from "@/lib/ListInput.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import Switch from "@/lib/Switch.svelte";
  import TimeInput from "@/lib/TimeInput.svelte";
  import ClockIcon from "@/svg/ClockIcon.svelte";
  import DurationIcon from "@/svg/DurationIcon.svelte";
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";
  import StorageSettings from "./_StorageSettings.svelte";
  import { ProceedAction, hasUV, type AutoProgram } from "./auto";

  $$restProps; // Get rid of "<xxx> was created with unknown prop" warning

  export let data: AutoProgram;
  export let minTemp: number;
  export let maxTemp: number;
  export let storageProgramItems: ListItem[];

  const proceedItems = [
    // { text: $_("programs.auto_choice.button_on_screen"), value: ProceedAction.ButtonOnScreen },
    { text: $_("programs.auto_choice.duration"), value: ProceedAction.Duration },
    { text: $_("programs.auto_choice.countdown_after_door"), value: ProceedAction.CountdownAfterDoor },
    { text: $_("programs.auto_choice.setpoint_reached"), value: ProceedAction.SetpointReached },
    { text: $_("programs.auto_choice.time"), value: ProceedAction.Time },
  ];

  const dispatch = createEventDispatcher();

  $: retarding = data.retarding;

  function triggerReactivity() {
    retarding = retarding;
    dispatch("change");
  }

  function onProceedChange() {
    retarding.proceed.value = 0;
    triggerReactivity();
  }
</script>

<div class="edit-container">
  <StorageSettings data={retarding} {minTemp} {maxTemp} {storageProgramItems} on:change={triggerReactivity} />

  <label>{$_("Light")}:</label>
  <Switch bind:checked={retarding.light} on:change />

  {#if $hasUV}
    <label>{$_("UV")}:</label>
    <Switch bind:checked={retarding.uv} on:change />
  {/if}

  <hr />

  <label>{$_("proceed")}:</label>
  <ListInput items={proceedItems} width="100%" bind:selectedValue={retarding.proceed.action} on:change={onProceedChange} />
  {#if retarding.proceed.action == ProceedAction.Duration}
    <label class="indent">{$_("programs.auto_choice.duration")}:</label>
    <div class="flex-line">
      <DurationInput bind:value={retarding.proceed.value} hideSecond on:change />
      <DurationIcon height="3rem" color="var(--clr-field-icon)" />
    </div>
  {:else if retarding.proceed.action == ProceedAction.CountdownAfterDoor}
    <label class="indent">{$_("minutes")}:</label>
    <NumberInput
      label={$_("countdown") + " (" + $_("minutes") + ")"}
      width="18rem"
      incdec
      step={1}
      min={0}
      max={350}
      bind:value={retarding.proceed.value}
      hidelabel
      on:change
    />
  {:else if retarding.proceed.action == ProceedAction.Time}
    <label class="indent">{$_("programs.auto_choice.time")}:</label>
    <div class="flex-line">
      <TimeInput bind:value={retarding.proceed.value} on:change />
      <ClockIcon height="3rem" color="var(--clr-field-icon)" />
    </div>
  {/if}
</div>

<style lang="scss">
  .edit-container {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 2rem;
    align-content: start;
    align-items: center;
    min-height: 20.5rem;

    .indent {
      padding-left: 2rem;
    }
  }
</style>
