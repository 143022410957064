<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let disabled = false;
  export let outline = false;
  export let round = false;
  export let naked = false;
  export let selected = false; // Only for naked
  export let size: "xsmall" | "small" | "medium" | "large" | "xlarge" = "medium";
  export let minWidth: string = "4rem";

  let clazz: string = "";
  export { clazz as class };

  const dispatch = createEventDispatcher();
</script>

<button
  tabindex="0"
  type="button"
  class:outline
  class:round
  class:naked
  class:selected
  style:--min-width={minWidth}
  {disabled}
  class={size + " " + clazz}
  on:click|stopPropagation
  on:pointerdown|stopPropagation
  on:pointerup|stopPropagation
>
  <slot />
</button>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  button {
    all: unset;
    box-sizing: border-box;
    display: inline-block;
    background-color: var(--color, $company);
    color: var(--text-color, black);
    font-family: hmiFontBold;
    display: flex;
    gap: 1rem;
    place-items: center;
    justify-content: center;
    white-space: nowrap;
    min-width: var(--min-width, 4rem);

    :global(> img),
    :global(> svg) {
      all: unset;
      height: var(--height, 4rem);
      max-height: 100%;
    }

    :global(> *) {
      pointer-events: none !important;
    }

    $sizes: xsmall, small, medium, large, xlarge;
    @each $size in $sizes {
      &.#{$size} {
        $i: index($sizes, $size) - 1;
        --height: calc(3rem + 1rem * #{$i});
        --padding: calc(0.4rem + 0.4rem * #{$i});
        font-size: 1.25rem + 0.5rem * $i;
      }
    }

    height: var(--height, 4rem);
    min-height: var(--height, 4rem);
    max-height: var(--height, 4rem);
    padding-inline: var(--padding, 1.5rem);
    padding-block: calc(var(--padding, 1.5rem) / 2);
    border-radius: calc(var(--height) / 8);

    &.round {
      border-radius: 50%;
      aspect-ratio: 1/1;
      padding-inline: 0;
    }

    &.outline {
      background-color: transparent;
      border: 2px solid var(--color, $company);
      color: var(--text-color, $company);
    }

    &.naked {
      height: auto;
      padding: 0.5rem;
      background-color: transparent;
      border: none;
      color: var(--text-color, $company);
      min-width: unset;

      &.selected {
        outline: 2px solid $company;
        outline-offset: 5px;
      }
    }

    :not(&.naked):active {
      outline: 2px solid var(--color, $company);
      outline-offset: 2px;
    }
    &.naked {
      &:active {
        background-color: lighten($background, 10%);
      }
      // @include active;
    }

    @include disabled;
  }
</style>
