<script lang="ts">
  import { config, programs } from "@/hmi";
  import Button from "@/lib/Button.svelte";
  import ListInput from "@/lib/ListInput.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import TextInput from "@/lib/TextInput.svelte";
  import { usesFarenheight } from "@/stores";
  import { CtoF } from "@/utils";
  import { isEqual } from "lodash-es";
  import { _, json, locale } from "svelte-i18n";
  import Defrost from "./_Defrost.svelte";
  import DefrostSchedule from "./_DefrostSchedule.svelte";
  import Multi from "./_Multi.svelte";
  import Setpoints from "./_Setpoints.svelte";

  export let prop: PropItem;
  export let value: any;
  export let changeCounter: number;
  export let help: (key: string, loc: string) => string | undefined;
  export let localePrefix: string | undefined = undefined;

  let locText = $json(localePrefix, $locale) ?? {};
  function T(key: string) {
    return locText[key] ?? key;
  }

  const helpText: string = help ? help(prop.path, $locale) : undefined;

  let min: number, max: number;
  let unit = prop.unit;
  let defaultValue = prop.default;
  const coolingPrograms = $programs.storage.programs.map((p) => ({ text: `${p.slot}: ${p.name}`, value: p.slot }));
  const proofingPrograms = $programs.proofing.programs.map((p) => ({ text: `${p.slot}: ${p.name}`, value: p.slot }));

  $: storageProgramTypes = $config.system.accessiblePrograms
    .filter((x) => x.startsWith("STORAGE"))
    .map((x) => ({ text: `${programTypeString(x)}`, value: x.slice(8) }));
  $: proofingProgramTypes = $config.system.accessiblePrograms
    .filter((x) => x.startsWith("PROOFING"))
    .map((x) => ({ text: `${programTypeString(x)}`, value: x.slice(9) }));

  if (prop.type === "int" || prop.type === "float") {
    min = prop.min;
    max = prop.max;
    if (prop.isAbsTemp && $usesFarenheight) {
      min = Math.floor(CtoF(min));
      max = Math.ceil(CtoF(max));
      if (defaultValue !== undefined) defaultValue = Math.round(CtoF(defaultValue));
    }
    if (min !== undefined) value = Math.max(min, value);
    if (max !== undefined) value = Math.min(max, value);
  }
  if (prop.unit === "°C/F") {
    if (prop.isAbsTemp) unit = $usesFarenheight ? "°F" : "°C";
    else unit = $usesFarenheight ? "Δ°F" : "Δ°C";
  }

  function programTypeString(s: string) {
    let n = s.indexOf("_");
    if (n === -1) {
      return s;
    }
    // FIXME: Real translated names for program types
    return s.slice(n + 1);
  }

  const yesno = [
    { text: $_("No"), value: false },
    { text: $_("Yes"), value: true },
  ];

  if (value === undefined) {
    value = structuredClone(prop.default);
    changeDetected();
  }

  function changeDetected() {
    changeCounter++;
  }

  function reset() {
    value = structuredClone(defaultValue);
    changeDetected();
  }

  function localizeListItems(items: { text: string; value: any }[]) {
    return items.map((item) => ({ text: T(item.text), value: item.value }));
  }
</script>

<div class="value">
  <div class="prop-name first-upper">
    <span>{T(prop.path)}</span>
    {#if prop.unit}
      <span class="unit">({unit})</span>
    {/if}
  </div>

  {#if prop.type === "int" || prop.type === "float"}
    <NumberInput
      width="20rem"
      incdec
      bind:value
      on:change={changeDetected}
      {min}
      {max}
      negative={min < 0}
      digit={prop.type === "float"}
      step={prop.step || 1}
    />
  {:else if prop.type === "boolean"}
    <ListInput width="12rem" items={yesno} bind:selectedValue={value} on:change={changeDetected} />
  {:else if prop.type === "list"}
    <ListInput width="24rem" items={localizeListItems(prop.items)} bind:selectedValue={value} on:change={changeDetected} />
  {:else if prop.type === "string"}
    <TextInput bind:value on:change={changeDetected} />
  {:else if prop.type === "defrost"}
    <Defrost bind:value {prop} on:change={changeDetected} {localePrefix} />
  {:else if prop.type === "defrost_schedule"}
    <DefrostSchedule bind:value {prop} on:change={changeDetected} {localePrefix} />
  {:else if prop.type === "multi"}
    <Multi bind:value {prop} on:change={changeDetected} {localePrefix} />
  {:else if prop.type === "cooling_program"}
    <ListInput width="24rem" items={coolingPrograms} bind:selectedValue={value} on:change={changeDetected} />
  {:else if prop.type === "proofing_program"}
    <ListInput width="24rem" items={proofingPrograms} bind:selectedValue={value} on:change={changeDetected} />
  {:else if prop.type === "storage_program_type"}
    <ListInput width="24rem" items={storageProgramTypes} bind:selectedValue={value} on:change={changeDetected} />
  {:else if prop.type === "proofing_program_type"}
    <ListInput width="24rem" items={proofingProgramTypes} bind:selectedValue={value} on:change={changeDetected} />
  {:else if prop.type === "setpoints"}
    <Setpoints bind:value {prop} on:change={changeDetected} />
  {/if}

  <br />
  <br />
  {#if defaultValue !== undefined}
    <Button disabled={isEqual(value, defaultValue)} on:click={reset}>{T("reset")}</Button>
  {/if}
</div>

{#if helpText}
  <div class="help">{helpText}.</div>
{/if}

<style lang="scss">
  @use "../../styles/variables.scss" as *;

  .value {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    font-size: 2.6rem;
  }

  .prop-name {
    color: $company;
    font-family: hmiFontBold;
    text-align: center;
  }

  .help {
    position: absolute;
    background-color: $background;
    left: -1rem;
    right: 0;
    bottom: 0;
    padding: 1rem;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .unit {
    font-size: 80%;
    opacity: 0.5;
  }
</style>
