<script lang="ts">
  import { Modes } from "@/hmi/index.js";
  import ModeViewChild from "./ModeViewChild.svelte";
  import Off from "./Standby.svelte";
  import Auto from "./auto/Auto.svelte";
  import Storage from "./Storage.svelte";
  import Proofing from "./Proofing.svelte";

  export let mode: number | null;

  let views = [
    { key: Modes.OFF, component: Off },
    { key: Modes.AUTO, component: Auto },
    { key: Modes.STORAGE, component: Storage },
    { key: Modes.PROOFING, component: Proofing },
  ];
</script>

{#each views as { key, component } (key)}
  <ModeViewChild {mode} selected={mode === key}>
    <svelte:component this={component} />
  </ModeViewChild>
{/each}
