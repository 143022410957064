<script lang="ts">
  import { applyBrightness, config, mutateConfig } from "@/hmi";
  import CheckBox from "@/lib/CheckBox.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import { clickDelay as s_clickDelay } from "@/stores";
  import { clamp } from "@/utils";
  import { onDestroy } from "svelte";
  import { _ } from "svelte-i18n";
  import ConfigDialog from "./ConfigDialog.svelte";

  let enableIdleTimeout = !!$config.system.userIdleTimeout;
  let userIdleTimeout = !enableIdleTimeout ? 60 : Math.max(10, $config.system.userIdleTimeout || 60); // seconds
  let enableLockTimeout = !!$config.system.lockTimeout;
  let lockTimeout = !enableLockTimeout ? 600 : Math.max(10, $config.system.lockTimeout || 600); // seconds
  let enableUnlockDelay = !!$config.system.unlockDelay;
  let unlockDelay = !enableUnlockDelay ? 3000 : $config.system.unlockDelay || 3000; // milliseconds
  let enableClickDelay = !!$config.system.clickDelay;
  let clickDelay = !enableClickDelay ? 1500 : $config.system.clickDelay || 1500; // milliseconds

  let appliedBrightness = 0;
  let brightnessString = localStorage.getItem("screen_brightness_2") || "50";
  appliedBrightness = clamp(parseInt(brightnessString, 10), 20, 100);
  let brightness = appliedBrightness;
  let ignore = localStorage.getItem("screen_ignore") === "true";

  $: if (brightness !== appliedBrightness) {
    appliedBrightness = brightness;
    localStorage.setItem("screen_brightness_2", brightness.toString());
    applyBrightness();
  }

  $: localStorage.setItem("screen_ignore", ignore.toString());

  onDestroy(() => {
    save();
  });

  function save() {
    s_clickDelay.set(enableClickDelay ? clickDelay : 0);
    mutateConfig((c) => {
      c.system.userIdleTimeout = enableIdleTimeout ? Math.max(10, userIdleTimeout) : 0;
      c.system.lockTimeout = enableLockTimeout ? Math.max(10, lockTimeout) : 0;
      c.system.unlockDelay = enableUnlockDelay ? unlockDelay : 0;
      c.system.clickDelay = enableClickDelay ? clickDelay : 0;
    });
  }
</script>

<ConfigDialog title={$_("menu.screen")}>
  <div class="content">
    <div class="table">
      <!-- User idle timeout -->
      <CheckBox label={$_("$$screen.user_idle_timeout") + ":"} bind:checked={enableIdleTimeout} />
      <NumberInput bind:value={userIdleTimeout} min={10} max={9999} step={10} incdec width="18rem" disabled={!enableIdleTimeout} />
      <span>s</span>
      <!-- Lock timeout -->
      <CheckBox label={$_("$$screen.lock_timeout") + ":"} bind:checked={enableLockTimeout} />
      <NumberInput bind:value={lockTimeout} min={10} max={9999} step={10} incdec width="18rem" disabled={!enableLockTimeout} />
      <span>s</span>
      <!-- Unlock delay -->
      <CheckBox label={$_("$$screen.unlock_time") + ":"} bind:checked={enableUnlockDelay} />
      <NumberInput bind:value={unlockDelay} min={0} max={9999} incdec width="18rem" step={100} disabled={!enableUnlockDelay} />
      <span>ms</span>
      <!-- Click delay -->
      <CheckBox label={$_("$$screen.click_time") + ":"} bind:checked={enableClickDelay} />
      <NumberInput bind:value={clickDelay} min={0} max={5000} incdec width="18rem" step={100} disabled={!enableClickDelay} />
      <span>ms</span>
    </div>
    <!-- Brightness -->
    <input type="range" min="20" max="100" step="1" bind:value={brightness} />
    {$_("$$screen.brightness")}: {brightness}%

    {#if window.location.hostname !== "localhost"}
      <br />
      <br />
      <!-- Ignore on local device -->
      <CheckBox bind:checked={ignore}>Ignore user idle and lock timeout on this device</CheckBox>
    {/if}
  </div>
</ConfigDialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .content {
    padding: $dialog-padding;
    height: 100%;
    font-family: hmiFont;
    font-size: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;

    .table {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 2rem;
      align-items: center;
      margin-bottom: 4rem;
    }

    input {
      width: 70%;
    }
  }
</style>
