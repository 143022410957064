<script lang="ts">
  import { api } from "@/api";
  import CurveDisplay from "@/config/programs/auto/CurveDisplay.svelte";
  import { Phase, lookupPhaseTitle, toUserTempUnit, type AutoProgram } from "@/config/programs/auto/auto";
  import { Stage } from "@/constants";
  import dialog from "@/dialog";
  import hmi, { MIN_TEMP_IN_CELCIUS_FOR_HUMIDITY, curHumidityText, curTempText } from "@/hmi";
  import { autoProgram, fullDateTimeFmt, usesFarenheight } from "@/stores";
  import { Events, FtoC, eventBus, pad2 } from "@/utils";
  import { debounce, isEqual } from "lodash-es";
  import { onMount } from "svelte";
  import { _, locale } from "svelte-i18n";
  import StatusPanel from "../StatusPanel.svelte";

  const autoUpdate = hmi.getObjectStore<DTO.AutoUpdate>("AutoUpdate");
  const autoStatus = hmi.getObjectStore<DTO.AutoStatus>("AutoStatus");
  const curT = hmi.getValueStore("cur.T");

  let bakingTime: Date;
  let data: AutoProgram = undefined;
  let programName = "";
  let lastUsesFarenheight = $usesFarenheight;
  let endTimes: Record<string, number> = undefined;
  const debounceUpdate = debounce(() => {
    updateData();
  }, 1);

  $: phase = $autoUpdate?.phase;
  $: progress = $autoUpdate?.progress;
  $: remain = $autoUpdate?.remain;
  $: waitingOnDoor = $autoUpdate?.waitingOnDoor;
  $: if ($autoStatus) debounceUpdate();
  $: if (!isEqual(endTimes, $autoUpdate?.endTimes)) endTimes = $autoUpdate?.endTimes;

  $: {
    phase;
    bakingTime = calcBakingTime();
  }

  $: if ($usesFarenheight !== lastUsesFarenheight) {
    lastUsesFarenheight = $usesFarenheight;
    debounceUpdate();
  }

  onMount(() => {
    const commandHandler = async (id: string) => {
      console.log("Auto command", id);
      if (id === "restart") {
        if (await dialog.confirm("Really restart the auto program?")) {
          api.run("auto.restart");
        }
      } else if (id === "menu") {
        window.alert("COMMING SOON!");
      } else if (id === "next-phase") {
        api.run("auto.nextPhase");
      } else if (id === "stop") {
        api.run("auto.stop");
      }
    };

    eventBus?.on(Events.menuAutoCommand, commandHandler);
    return () => {
      eventBus?.detach(Events.menuAutoCommand, commandHandler);
    };
  });

  function calcBakingTime(): Date {
    if (typeof $autoStatus?.bakingTime !== "number") return new Date(0);
    let t = new Date($autoStatus.bakingTime * 1000);
    console.log("Baking time:", $fullDateTimeFmt(t));
    return t;
  }

  function updateData() {
    data = undefined;
    autoProgram.set(undefined);
    if (!$autoStatus?.data) return;
    data = structuredClone($autoStatus.data);
    toUserTempUnit(data);
    programName = data.name;
    autoProgram.set($autoStatus.slot);
  }

  $: {
    $locale; // This is a hack to force this to re-run when the locale changes

    // HACK: This fixes a bug where one of the time-tags was not updated when the locale changed.
    bakingTime = new Date(0);
    queueMicrotask(() => {
      bakingTime = calcBakingTime();
    });
  }

  let autoStageString = "";

  $: {
    $locale; // This is a hack to force this to re-run when the locale changes
    switch (phase) {
      case Stage.off:
        autoStageString = "OFF";
        break;
      case Stage.preCooling:
        autoStageString = lookupPhaseTitle(data, Phase.preCooling);
        break;
      case Stage.retarding:
        autoStageString = lookupPhaseTitle(data, Phase.retarding);
        break;
      case Stage.storage1:
        autoStageString = lookupPhaseTitle(data, Phase.storage1);
        break;
      case Stage.storage2:
        autoStageString = lookupPhaseTitle(data, Phase.storage2);
        break;
      case Stage.thawing:
        autoStageString = lookupPhaseTitle(data, Phase.thawing);
        break;
      case Stage.proofing:
        autoStageString = lookupPhaseTitle(data, Phase.proofing);
        break;
      case Stage.cooling:
        autoStageString = lookupPhaseTitle(data, Phase.cooling);
        break;
      default:
        autoStageString = undefined;
    }

    if (!autoStageString) {
      autoStageString = `Phase #${phase} ?`;
    }
  }

  function dimmedZero(s: string) {
    for (let i = 0; i < s.length; i++) {
      if (s[i] !== "0" && s[i] !== ":") {
        if (!i) return s;
        return `<span class="dim">${s.slice(0, i)}</span>` + s.slice(i);
      }
    }
    return `<span class="dim">${s}</span>`;
  }

  function timeString(d: number): string {
    let pre = d < 0 ? "-" : "";
    d = Math.abs(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d / 60) % 60);
    let s = Math.floor(d % 60);
    if (h) return `${pre}${pad2(h)}:${pad2(m)}:${pad2(s)}`;
    else return `${pre}${pad2(m)}:${pad2(s)}`;
  }

  export function ensureCelcius(n: number) {
    if (!$usesFarenheight) return n;
    return FtoC(n);
  }
</script>

<div class="container">
  <div class="scroll-page-padding">
    {#if data && $autoStatus && autoUpdate && bakingTime}
      <CurveDisplay {data} {bakingTime} {phase} {progress} {endTimes} --background="var(--clr-background)" />
    {/if}
  </div>
  <StatusPanel>
    <svelte:fragment slot="info">
      <div class="status-details">
        <div class="cur-values">
          {$curTempText} <br />
          <span style:opacity={ensureCelcius($curT) >= MIN_TEMP_IN_CELCIUS_FOR_HUMIDITY ? 1 : 0}>
            {$curHumidityText}
          </span>
        </div>
        {#if phase !== undefined}
          <div>
            {autoStageString}
            <br />
            {#if remain !== undefined}
              {@html dimmedZero(timeString(remain))}
            {:else if waitingOnDoor}
              <span class="font-thin blink">{$_("waiting_on_door")}...</span>
            {/if}
          </div>
        {/if}
      </div>
    </svelte:fragment>
  </StatusPanel>
</div>

<style lang="scss">
  @use "../../styles/variables.scss" as *;

  .container {
    display: grid;
    grid-template-rows: 1fr auto;
    height: 100%;

    .status-details {
      display: flex;
      gap: 2rem;
      font-size: 3.85rem;
      font-weight: bold;
      line-height: 1.17;

      .cur-values {
        padding-right: 2rem;
        border-right: 1px solid var(--clr-primary-dimmed);
      }
    }
  }
</style>
