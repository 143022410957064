<script lang="ts">
  import hmi, { config, equipment, state } from "@/hmi";
  import { numFmt, usesFarenheight } from "@/stores";
  import { CtoF, repeat } from "@/utils";
  import { onDestroy } from "svelte";
  import type { Unsubscriber } from "svelte/store";

  const fanSpeed = hmi.getValueStore("cur.fan_speed");
  const steampotAmps = hmi.getValueStore("cur.steampot_amps");
  const heatOn = hmi.getValueStore("cur.heat_on");
  const coolingOn = hmi.getValueStore("cur.cooling_on");
  const uvOn = hmi.getValueStore("cur.uv_light");
  const steamExternal = hmi.getValueStore("cur.steam_external");

  $: hasUVOption = $equipment?.includes("UV_LIGHT");
  $: hasAirHeatOption = $equipment?.includes("AIR_HEATING_ELEMENTS");
  $: hasSteamOption = $equipment?.includes("STEAM_RODS_IN_STEAM_POT");
  $: hasCoolingOption = $equipment?.includes("COOLING_PUMP_DOWN_SIGNAL");
  $: numEvaps = $config?.general.evaporatorCount || 0;

  const unsubs: Unsubscriber[] = [];

  let evapTemps = new Array<number>(numEvaps).fill(0);
  let evapHeat = new Array<boolean>(numEvaps).fill(false);

  $: for (let i = 0; i < numEvaps; i++) {
    unsubs.forEach((u) => u());
    unsubs.length = 0;

    const tempStore = hmi.getValueStore(`cur.eva_temp.${i + 1}`);
    let f = tempStore.subscribe((val) => {
      evapTemps[i] = $usesFarenheight ? CtoF(val) : val;
      evapTemps = evapTemps; // trigger update
    });
    unsubs.push(f);

    const heatStore = hmi.getValueStore(`cur.eva_heat.${i + 1}`);
    f = heatStore.subscribe((val) => {
      evapHeat[i] = !!val;
      evapHeat = evapHeat; // trigger update
    });
    unsubs.push(f);
  }

  onDestroy(() => {
    unsubs.forEach((u) => u());
  });
</script>

<!-- FIXME: Remove zoom when we have new higher resolution images -->
<div class="stacking" style="zoom: var(--img-zoom)">
  <img src="/img/air-treatment/AirBoxBackground.png" draggable="false" loading="lazy" />

  <img src="/img/air-treatment/AirFanOff.png" draggable="false" style:opacity={$fanSpeed ? 0 : 1} loading="lazy" />
  <img src="/img/air-treatment/AirFanOnAni.webp" draggable="false" style:opacity={$fanSpeed ? 1 : 0} loading="lazy" />

  {#if hasUVOption}
    <img src="/img/air-treatment/AirUvLightOff.png" draggable="false" loading="lazy" />
    <img src="/img/air-treatment/AirUvLightOn.png" draggable="false" style:opacity={$uvOn} loading="lazy" />
  {/if}

  {#if hasSteamOption}
    <img src="/img/air-treatment/AirSteampipe.png" draggable="false" loading="lazy" />
    <img
      src="/img/air-treatment/AirSteamOnAni.webp"
      draggable="false"
      style:opacity={$steampotAmps > 0.1 ? 1 : 0 || $steamExternal}
      loading="lazy"
    />
  {/if}

  {#if hasCoolingOption}
    <img src="/img/air-treatment/AirEvaporatorOff.png" draggable="false" loading="lazy" style:opacity={hasCoolingOption ? 1 : 0.2} />
    <img src="/img/air-treatment/AirEvaporatorOn.png" draggable="false" style:opacity={$coolingOn} loading="lazy" />
    <img src="/img/air-treatment/AirCoolingOn2Ani.webp" draggable="false" style:opacity={$coolingOn} loading="lazy" />
    <img src="/img/air-treatment/AirCoolingOnAni.webp" draggable="false" style:opacity={$coolingOn} loading="lazy" />

    {#if numEvaps}
      <div class="evap-temps">
        {#each repeat(numEvaps) as i}
          <div class:heat={evapHeat[i]}>{$numFmt(evapTemps[i], 1)}{$state.tempUnit}</div>
        {/each}
      </div>
    {/if}
  {/if}

  {#if hasAirHeatOption}
    <img src="/img/air-treatment/AirHeatOff.png" draggable="false" loading="lazy" />
    <img src="/img/air-treatment/AirHeatOn.png" draggable="false" style:opacity={$heatOn} loading="lazy" />
  {/if}
</div>

<style lang="scss">
  .stacking {
    position: relative;
    display: inline-grid;
    > * {
      grid-area: 1/1;
    }

    img {
      width: 800px;
      height: 342px;
      transition: opacity 0.3s ease-in-out;
    }
  }

  .evap-temps {
    position: absolute;
    top: 89px;
    left: 280px;
    width: auto;
    height: 204px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
      font: 1.2rem hmiFontBold;
      line-height: 1;
      opacity: 0.5;
      padding: 0.1rem 0.2rem;
      border: 1px solid black;
      background-color: white;
      color: black;

      &.heat {
        background-color: red;
        color: white;
      }
    }
  }
</style>
