<script lang="ts">
  import Dialog from "./Dialog.svelte";

  export let title: string;
  export let items: string[];

  let selectedIndex: number | null = null;

  function onItemSelected(e: MouseEvent) {
    let target = e.target as HTMLLIElement;
    if (target.tagName !== "LI") return;
    selectedIndex = +target.getAttribute("data-index")!;
  }
</script>

<Dialog {title} returnValue={selectedIndex}>
  <ul on:click|stopPropagation={onItemSelected}>
    {#each items as item, i}
      <li data-index={i} class:selected={i === selectedIndex}>{item}</li>
    {/each}
  </ul>
</Dialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  ul {
    margin: 0;
    padding: 0;
    line-height: 4.5rem;
    font-size: 2.5rem;
    font-family: hmiFont;
    list-style-type: none;

    li {
      padding: 0 $dialog-padding;
      &:not(:last-child) {
        border-bottom: 1px solid $primary-dimmed;
      }
    }
  }
  li.selected {
    background-color: $company;
    color: $menu-background;
  }
</style>
