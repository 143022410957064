<script lang="ts">
  import { fade } from "svelte/transition";
  import { slowClick } from "./actions";
  import { config } from "./hmi";

  export let locked;
</script>

<div
  class="lock-screen"
  use:slowClick={{ clickDelay: $config?.system.unlockDelay }}
  on:click={() => (locked = false)}
  tabindex="-1"
  transition:fade={{ duration: 200 }}
>
  <div class="menu-overlay">
    <svg width="6rem" viewBox="0 0 14 14">
      <g fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round">
        <rect width="10" height="8" x="2" y="5.5" rx="1" />
        <path d="M10.5 5.5V4a3.5 3.5 0 0 0-7 0v1.5" />
        <circle cx="7" cy="9.5" r=".5" />
      </g>
    </svg>
  </div>
</div>

<style lang="scss">
  .lock-screen {
    position: absolute;
    opacity: 0.8;
    inset: 0;
    z-index: 10000;
  }

  .menu-overlay {
    position: absolute;
    right: 0;
    left: auto;
    width: var(--menu-width);
    background-color: var(--clr-background);
    display: grid;
    place-items: center;
  }
</style>
