<script lang="ts">
  import { state } from "@/hmi";
  import Button from "@/lib/Button.svelte";
  import DurationInput from "@/lib/DurationInput.svelte";
  import ListInput from "@/lib/ListInput.svelte";
  import MultiSelectInput from "@/lib/MultiSelectInput.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import Switch from "@/lib/Switch.svelte";
  import DurationIcon from "@/svg/DurationIcon.svelte";
  import { _ } from "svelte-i18n";
  import { AfterMode, Indication, type AutoProgram } from "./auto";

  $$restProps; // Get rid of "<xxx> was created with unknown prop" warning

  export let data: AutoProgram;
  export let minTemp: number;
  export let maxTemp: number;
  export let proofingProgramItems: ListItem[];

  const indicationItems = [
    { text: $_("programs.auto_choice.beeper"), value: Indication.Beeper },
    { text: $_("programs.auto_choice.blink_light"), value: Indication.BlinkLight },
    // { text: $_("programs.auto_choice.AUX_DO"), value: Indication.AuxDO },
  ];

  $: proofing = data.proofing;

  function onRecool() {
    window.alert("Not implemented yet");
  }
</script>

<div class="edit-container">
  <div class="column">
    <label>{$_("Temperature")}:</label>
    <div class="flex-line">
      <NumberInput
        label={$_("Temperature")}
        width="18rem"
        incdec
        step={1}
        min={minTemp}
        max={maxTemp}
        bind:value={proofing.t}
        digit
        hidelabel
        on:change
      />
      <span>{$state.tempUnit}</span>
    </div>

    <label>{$_("Humidity")}:</label>
    <div class="flex-line">
      <NumberInput label={$_("Humidity")} width="18rem" incdec step={1} min={0} max={100} bind:value={proofing.h} hidelabel on:change />
      <span>%</span>
    </div>

    {#if proofingProgramItems.length > 1}
      <label>{$_("settings")}:</label>
      <ListInput items={proofingProgramItems} bind:selectedValue={proofing.program} on:change width="26rem" />
    {/if}

    <label>{$_("programs.auto_choice.duration")}:</label>
    <div class="flex-line">
      <DurationInput bind:value={proofing.duration} hideSecond on:change />
      <DurationIcon height="3rem" color="var(--clr-field-icon)" />
    </div>

    {#if !data.after?.add_cooling_curve || data.after?.mode !== AfterMode.storage}
      <label>{$_("programs.auto.indications")}:</label>
      <MultiSelectInput items={indicationItems} width="100%" bind:value={proofing.indications} on:change />
    {/if}

    <label>{$_("Light")}:</label>
    <Switch bind:checked={proofing.light} on:change />

    <hr />

    <div class="whole-row flex-line pull-right">
      <Button size="small" disabled on:click={onRecool}>{$_("programs.auto.recool") + "..."}</Button>
    </div>
  </div>
</div>

<style lang="scss">
  @use "../../../styles/variables.scss" as *;

  .edit-container {
    display: flex;
    gap: 2rem;

    label {
      white-space: nowrap;
    }

    .column {
      position: relative;
      height: 100%;
      display: grid;
      grid-template-columns: auto auto;
      gap: 2rem;
      align-content: start;
      align-items: center;
    }
  }
</style>
