<script lang="ts">
  import { swipeable } from "@react2svelte/swipeable";
  import { _ } from "svelte-i18n";
  import { flip } from "svelte/animate";
  import { quintOut } from "svelte/easing";
  import { crossfade } from "svelte/transition";

  import { timers } from "@/hmi";
  import { prefersReducedMotion } from "@/stores";

  import { api } from "@/api";
  import Countdown from "@/lib/Countdown.svelte";
  import HourglassIcon from "@/svg/HourglassIcon.svelte";
  import StatusPanelButton from "./StatusPanelButton.svelte";

  export let collapsable = false;
  export let collapsed = collapsable;

  const MAX_COUNTDOWN_TIMERS = 4;

  $: anyRunningTimers = $timers.some((t) => t.state === "running");

  async function addCountdownTimer() {
    let id = await api.timerAdd(30);
    if (!id) return;
    queueMicrotask(() => {
      timers.update((t) => {
        t.forEach((x) => {
          if (x.id === id) x.forceShowPopup = true;
        });
        return t;
      });
    });
  }

  function deleteCountdownTimer(id: number) {
    api.timerRemove(id);
  }

  // This is a custom crossfade function that will be used to animate the
  // transition between countdown timers when they are added/removed.
  const [send, receive] = crossfade({
    duration: (d) => Math.sqrt(d * 200),
    fallback(node, params) {
      const style = getComputedStyle(node);
      let transform = style.transform === "none" ? "" : style.transform;
      if ($prefersReducedMotion) transform = "";
      return {
        duration: 200,
        easing: quintOut,
        css: (t) => ($prefersReducedMotion ? `opacity: ${t}` : `transform: ${transform} scale(${t}); opacity: ${t}`),
      };
    },
  });
</script>

<div class="status" class:collapsed class:collapsable use:swipeable on:swipeddown={() => (collapsed = collapsable && !collapsed)}>
  <div class="info">
    <slot name="info" />
  </div>
  <div class="timers-and-buttons">
    {#each $timers as t (t.id)}
      <div in:receive={{ key: t.id }} out:send={{ key: t.id }} animate:flip={{ duration: 200 }}>
        <Countdown timer={t} bind:duration={t.duration} bind:state={t.state} on:delete={() => deleteCountdownTimer(t.id)} />
      </div>
    {/each}
    <StatusPanelButton text={$_("new_timer")} on:click={addCountdownTimer} disabled={$timers.length >= MAX_COUNTDOWN_TIMERS}>
      <HourglassIcon />
    </StatusPanelButton>
    <slot name="buttons" />
  </div>
  {#if collapsable}
    <div class="handle" on:click={() => (collapsed = collapsable && !collapsed)} class:active-timers={anyRunningTimers} />
  {/if}
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .status {
    position: relative;
    width: 100%;
    padding: 1rem 2rem;
    text-align: left;
    background-color: $status-background;
    color: $primary;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 2rem;
    height: $status-height;

    &.collapsable {
      outline: $primary-dimmed solid 2px;
    }

    transition:
      transform 0.2s ease,
      height 0.2s ease;
    &.collapsed {
      transform: translateY(100%);
      height: 0;
    }

    .info {
      font-family: hmiFont;
      font-size: 3rem;
    }

    .timers-and-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  .handle {
    --width: 100px;
    clip: rect(-2px, calc(var(--width) + 2px), 16px, -2px); // Clip bottom so it doesn't overlap parent panel
    position: absolute;
    border-top: $status-background solid 7px;
    border-inline: $status-background solid 28px;
    border-bottom: $status-background solid 10px;
    border-radius: 20px 20px 0 0;
    top: -15px;
    height: 20px;
    width: var(--width);
    left: 50%;
    transform: translateX(-50%);

    outline: $primary-dimmed solid 2px;

    background-color: $primary-dimmed;
    transition: background-color 0.2s ease;
    &.active-timers {
      background-color: $company;
    }
  }
</style>
