<script lang="ts">
  import { Stage } from "@/constants";
  import hmi, { programs, state } from "@/hmi";
  import { hhmm, is12HourClock, numFmt, shortDateFmt, shortDayFmt, timeFmt, usesFarenheight } from "@/stores";
  import { durationString, linear, pad2 } from "@/utils";
  import { afterUpdate, onMount } from "svelte";
  import { _, locale } from "svelte-i18n";
  import { cubicOut } from "svelte/easing";
  import { fade, fly } from "svelte/transition";
  import {
    AfterMode,
    Curve,
    MAX_TEMP_C,
    MIN_TEMP_C,
    Phase,
    ProceedAction,
    calcAdapthawLinearDuration,
    calcLinearDuration,
    ensureCelcius,
    getAfterModeString,
    hasUV,
    lookupCurveName,
    lookupPhaseTitle,
    type AutoProgram,
  } from "./auto";
  import { getAdapThawCurvePoints } from "./curves";

  export let data: AutoProgram = undefined;
  export let bakingTime: Date;
  export let selectedPhase: string = undefined;
  export let editMode = false;
  export let calculatedThawingTimeString: string = "";
  export let phase: number = undefined;
  export let progress: number = undefined;
  export let endTimes: Record<string, number> = undefined;
  export let panelSide: "left" | "right" = undefined;

  const showMarchingAnts = false;

  onMount(() => {
    // NOTE: This is a hack to force a redraw of the graph & progress overlay when the component is mounted
    setTimeout(() => {
      redrawTrigger++;
    });
  });

  if (!data.after) data.after = { mode: AfterMode.standby, t: 0, h: 0, light: false, uv: false };

  export const redraw = () => {}; // FIXME: Remove this when we have a proper redraw mechanism

  type DrawAction = { cmd: Cmd; x: number; y: number };

  const enum Cmd {
    line,
    move,
    bezier,
    goal,
  }
  const RISE_FACTOR = 0.02;
  const canvasXPadding = 20;
  const autoStatus = hmi.getObjectStore<DTO.AutoStatus>("AutoStatus");

  let mainEl: HTMLDivElement | undefined;
  let svgCurveEl: SVGElement = undefined;
  let triggerTransition = false;
  let calculatedThawingDuration = 0;
  let glitchTimer: number | undefined;
  let sectionGridColums: string;
  let coolingTime = 1110;
  let redrawTrigger = 0;
  let svgPath = "";
  let height = 0;
  let zeroPointY = 0;
  let activePhaseLeft = 0;
  let activePhaseRight = 0;
  let selectedPhaseLeft = 0;
  let selectedPhaseRight = 0;

  $: bakingTimeString = $autoStatus?.bakingTime ? new Date($autoStatus.bakingTime * 1000) : undefined;

  onMount(() => {
    setGlitchTimer();
    return () => {
      clearTimeout(glitchTimer);
    };
  });

  afterUpdate(drawCurve);

  $: scaleY = linear([MIN_TEMP_C, MAX_TEMP_C], [height, 0]);
  $: if (scaleY) zeroPointY = scaleY(0);

  $: if (data && mainEl) {
    // Trigger initial CSS transitions
    queueMicrotask(() => {
      triggerTransition = true;
      sectionGridColums = collectGridColumns(mainEl, data);
      if (!editMode) {
        queueMicrotask(() => {
          animateCurve();
        });
      }
    });
    if (!editMode) {
      mainEl.querySelectorAll<HTMLDivElement>("[data-phase]").forEach((el, i) => {
        el.style.setProperty("--animation-delay", 100 * (i + 1) + "ms");
      });
    }
  }

  $: thawingDuration = (() => {
    if (!data || !data.thawing) return 0;
    if (data.thawing.curve === Curve.adapThawLinear) return calcAdapthawLinearDuration(data);
    if (data.thawing.curve === Curve.adapThawClassic || data.thawing.curve === Curve.adapThawTime) {
      return calculatedThawingDuration;
    }
  })();

  $: calculatedThawingTimeString = thawingDuration <= 24 * 60 * 60 ? durationString(roundToMinute(thawingDuration)) : ">24h";

  $: {
    coolingTime = 0;
    if (data?.after) {
      if (data.after.mode === AfterMode.storage && data.after.add_cooling_curve) {
        coolingTime = calcLinearDuration(ensureCelcius(data.proofing.t), ensureCelcius(data.cooling.t), data.cooling.factor);
      }
    }
  }

  $: if (editMode) getSelectedPhasePosition(selectedPhase);

  function getSelectedPhasePosition(phase: string) {
    queueMicrotask(() => {
      selectedPhaseLeft = 0;
      selectedPhaseRight = 0;
      let el = mainEl?.querySelector<HTMLDivElement>(`[data-phase].selected`);
      if (!el) return;
      selectedPhaseLeft = el.offsetLeft;
      selectedPhaseRight = el.offsetLeft + el.clientWidth;
    });
  }

  function animateCurve() {
    svgCurveEl.querySelectorAll("path").forEach((path) => {
      if (path.classList.contains("active-phase")) return;
      var length = Math.ceil(path.getTotalLength());
      path.style.strokeDasharray = length + " " + length;
      path.style.strokeDashoffset = length + " ";
      path.animate({ strokeDashoffset: 0 }, { duration: 1000, fill: "forwards", easing: "ease-in" });
    });
  }

  function calcProgressWidth(phase: number, progress: number, deps: any): number {
    let phaseName = stageToPhase(phase);
    if (!phaseName) {
      console.warn("No phaseName found for phase", phase);
      return 0;
    }
    console.assert(mainEl, "sectionsEl is undefined");
    let el = mainEl?.querySelector<HTMLDivElement>(`[data-phase="${phaseName}"]`);
    if (el) return el.offsetLeft + el.clientWidth * progress;
    else console.warn("No element found for phase", phaseName);
    return 0;
  }

  function roundToMinute(d: number): number {
    return Math.round(d / 60) * 60;
  }

  function collectGridColumns(el: HTMLDivElement, data: AutoProgram): string {
    if (!el || !data) return "";
    let sections = mainEl.querySelectorAll<HTMLDivElement>("[data-phase]");
    if (!sections) return "";
    let result: string[] = [];
    for (let i = 0; i < sections.length; i++) {
      switch (sections[i].dataset.phase) {
        case Phase.thawing:
          result.push("min(20rem)");
          break;
        case Phase.after:
        case Phase.preCooling:
          result.push("min-content");
          break;
        case Phase.end:
          result.push("3rem");
          break;
        default:
          result.push("auto");
          break;
      }
    }
    return result.join(" ");
  }

  function setGlitchTimer() {
    glitchTimer = window.setTimeout(executeGlitch, Math.max(30 * 1000, Math.round(Math.random() * 12 * 60 * 60 * 1000)));
  }

  // Easter egg!
  // Glitch the lights every now and then
  function executeGlitch() {
    let sectionsWithLight = mainEl.querySelectorAll(".light");
    if (sectionsWithLight.length === 0) return;
    let target = sectionsWithLight[Math.round(Math.random() * (sectionsWithLight.length - 1))];
    let keyframes = [];
    for (let i = 0; i < Math.round(Math.random() * 4) + 1; i++) {
      keyframes.push({
        "--light-color": i % 2 ? "white" : "transparent",
      });
    }
    target.animate(keyframes, { duration: 100 + Math.random() * 500 });
    setGlitchTimer();
  }

  export function hhmmString(d: number): string {
    let negative = d < 0;
    let s = $hhmm(Math.abs(d));
    if (negative) s = "-" + s;
    return s;
  }

  function tagTimeString(secs: number, ...deps: any): string {
    if (editMode) {
      secs = -secs;
      let neg = secs < 0;
      secs = Math.abs(secs);
      let h = Math.floor(secs / 3600);
      let m = Math.floor((secs / 60) % 60);
      let s = `${pad2(h)}:${pad2(m)}`;
      if (neg) s = "-" + s;
      return s;
    }
    if (bakingTime.getTime() <= 0) return "--:--";
    let date = new Date(bakingTime.getTime() - secs * 1000);
    return $hhmm(date);
  }

  export function endTimeString(d: number, ...deps: any): string {
    let date = new Date(d * 1000);
    return $hhmm(date);
  }

  function stageToPhase(stage: Stage): string {
    switch (stage) {
      case Stage.preCooling:
        return Phase.preCooling;
      case Stage.retarding:
        return Phase.retarding;
      case Stage.storage1:
        return Phase.storage1;
      case Stage.storage2:
        return Phase.storage2;
      case Stage.thawing:
        return Phase.thawing;
      case Stage.proofing:
        return Phase.proofing;
      case Stage.cooling:
        return Phase.cooling;
      case Stage.after:
        return Phase.after;
      default:
        return undefined;
    }
  }

  function onPointerDown(event: PointerEvent) {
    let el = event.target as HTMLDivElement;
    if (!el) return;
    let phase = el.getAttribute("data-phase");
    if (!phase) return;
    if (phase === selectedPhase) {
      selectedPhase = undefined;
      return;
    }
    selectedPhase = phase;
  }

  function getDrawingActions(): DrawAction[] {
    const scaleCelciusToHeight = linear([MIN_TEMP_C, MAX_TEMP_C], [height, 0]);
    const scaleY = (t: number) => scaleCelciusToHeight(ensureCelcius(t));
    const toRiseFactor = (d: number) => {
      d = Math.abs(d);
      if ($usesFarenheight) d /= 1.8;
      return Math.min(1, RISE_FACTOR * d);
    };
    let sections = mainEl.querySelectorAll<HTMLDivElement>("[data-phase]");
    if (!sections) return [];

    let actions: DrawAction[] = [];

    const addMove = (i: number, x: number, y: number) => actions.push({ cmd: Cmd.move, x: sections[i].offsetLeft + x, y });
    const addLine = (i: number, x: number, y: number) => actions.push({ cmd: Cmd.line, x: sections[i].offsetLeft + x, y });
    const addGoal = (i: number, x: number, y: number) => actions.push({ cmd: Cmd.goal, x: sections[i].offsetLeft + x, y });

    for (let i = 0; i < sections.length; i++) {
      let el = sections[i];
      let phase = el.getAttribute("data-phase");
      let w = el.clientWidth;
      switch (phase) {
        case Phase.preCooling:
          addMove(i, 0, scaleCelciusToHeight(20)); // Pre-cooling is always 20C
          addLine(i, w, scaleY(data.pre_cooling.t)); // Pre-cooling is always 20C
          break;
        case Phase.retarding: {
          let d = toRiseFactor(data.pre_cooling.t - data.retarding.t);
          addLine(i, w * d, scaleY(data.retarding.t));
          addLine(i, w, scaleY(data.retarding.t));
          break;
        }
        case Phase.storage1: {
          let d = toRiseFactor(data.retarding.t - data.storage1.t);
          addLine(i, w * d, scaleY(data.storage1.t));

          // let x = w * 0.5;
          // addLine(i, x, scaleY(data.storage1.t));
          // addMove(i, x - 5, scaleY(data.storage1.t) + 12);
          // addLine(i, x + 5, scaleY(data.storage1.t) - 12);

          // x = w * 0.6;
          // addMove(i, x - 5, scaleY(data.storage1.t) + 12);
          // addLine(i, x + 5, scaleY(data.storage1.t) - 12);
          // addMove(i, x, scaleY(data.storage1.t));

          addLine(i, w, scaleY(data.storage1.t));
          break;
        }
        case Phase.storage2: {
          if (!data.storage2) break;
          let d = toRiseFactor(data.storage1.t - data.storage2.t);
          addLine(i, w * d, scaleY(data.storage2.t));
          addLine(i, w, scaleY(data.storage2.t));
          break;
        }
        case Phase.thawing: {
          if (data.thawing.curve === Curve.adapThawClassic) {
            let startTemp = ensureCelcius(data.storage2 ? data.storage2.t : data.storage1.t);
            let endTemp = ensureCelcius(data.proofing.t);
            let factor = Math.max(0.1869 * data.thawing.factor - 0.1227, 0.001);

            let { points, totalSeconds } = getAdapThawCurvePoints(startTemp, endTemp, factor);
            calculatedThawingDuration = totalSeconds;

            for (let { x, y } of points) addLine(i, w * x, scaleCelciusToHeight(startTemp + (endTemp - startTemp) * y));
          } else if (data.thawing.curve === Curve.adapThawLinear) {
            calculatedThawingDuration = data.thawing.factor * 60 * 60; // @@@ FIXME: Calculate this currectly
          } else if (data.thawing.curve === Curve.adapThawTime) {
            calculatedThawingDuration = data.thawing.duration;
            // let startTemp = ensureCelcius(data.storage2 ? data.storage2.t : data.storage1.t);
            // let endTemp = ensureCelcius(data.proofing.t);
            // let { points, totalSeconds } = getClassicCurvePoints(startTemp, endTemp, data.thawing.curve);
            // calculatedThawingDuration = totalSeconds;
            // for (let { x, y } of points) addLine(i, w * x, scaleCelciusToHeight(y));
          } else {
            // TODO: Draw custom curves
            // TODO: Draw custom curves
            // TODO: Draw custom curves
          }
          break;
        }
        case Phase.proofing: {
          addLine(i, 0, scaleY(data.proofing.t));
          addLine(i, w, scaleY(data.proofing.t));

          let totalTime = data.proofing.duration;

          // // Draw goal flag at bake start time
          addGoal(i, (w * data.proofing.duration) / totalTime, scaleY(data.proofing.t));

          // Set where we draw the after phase symbol
          mainEl?.style.setProperty("--after-icon-y-position", `${scaleY(data.proofing.t)}px`);
          break;
        }
        case Phase.cooling: {
          if (data?.after && (data.after.mode !== AfterMode.storage || !data.after.add_cooling_curve)) {
            break;
          }
          addLine(i, 0, scaleY(data.proofing.t));
          addLine(i, w, scaleY(data.cooling.t));

          // Set where we draw the after phase symbol
          mainEl?.style.setProperty("--after-icon-y-position", `${scaleY(data.cooling.t)}px`);
          break;
        }
      }
    }

    return actions;
  }

  function drawCurve() {
    console.assert(mainEl, "sectionsEl is undefined");
    svgPath = "";
    let draw = getDrawingActions();
    let minY = +Infinity;
    for (let d of draw) {
      switch (d.cmd) {
        case Cmd.move:
          svgPath += `M${Math.round(d.x)},${Math.round(d.y)} `;
          if (d.y < minY) minY = d.y;
          break;
        case Cmd.line:
          svgPath += `L${Math.round(d.x)},${Math.round(d.y)} `;
          if (d.y < minY) minY = d.y;
          break;
        case Cmd.bezier:
          svgPath += `C${Math.round(d.x)},${Math.round(d.y)} `;
          break;
        case Cmd.goal:
          mainEl.style.setProperty("--finish-flag-y", `${d.y}px`);
      }
    }

    if (phase !== undefined) {
      let phaseName = stageToPhase(phase);
      console.assert(phaseName, "No phaseName found for phase", phase);
      let el = mainEl?.querySelector<HTMLDivElement>(`[data-phase="${phaseName}"]`);
      if (el) {
        //let l = calcProgressWidth(phase, progress, []);
        activePhaseLeft = el.offsetLeft;
        activePhaseRight = el.offsetLeft + el.clientWidth;
      }
    }
  }

  // Determine if the storage program has humidity enabled
  function hasHumidity(slot?: string) {
    if (!slot) return false;
    let program = $programs?.storage?.programs?.find((p) => p.slot === slot);
    if (!program) return false;
    return program.use_humidity;
  }

  function customScaleY(node: Element, options = { duration: 1000 }) {
    return {
      duration: options.duration,
      easing: cubicOut,
      css: (t: number) => `transform:scaleY(${t}); transform-origin: center;`,
    };
  }
</script>

<div
  class="curve-display"
  bind:this={mainEl}
  tabindex="-1"
  bind:clientHeight={height}
  on:pointerdown={onPointerDown}
  class:editMode
  style:--canvas-x-padding="{canvasXPadding}px"
  style:--zero-point-y="{zeroPointY}px"
  style:--active-phase-left="{activePhaseLeft}px"
  style:--active-phase-right="{activePhaseRight}px"
  style:--selected-phase-left="{selectedPhaseLeft}px"
  style:--selected-phase-right="{selectedPhaseRight}px"
  style:--grid-columns={sectionGridColums}
>
  <svg class="svg-overlay zero-line">
    <line x1="0" y1={zeroPointY} x2="100%" y2={zeroPointY} stroke="gray" stroke-width="2" stroke-dasharray="6 6" />
  </svg>

  <svg class="svg-overlay" bind:this={svgCurveEl}>
    <path class="above-zero" d={svgPath} />
    <path class="below-zero" d={svgPath} />
    {#if !editMode && phase !== undefined && showMarchingAnts}
      <path class="active-phase" d={svgPath} />
    {/if}
  </svg>

  <!-- PRE COOLING -->
  <div
    class:selected={selectedPhase === Phase.preCooling}
    data-phase={Phase.preCooling}
    class:light={triggerTransition && data.pre_cooling.light && editMode}
    class:uv={triggerTransition && data.pre_cooling.uv && editMode && $hasUV}
  >
    <div class="header">{lookupPhaseTitle(data, Phase.preCooling)}</div>
    <div class="sub temp">{$numFmt(data.pre_cooling.t, -1)}{$state.tempUnit}</div>
    {#if hasHumidity(data.pre_cooling.program)}
      <div class="sub">{$numFmt(data.pre_cooling.h, -1)}% <br /></div>
    {/if}
    {#if !editMode && endTimes?.preCooling}
      <div class="tag end-time">{@html endTimeString(endTimes.preCooling, $is12HourClock)}</div>
    {/if}
  </div>

  <!-- RETARDING -->
  <div
    class:selected={selectedPhase === Phase.retarding}
    data-phase={Phase.retarding}
    class:light={triggerTransition && data.retarding.light && editMode}
    class:uv={triggerTransition && data.retarding.uv && editMode && $hasUV}
  >
    <div class="header">{lookupPhaseTitle(data, Phase.retarding)}</div>
    <div class="sub temp">{$numFmt(data.retarding.t, -1)}{$state.tempUnit}</div>
    {#if hasHumidity(data.retarding.program)}
      <div class="sub">{$numFmt(data.retarding.h, -1)}% <br /></div>
    {/if}
    {#if data.retarding.proceed.action === ProceedAction.Duration}
      <div class="tag duration">{durationString(data.retarding.proceed.value)}</div>
    {/if}
    {#if !editMode && endTimes?.retarding}
      <div class="tag end-time">{@html endTimeString(endTimes.retarding, $is12HourClock)}</div>
    {/if}
  </div>

  {#if data.storage2}
    <!-- STORAGE 1-->
    <div
      class:selected={selectedPhase === Phase.storage1}
      data-phase={Phase.storage1}
      class:light={triggerTransition && data.storage1.light && editMode}
      class:uv={triggerTransition && data.storage1.uv && editMode && $hasUV}
    >
      <div class="header">{lookupPhaseTitle(data, Phase.storage1)}</div>
      <div class="sub temp">{$numFmt(data.storage1.t, -1)}{$state.tempUnit}</div>
      {#if hasHumidity(data.storage1.program)}
        <div class="sub">{$numFmt(data.storage1.h, -1)}% <br /></div>
      {/if}
    </div>

    <!-- STORAGE 2-->
    <div
      class:selected={selectedPhase === Phase.storage2}
      data-phase={Phase.storage2}
      class:light={triggerTransition && data.storage2.light && editMode}
      class:uv={triggerTransition && data.storage2.uv && editMode && $hasUV}
    >
      <div class="header">{lookupPhaseTitle(data, Phase.storage2)}</div>
      <div class="sub temp">{$numFmt(data.storage2.t, -1)}{$state.tempUnit}</div>
      {#if hasHumidity(data.storage2.program)}
        <div class="sub">{$numFmt(data.storage2.h, -1)}% <br /></div>
      {/if}
      <div class="tag duration">{durationString(data.storage2.duration)}</div>
      {#if bakingTime.getTime() || editMode}
        <div class="tag time">
          {@html tagTimeString(data.storage2.duration + thawingDuration + data.proofing.duration, $locale, bakingTime)}
        </div>
      {/if}
    </div>
  {:else}
    <!-- STORAGE 1-->
    <div
      class:selected={selectedPhase === Phase.storage1}
      data-phase={Phase.storage1}
      class:light={triggerTransition && data.storage1.light && editMode}
      class:uv={triggerTransition && data.storage1.uv && editMode && $hasUV}
    >
      <div class="header">{lookupPhaseTitle(data, Phase.storage1)}</div>
      <div class="sub temp">{$numFmt(data.storage1.t, -1)}{$state.tempUnit}</div>
      {#if hasHumidity(data.storage1.program)}
        <div class="sub">{$numFmt(data.storage1.h, -1)}% <br /></div>
      {/if}
    </div>
  {/if}

  <!-- THAWING -->
  <div
    class:selected={selectedPhase === Phase.thawing}
    data-phase={Phase.thawing}
    class:light={triggerTransition && data.thawing.light && editMode}
  >
    <div class="header">{lookupPhaseTitle(data, Phase.thawing)}</div>
    <div class="sub temp">
      {lookupCurveName(data.thawing.curve)}
      {#if editMode && (data.thawing.curve === Curve.adapThawClassic || data.thawing.curve === Curve.adapThawLinear)}
        &nbsp;(f={$numFmt(data.thawing.factor, -1)})
      {/if}
    </div>
    <div class="sub">
      {$numFmt(data.thawing.h, -1)}%
    </div>
    <div class="tag duration">{calculatedThawingTimeString}</div>
    {#if bakingTime.getTime() || editMode}
      <div class="tag time">{@html tagTimeString(thawingDuration + data.proofing.duration, $locale, bakingTime)}</div>
    {/if}
  </div>

  <!-- PROOFING -->
  <div
    class:selected={selectedPhase === Phase.proofing}
    data-phase={Phase.proofing}
    class:light={triggerTransition && data.proofing.light && editMode}
  >
    <div class="header">{lookupPhaseTitle(data, Phase.proofing)}</div>
    <div class="sub temp">{$numFmt(data.proofing.t, -1)}{$state.tempUnit}</div>
    <div class="sub">{$numFmt(data.proofing.h, -1)}% <br /></div>
    <div class="tag duration">{durationString(data.proofing.duration)}</div>
    {#if bakingTime.getTime() || editMode}
      <div class="tag time">{@html tagTimeString(data.proofing.duration, $locale, bakingTime)}</div>
    {/if}

    <div class="zero-line-text" style:top={zeroPointY + "px"} style:left="50%" style:z-order="10">
      {$usesFarenheight ? 32 : 0}{$state.tempUnit}
    </div>
    <svg class="finish-flag" viewBox="0 0 100 100">
      <path
        fill="white"
        d="M50 0a3.5 3.5 0 0 0-3.5 3.5v80A3.5 3.5 0 0 0 50 87a3.5 3.5 0 0 0 3.5-3.5V47h43a3.5 3.5 0 0 0 3.5-3.5v-40A3.5 3.5 0 0 0 96.5 0h-46a3.5 3.5 0 0 0-.254.01A3.5 3.5 0 0 0 50 0m13.8 7h9.8v7.43h9.8V7H93v7.43h-9.6v9.799H93v9.8h-9.6V40h-9.8v-5.97h-9.8V40H54v-5.97h9.8v-9.801H54v-9.8h9.8zm0 7.43v9.799h9.8v-9.8zm9.8 9.799v9.8h9.8v-9.8z"
      />
    </svg>
  </div>

  {#if data?.after && data.after.mode === AfterMode.storage && data.after.add_cooling_curve}
    <!-- COOLING -->
    <div
      class:selected={selectedPhase === Phase.cooling}
      data-phase={Phase.cooling}
      class:light={triggerTransition && data.cooling.light && editMode}
      class:uv={triggerTransition && data.cooling.uv && editMode && $hasUV}
    >
      <div class="header">{lookupPhaseTitle(data, Phase.cooling)}</div>
      <div class="sub temp">{$numFmt(data.cooling.t, -1)}{$state.tempUnit}</div>
      {#if hasHumidity(data.cooling.program)}
        <div class="sub">{$numFmt(data.cooling.h, -1)}% <br /></div>
      {/if}

      <div class="tag duration">{coolingTime === +Infinity ? ">24h" : durationString(coolingTime)}</div>
      {#if bakingTime.getTime() || editMode}
        <div class="tag time">{@html editMode ? tagTimeString(0, $locale, bakingTime) : $hhmm(bakingTime)}</div>
      {/if}
    </div>
  {/if}

  {#if data?.after && editMode}
    <!-- AFTER -->
    <div
      class:selected={selectedPhase === Phase.after}
      data-phase={Phase.after}
      class:light={triggerTransition && data.after.light && editMode}
      class:uv={triggerTransition && data.after.uv && editMode && data.after.mode === AfterMode.storage && $hasUV}
    >
      <!-- <div class="header">{lookupPhaseTitle(data, Phase.afterProofing)}</div> -->
      <div class="header">{getAfterModeString(data.after.mode)}</div>
      {#if data.after.mode === AfterMode.proofing}
        <div class="sub temp">
          {$numFmt(data.after.t, -1)}{$state.tempUnit}
        </div>
        <div class="sub">
          {$numFmt(data.after.h, -1)}%
        </div>
      {:else if data.after.mode === AfterMode.storage}
        <div class="sub temp">
          {$numFmt(data.after.t, -1)}{$state.tempUnit}
        </div>
        {#if hasHumidity(data.after.program)}
          <div class="sub">{$numFmt(data.after.h, -1)}% <br /></div>
        {/if}
      {/if}
      <div class="tag time">
        <!--- NOTE: coolingTime is negative here because tagTimeString inverts the sign -->
        {@html coolingTime === +Infinity ? "--:--" : tagTimeString(-coolingTime, $locale)}
      </div>
      <!--
          Draw icon symbol for the after action. The y-position is set set by the --after-icon-y-position CSS variable.
        -->
      <img src="/img/right-arrow.svg" draggable="false" class="after-icon-pre" />
      {#if data.after.mode === AfterMode.standby}
        <img src="/img/menu-ikon-standby.svg" draggable="false" class="after-icon" />
      {:else if data.after.mode === AfterMode.proofing}
        <img src="/img/menu-ikon-raskning.svg" draggable="false" class="after-icon" />
      {:else if data.after.mode === AfterMode.storage}
        <img src="/img/menu-ikon-lager.svg" draggable="false" class="after-icon" />
      {/if}
    </div>
  {:else}
    <div data-phase={Phase.end}>
      {#if bakingTime.getTime() || editMode}
        <div class="tag time">
          {@html coolingTime === +Infinity ? "--:--" : $hhmm(bakingTime.getTime() + coolingTime * 1000)}
        </div>
      {/if}
    </div>
  {/if}

  {#if !editMode && phase !== undefined && progress !== undefined && mainEl}
    <div
      class="progress-overlay"
      style:--progress-width={`${calcProgressWidth(phase, progress, [data, mainEl, redrawTrigger, sectionGridColums])}px`}
      in:fade={{ duration: 500 }}
    >
      <div class="inner" />
    </div>
  {/if}

  <!-- BAKING TIME SIGN-->
  {#if !editMode && mainEl && bakingTime?.getTime() >= Date.now()}
    <div class="baking-time" in:fly={{ duration: 500, y: 50 }}>
      {$_("auto_mode.Baking")}:
      {$shortDayFmt(bakingTime)}
      {$shortDateFmt(bakingTime)}
      {$timeFmt(bakingTime, { seconds: false })}
    </div>
  {/if}

  <!-- PANEL SIDE INDICATOR -->
  {#if selectedPhase}
    <div class="click-to-close" transition:fade={{ duration: 200 }}>
      {#if panelSide === "left"}
        <!-- https://api.iconify.design/material-symbols:left-panel-close-rounded.svg -->
        <svg viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M16.5 14.8V9.2q0-.35-.3-.475t-.55.125L13.2 11.3q-.3.3-.3.7t.3.7l2.45 2.45q.25.25.55.125t.3-.475M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v14q0 .825-.587 1.413T19 21zm5-2h9V5h-9z"
          />
        </svg>
      {:else if panelSide === "right"}
        <!-- https://api.iconify.design/material-symbols:right-panel-close-rounded.svg -->
        <svg viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M7.5 14.8q0 .35.3.475t.55-.125l2.45-2.45q.3-.3.3-.7t-.3-.7L8.35 8.85q-.25-.25-.55-.125t-.3.475zM5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v14q0 .825-.587 1.413T19 21zm9-2V5H5v14z"
          />
        </svg>
      {/if}
    </div>
  {/if}
</div>

<style lang="scss">
  @use "../../../styles/variables.scss" as *;

  .curve-display {
    --grid-columns: auto auto auto auto min(20rem) auto auto auto;

    position: relative;
    height: 100%;
    width: 100%;
    isolation: isolate;
    background-color: var(--background, $menu-background);

    display: grid;
    grid-template-columns: var(--grid-columns);

    > [data-phase] {
      --light-color: white;
      all: initial;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      font-family: hmiFont;
      font-size: 1.8rem;
      position: relative;
      overflow: visible;
      pointer-events: none;
      border-inline: 1px dotted $primary-dimmed;

      > * {
        pointer-events: none;
        text-shadow: 0 0 5px $background;
      }

      > :first-child {
        margin-block: 1rem;
      }

      > .header {
        font-family: hmiFontBold;
        color: white;
        white-space: nowrap;
      }

      > .sub {
        text-align: center;
        color: white;
        line-height: 1.4;
        &.temp {
          color: orange;
        }
      }

      &[data-phase] {
        padding-inline: 1rem;
      }

      &::before,
      &::after {
        content: "";
        opacity: 0;
        position: absolute;
        overflow: hidden;
        top: 0;
        inset-inline: auto;
        width: 100%;
        height: 100%;

        transition: opacity 0.5s ease-in-out;
        pointer-events: none;
      }

      &::before {
        background: url(/img/halo.png) no-repeat top center;
        background-position: center -60px;
      }

      &::after {
        mask: url(/img/halo.png) no-repeat top right;
        mask-size: 10rem 2rem;
        background-color: blueviolet;
      }

      &.light::before,
      &.uv::after {
        opacity: 0.7;
      }

      @keyframes fade-in {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      .tag {
        position: absolute;
        z-index: 1;

        font-size: 1.4rem;
        color: darken($primary, 20%);
        font-family: hmiFont;
        text-shadow: none;

        padding: 0.1rem 0.5rem;
        border: 0.2rem solid $primary-dimmed;
        background-color: $background;
        border-radius: 0.5rem;

        text-wrap: nowrap;
        text-align: center;

        bottom: 0.5rem;

        opacity: 0;
        animation: fade-in 0.5s ease-in forwards;
        animation-delay: var(--animation-delay, 0s);

        &.duration {
          margin-inline: 0;
          text-align: center;
          border-color: transparent;
        }

        &.time {
          transform: translateX(-50%);
          left: 0;
          color: $company;
          margin-block: 0;
        }
        &.end-time {
          transform: translateX(50%);
          right: 0;
          color: $company;
          margin-block: 0;
        }
      }
    }

    > [data-phase="__end"] {
      border-right: none;
    }

    .progress-overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: var(--progress-width, 0);
      z-index: 1;
      backdrop-filter: grayscale(100%);
      pointer-events: none;

      .inner {
        position: absolute;
        inset: 0 -1rem 0 0;
        background: linear-gradient(to left, transparent, rgb(255, 255, 255, 0.25) 1rem);
      }
    }

    &:has(.selected) > [data-phase]:not(.selected) {
      filter: brightness(0.6);
      backdrop-filter: brightness(0.6);
    }
    .selected {
      .tag,
      .finish-flag {
        filter: brightness(0.6);
      }
    }

    .after-icon-pre {
      position: absolute;
      left: 0.2rem;
      top: var(--after-icon-y-position, 10rem);
      width: 2rem;
      height: auto;
      transform: translateY(-50%);
    }
    .after-icon {
      position: absolute;
      left: 2.5rem;
      right: auto;
      top: var(--after-icon-y-position, 10rem);
      width: 3rem;
      height: auto;
      transform: translateY(-50%);
    }

    :global(.ampm) {
      font-size: 80%;
      vertical-align: baseline;
      opacity: 0.8;
      color: white;
    }

    .baking-time {
      position: absolute;
      text-transform: capitalize;
      font-family: hmiFont;
      font-size: 1.6rem;
      top: 7rem;
      left: 2rem;
      color: $primary;
      border: 0.2rem solid $primary-dimmed;
      background-color: $background;
      border-radius: 0.5rem;
      padding: 0.4rem 0.8rem;
      z-index: 1;
    }

    &:not(.editMode) {
      [data-phase] {
        font-size: 2rem;
        .tag {
          font-size: 1.8rem;
          &.duration {
            display: none;
          }
        }
      }
      .header {
        display: none;
      }
    }
    &.editMode {
      [data-phase] {
        border: 1px solid rgba(255, 255, 255, 0.2);
        pointer-events: all;
      }
    }
  }

  svg {
    pointer-events: none;
  }

  .svg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;

    path {
      stroke-width: 10;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: none;

      &.above-zero {
        stroke: orange;
        clip-path: rect(0 100% var(--zero-point-y) 0) view-box;
      }
      &.below-zero {
        stroke: hsl(209, 72%, 79%);
        clip-path: rect(var(--zero-point-y) 100% 100% 0) view-box;
      }
      &.active-phase {
        opacity: 0;
        stroke: rgba(0, 0, 0, 0.8);
        mix-blend-mode: soft-light;
        stroke-dasharray: 0 15;
        stroke-dashoffset: 15;
        animation:
          dash 2s linear infinite,
          fade-in 0.5s ease-out forwards 1s;
        clip-path: rect(0 var(--active-phase-right) 100% var(--active-phase-left)) view-box;
      }

      @keyframes fade-in {
        to {
          opacity: 1;
        }
      }
      @keyframes dash {
        to {
          stroke-dashoffset: 0;
        }
      }
    }
  }

  .zero-line {
    z-index: -1;
  }
  .zero-line-text {
    position: absolute;
    padding: 0.8rem;
    font-family: hmiFont;
    font-size: 2rem;
    background-color: var(--background, $menu-background);
    color: $primary-dimmed;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  .finish-flag {
    position: absolute;
    right: 0;
    top: var(--finish-flag-y, 80px);
    width: 3.6rem;
    height: auto;
    transform: translate(50%, -110%);
    z-index: 1;
  }

  .click-to-close {
    pointer-events: none;
    position: absolute;
    top: 85%;
    transform: translateY(-50%);
    left: calc(var(--selected-phase-left));
    width: calc(var(--selected-phase-right) - var(--selected-phase-left));
    display: flex;
    justify-content: center;

    svg {
      width: 4rem;
      height: auto;
      color: rgba(255, 255, 255, 0.5);
    }
  }
</style>
