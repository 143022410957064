<script lang="ts">
  import { _, locale } from "svelte-i18n";
  import { decimalSeparator } from "@/stores";
  import Dialog from "./Dialog.svelte";
  import BackspaceIcon from "@/svg/BackspaceIcon.svelte";
  import PlusMinusIcon from "@/svg/PlusMinusIcon.svelte";
  import { numFmt } from "@/stores";

  export let title: string;
  export let value: number;
  export let min: number | undefined;
  export let max: number | undefined;
  export let digit: boolean = true;
  export let neg: boolean = true;
  export let info: string | undefined;
  export let result: number | null = null;

  let origValue = value;
  let input = "";
  let allowBackspace = false;

  if (min !== undefined) min += 0; // Ensure we dont show -0
  if (max !== undefined) max += 0; // Ensure we dont show -0

  function onClick(event: MouseEvent) {
    let el = event.target as HTMLButtonElement;
    if (!el) return;
    if (el.tagName !== "BUTTON") return;
    let fn = el.dataset.fn;
    if (fn) {
      switch (fn) {
        case "neg":
          if (input.slice(0, 1) !== "-") input = "-" + input;
          else input = input.slice(1);
          break;
        case "del":
          if (input.length > 0) {
            input = input.slice(0, -1);
          }
          break;
      }
    } else {
      if (input.length >= 5) return;

      let text = el.textContent;
      if (text === $decimalSeparator && input.indexOf($decimalSeparator) !== -1) return;
      input += text;
    }

    if (input.length === 1) {
      allowBackspace = true;
    } else if (input.length === 0) allowBackspace = false;

    if (input === "-") {
      result = null;
      return;
    }

    result = input.length > 0 ? +input.replace($decimalSeparator, ".") : null;
    value = input.length ? result : origValue;
    if (min !== undefined && value < min) value = min;
    if (max !== undefined && value > max) value = max;
  }
</script>

<Dialog {title} returnValue={result}>
  <div class="numpad-container">
    {#if info}
      <div class="info">{info}</div>
    {/if}
    <div class="display">
      <div class="range">
        {#if min !== undefined && max !== undefined}
          <div>Max: {$numFmt(max, -1)}</div>
          <div>Min: {$numFmt(min, -1)}</div>
        {/if}
      </div>
      {#if input}
        <div class="value">{input}</div>
      {:else}
        <div class="value dim">{$numFmt(value, -1)}</div>
      {/if}
    </div>
    <div class="keys" on:click|preventDefault={onClick}>
      <button type="button">7</button>
      <button type="button">8</button>
      <button type="button">9</button>
      <button type="button" data-fn="del" disabled={!allowBackspace} style="padding: 5px"><BackspaceIcon height="3rem" /></button>
      <button type="button">4</button>
      <button type="button">5</button>
      <button type="button">6</button>
      <button type="button" style="grid-column: 1">1</button>
      <button type="button">2</button>
      <button type="button">3</button>
      <button type="button" style="grid-column: 1 / span 2">0</button>
      <button type="button" disabled={!digit}>{$decimalSeparator}</button>
      <button type="button" data-fn="neg" disabled={!neg} style="padding: 5px">
        <PlusMinusIcon height="3rem" />
      </button>
    </div>
  </div>
</Dialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .numpad-container {
    display: flex;
    flex-direction: column;
    padding: $dialog-padding;
    font-family: hmiFont;
    min-width: 31rem;
  }

  .info {
    font-size: 70%;
    margin-inline: $dialog-padding;
    padding-bottom: 10px;
  }

  .display {
    display: grid;
    grid-template-columns: auto 1fr;
    margin-bottom: $dialog-padding;
    padding-inline: 1rem;
    background-color: $background;
    gap: 1rem;

    .range {
      margin-block: auto;
      font-family: hmiFont;
      font-size: 70%;
      text-align: center;
      line-height: 1.5;
      opacity: 0.7;
      :first-child {
        border-bottom: 1px solid $primary-dimmed;
      }
    }
    .value {
      font-family: hmiFontBold;
      font-size: 220%;
      text-align: right;
    }
  }

  .keys {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--keys-gap);
    * {
      text-align: center;
      min-width: var(--keys-size);
      height: var(--keys-size);
      padding: $dialog-padding;
      font-size: inherit;
    }

    :global(button > *) {
      margin-inline: auto;
      pointer-events: none;
    }
  }
</style>
