<script lang="ts">
  import { Phase, type AutoProgram } from "./auto";
  import EditAfterProofing from "./EditAfterProofing.svelte";
  import EditCooling from "./EditCooling.svelte";
  import EditPreCooling from "./EditPreCooling.svelte";
  import EditProofing from "./EditProofing.svelte";
  import EditRetarding from "./EditRetarding.svelte";
  import EditStorage1 from "./EditStorage1.svelte";
  import EditStorage2 from "./EditStorage2.svelte";
  import EditThawing from "./EditThawing.svelte";

  export let selectedPhase: string;
  export let editProps: any;
  export let data: AutoProgram;
  export let calculatedTimeString: string;
</script>

{#if selectedPhase === Phase.preCooling}
  <EditPreCooling bind:data on:change {...editProps} />
{:else if selectedPhase === Phase.retarding}
  <EditRetarding bind:data on:change {...editProps} />
{:else if selectedPhase === Phase.storage1}
  <EditStorage1 bind:data on:change on:add {...editProps} />
{:else if selectedPhase === Phase.storage2 && !!data.storage2}
  <EditStorage2 bind:data on:change on:remove {...editProps} />
{:else if selectedPhase === Phase.thawing}
  <EditThawing bind:data {calculatedTimeString} on:change {...editProps} />
{:else if selectedPhase === Phase.proofing}
  <EditProofing bind:data on:change {...editProps} />
{:else if selectedPhase === Phase.cooling}
  <EditCooling bind:data on:change {...editProps} />
{:else if selectedPhase === Phase.after}
  <EditAfterProofing bind:data on:change {...editProps} />
{/if}
