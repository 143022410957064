<script>
  export let color = "currentColor";
</script>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...$$restProps}>
  <path
    fill={color}
    d="M21.5 3.75a.75.75 0 0 0-1.5 0v3.4c-1.154-1.388-2.65-2.563-4.528-3.145a11.185 11.185 0 0 0-7.233.192c-2.316.862-4.504 2.61-5.646 4.693a.75.75 0 1 0 1.315.72c.944-1.722 2.825-3.252 4.854-4.007a9.685 9.685 0 0 1 6.266-.165c1.702.527 3.071 1.664 4.13 3.062H15.25a.75.75 0 0 0 0 1.5h5.5a.75.75 0 0 0 .75-.75zM8 14a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5zm-2 .5a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2z"
  />
</svg>
