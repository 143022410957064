<script lang="ts">
  import dialog from "@/dialog";
  import KeyboardIcon from "@/svg/KeyboardIcon.svelte";
  import { createEventDispatcher } from "svelte";

  export let label = "";
  export let description = "";
  export let value: string;
  export let width = "30rem";
  export let labelWidth: string | undefined = undefined;
  export let max: number | undefined = undefined;
  export let lines = 1;
  export let initEmpty = false;
  export let disabled = false;

  let fire = createEventDispatcher();
  let dialogOpen = false;

  async function edit() {
    dialogOpen = true;
    let v = value;
    if (initEmpty) v = "";
    let r = await dialog.keyboard(description || label || "Text", v, max);
    dialogOpen = false;
    if (r !== null) {
      value = r;
      fire("change", r);
    }
  }

  const id = Math.random().toString(36).slice(2, 9);
</script>

<div class="text-input-container">
  {#if label}
    <label style:width={labelWidth} for={id}>{label}:</label>
  {/if}
  <div tabindex="-1" {id} class="text-input" style:width on:click={edit} class:dialogOpen style:--lines={lines} class:disabled>
    <span>{value}</span>
    {#if lines === 1}
      <KeyboardIcon height="80%" />
    {/if}
  </div>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .text-input-container {
    --height: var(--controls-height);

    pointer-events: all;
    gap: 1rem;
    font-size: 2.2rem;
  }

  .text-input {
    display: flex;
    height: calc(var(--height) * var(--lines));
    border-radius: calc(var(--height) / 8);
    padding: 0.5rem 1rem;
    transition: border 0.2s ease-in-out;
    align-items: center;
    background-color: var(--clr-input-field-background);

    :first-child {
      flex-grow: 1;
    }
    :global(svg) {
      transition: color 0.2s ease-in-out;
      color: $primary-dimmed;
    }

    border: 2px solid $primary-dimmed;
    &:active,
    &.dialogOpen {
      border: 2px solid $company;
      :global(svg) {
        color: $company;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
</style>
