<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { _, json, locale } from "svelte-i18n";

  import ListInput from "@/lib/ListInput.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import TextInput from "@/lib/TextInput.svelte";

  const dispatch = createEventDispatcher();

  export let prop: PropItem;
  export let value: any;
  export let localePrefix: string | undefined = undefined;

  let locText = $json(localePrefix, $locale) ?? {};
  function T(key: string) {
    return locText[key] ?? key;
  }

  console.assert(prop.type === "multi");

  if (value === undefined) {
    value = structuredClone(prop.default);
  }

  const yesno = [
    { text: $_("No"), value: false },
    { text: $_("Yes"), value: true },
  ];

  function onItemChange(item: PropItem) {
    if ((item.type === "int" || item.type === "float") && prop.limitMinMax) {
      if (item.path === "max") {
        if (value.max < value.min) {
          value.max = value.min;
          value = value; // Trigger update
        }
      }
      if (item.path === "min") {
        if (value.min > value.max) {
          value.min = value.max;
          value = value; // Trigger update
        }
      }
    }
    dispatch("change", { value });
  }
</script>

<section>
  {#if prop.type === "multi"}
    {#each prop.items as item}
      <label>{T(item.path)}:</label>
      {#if item.type === "int" || item.type === "float"}
        <NumberInput
          width="20rem"
          incdec
          bind:value={value[item.path]}
          on:change={() => onItemChange(item)}
          min={item.min}
          max={item.max}
          step={item.step || 1}
          negative={item.min < 0}
          digit={item.type === "float"}
        />
      {:else if item.type === "boolean"}
        <ListInput width="12rem" items={yesno} bind:selectedValue={value[item.path]} on:change />
      {:else if item.type === "list"}
        <ListInput width="24rem" items={item.items} bind:selectedValue={value[item.path]} on:change />
      {:else if item.type === "string"}
        <TextInput bind:value={value[item.path]} on:change />
      {/if}
    {/each}
  {/if}
</section>

<style lang="scss">
  section {
    display: grid;
    grid-template-columns: auto auto;
    gap: 2rem 1rem;
    align-items: center;
  }
</style>
