<script lang="ts">
  import { isEqual } from "lodash-es";
  import momentumSlider from "momentum-slider";

  const LINE_HEIGHT = 60;

  export let values: string[];
  export let selected: number;
  export let changing = false;
  export let wrap = false;

  let el: HTMLDivElement;
  let ms: momentumSlider;
  let lastValues: string[];
  let lastSelected = selected;

  $: if (el && !isEqual(values, lastValues)) {
    lastValues = values;
    el.innerHTML = "";
    ms = new momentumSlider({
      el: el,
      vertical: true,
      range: [0, values.length - 1],
      loop: wrap ? 2 : 0,
      style: {
        // Styles to interpolate as we move the slider
        // The first value corresponds to the adjacent elements
        // The second value corresponds to the current element
        // transform: [{ scale: [0.7, 1] }],
        opacity: [0.2, 1],
      },
      currentIndex: selected,
      rangeContent: (v: number) => {
        return values[v];
      },
      change: (i: number) => {
        if (i < 0 || i > values.length - 1) return;
        selected = i;
      },
    });
  }

  $: if (ms && !changing && selected !== lastSelected) {
    ms.select(selected);
    lastSelected = selected;
  }
</script>

<div class="wheel" style:--line-height="{LINE_HEIGHT}px" tabindex="-1">
  <div class="clip" on:pointerdown={() => (changing = true)}>
    <div bind:this={el}></div>
    <div class="framing" />
  </div>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .wheel {
    overflow: hidden;
    font-family: hmiFontBold;
    font-size: 3.2rem;
    line-height: var(--line-height);

    .clip {
      position: relative;
      height: calc(var(--line-height) * 3);

      .framing {
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: var(--line-height);
        top: var(--line-height);
        border-block: 3px solid $company;
      }
    }
  }

  :global(.ms-slide) {
    transform: translateY(var(--line-height));
  }
</style>
