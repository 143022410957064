<script lang="ts">
  import { state } from "@/hmi";
  import { numFmt, usesFarenheight } from "@/stores";
  import { _, json, locale } from "svelte-i18n";

  export let header: string;
  export let props: PropItem[];
  export let data: any;
  export let changeCounter: number;
  export let expanded: boolean;
  export let selected: string | undefined = undefined;
  export let localePrefix: string | undefined = undefined;
  export let sectionIndex: number;
  export let selectedSectionIndex: number;

  let locText = $json(localePrefix, $locale) ?? {};
  function T(key: string) {
    return locText[key] ?? key;
  }

  // We need to delay the changeCounter to avoid Svelte updates the DOM to early
  let changeCounterDelayed = changeCounter;
  $: if (changeCounterDelayed !== changeCounter) {
    queueMicrotask(() => (changeCounterDelayed = changeCounter));
  }

  function select(path: string) {
    if (selected === path) return;
    selected = path;
    selectedSectionIndex = sectionIndex;
  }

  $: if (changeCounter) {
    data = data; // Trigger DOM update of values
  }

  function getUnit(prop: PropItem) {
    if (prop.unit === "°C/F") {
      if (prop.isAbsTemp) return $usesFarenheight ? "°F" : "°C";
      else return $usesFarenheight ? "Δ°F" : "Δ°C";
    }
    return prop.unit;
  }

  function formatPropValue(prop: PropItem, value?: any) {
    switch (prop.type) {
      case "boolean":
        return value ? $_("Yes") : $_("No");
      case "int":
      case "float":
        return $numFmt(value, -2);
      case "string":
        return value;
      case "list":
        console.assert(prop.items);
        return T(prop.items.find((x) => x.value === value)?.text);
      case "multi": {
        // Combine multiple values + unit into one string separated by "/"
        if (!value) break;
        console.assert(prop.items && prop.items.length > 0);
        let a: string[] = [];
        for (let i = 0; i < prop.items.length; i++) {
          let s = formatPropValue(prop.items[i], value[prop.items[i].path]);
          if (s) {
            if (prop.items[i].unit) s += `<span class="unit">${getUnit(prop.items[i])}</span>`;
            a.push(s);
          }
        }
        return a.join("/");
      }
      case "defrost":
        switch (value.type) {
          case 1:
            return T("natural_time");
          case 2:
            return T("natural_sensor");
          case 3:
            return T("active");
          default:
            return T("none");
        }
      case "defrost_schedule":
        return value ? `(${value.length})` : "(0)";
      case "proofing_program":
      case "cooling_program":
        return value;
      case "storage_program_type":
      case "proofing_program_type":
        return value;
      case "setpoints":
        if (!value?.fixed) return "";
        return `${$numFmt(value.t, -1)}${$state.tempUnit}/${$numFmt(value.h, -1)}%`;
    }
    return "";
  }

  function checkCondition(prop: PropItem) {
    function scopeEval(scope: any, script: string) {
      return Function('"use strict";return (' + script + ")").bind(scope)();
    }
    if (!prop.condition) return true;
    return scopeEval(data, prop.condition) as boolean;
  }
</script>

<tr class="section">
  <td colspan="2" on:click={() => (expanded = !expanded)}>
    <div class="expand" class:expanded />
    {@html header}
  </td>
</tr>
{#if expanded}
  {#key changeCounterDelayed}
    {#each props as prop, i (prop.path)}
      {#if checkCondition(prop)}
        <tr
          class:selected={selected === prop.path}
          class:last={props.length === i + 1}
          on:click={() => select(prop.path)}
          data-prop-path={prop.path}
        >
          <td class="flex-line space-between">
            <span class="cap-first">{T(prop.path)}</span>
            <span class="value">
              {@html formatPropValue(prop, data[prop.path])}
              <span class="unit">{prop.unit ? getUnit(prop) : ""}</span>
            </span>
          </td>
        </tr>
      {/if}
    {/each}
  {/key}
{/if}

<style lang="scss">
  @use "../../styles/variables.scss" as *;

  .expand {
    display: inline-block;
    aspect-ratio: 1/1;
    width: 2rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='3 2 20 20' fill='none' stroke='lightgray' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(2px);
    &.expanded {
      transform: rotate(180deg) translateY(-1px);
    }
    &::first-letter {
      text-transform: uppercase;
    }
  }

  td:last-of-type {
    text-align: right;
  }

  .section {
    font-family: hmiFontBold;
    color: $company;
    background-color: $background;
    border: none;
    border-bottom: 0.3rem solid $menu-background;

    td {
      text-align: left;
    }
  }

  tr {
    border-bottom: 0.1rem solid rgba(255, 255, 255, 0.2);

    &.selected {
      background-color: $company;
      color: black;
    }
    &:active {
      background-color: lighten($background, 15%);
      &.selected {
        background-color: darken($company, 7%);
        color: black;
      }
    }

    &.last {
      border: none;
    }
  }

  td {
    padding: 0.5rem 1rem;
    &::first-letter {
      text-transform: uppercase;
    }

    & > span {
      text-align: left;
      white-space: nowrap;
    }
  }

  .value {
    font-size: 80%;
    white-space: nowrap;
  }
  :global(.unit) {
    font-size: 80%;
    opacity: 0.5;
  }
</style>
