<script lang="ts">
  import dialog from "@/dialog";
  import hmi, { curHumidityText, curTempText, state } from "@/hmi";
  import ToyotaButton from "@/lib/ToyotaButton.svelte";
  import { numFmt, timeFmt } from "@/stores";
  import EditIcon from "@/svg/EditIcon.svelte";
  import HeatBoostIcon from "@/svg/HeatBoostIcon.svelte";
  import { Events, clamp, eventBus } from "@/utils.js";
  import { debounce } from "lodash-es";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import { fade } from "svelte/transition";
  import ActiveDefrostMainOverlay from "./ActiveDefrostMainOverlay.svelte";
  import StatusPanel from "./StatusPanel.svelte";
  import StatusPanelButton from "./StatusPanelButton.svelte";

  export let setpointT = 0;
  export let setpointH = 0;
  export let mode: "proofing" | "storage";
  export let showHumidity = true;
  export let TRange: [min: number, max: number] = [0, 0];
  export let HRange: [min: number, max: number] = [0, 0];

  const humidityWarnColor = "#E74C3C";
  const fire = createEventDispatcher();

  let heatBoost = hmi.getValueStore("set.heat_boost");
  let allowHeatBoost = hmi.getValueStore("set.allow_heat_boost");
  let setActive = false;
  const defrostStatus = hmi.getObjectStore<DTO.DefrostStatus>("DefrostStatus");
  let turboCool = hmi.getValueStore("set.turbo_cool");
  let fixedSetpoints = hmi.getValueStore("set.FIXED");

  const debouncedClose = debounce(() => (setActive = false), 5000);
  const debouncedUpdate = debounce(() => fire("update", { setpointT, setpointH }), 500);

  // Override max humidity
  HRange[1] = 95;

  $: tempUnit = $state?.tempUnit || "";

  onMount(() => {
    const handler = () => {
      setActive = false;
      debouncedClose.cancel();
      debouncedUpdate.flush();
    };
    eventBus.on(Events.preProgramChange, handler);

    return () => {
      eventBus.detach(Events.preProgramChange, handler);
    };
  });

  onDestroy(() => {
    debouncedClose.cancel();
    debouncedUpdate.flush();
  });

  function toggleActive() {
    setActive = !setActive;
    if (setActive) debouncedClose();
    else debouncedUpdate.flush();
  }

  async function editTemp() {
    if (!setActive) return;
    debouncedClose.cancel();
    let result = await dialog.numpad($_("Temperature"), setpointT, { min: TRange[0], max: TRange[1], digit: true, neg: TRange[0] < 0 });
    if (result !== null && result !== setpointT) {
      setpointT = Math.floor(clamp(result, TRange[0], TRange[1]));
      debouncedUpdate();
    }
    debouncedClose();
  }

  async function editHumidity() {
    if (!setActive) return;
    debouncedClose.cancel();
    let result = await dialog.numpad($_("Humidity"), setpointH, { min: HRange[0], max: HRange[1], digit: false, neg: HRange[0] < 0 });
    if (result !== null && result !== setpointH) {
      setpointH = Math.floor(clamp(result, HRange[0], HRange[1]));
      debouncedUpdate();
    }
    debouncedClose();
  }

  function toggleHeatBoost() {
    $heatBoost = !$heatBoost ? 1 : 0;
  }

  function toggleTurboCool() {
    $turboCool = !$turboCool ? 1 : 0;
  }

  $: {
    setpointT;
    setpointH;
    debouncedUpdate();
    debouncedClose();
  }
</script>

<div class="proofing mode-{mode}">
  {#if $defrostStatus?.type === 3}
    <ActiveDefrostMainOverlay status={$defrostStatus} />
  {:else}
    <div class="grid" class:single={!showHumidity}>
      <div class="cur-value">
        {$curTempText}
      </div>
      {#if showHumidity}
        <div class="cur-value" style="grid-column:3">
          {$curHumidityText}
        </div>
      {/if}

      <div class="separator" class:half={!showHumidity} />

      {#if mode === "storage" && $defrostStatus}
        <div class="defrost" transition:fade>
          <img src="/img/defrost/DefrostBackground.png" width="240" height="240" draggable="false" />
          <img src="/img/defrost/DefrostDrop.webp" width="240" height="240" draggable="false" />
        </div>
      {/if}

      <div class="setpoint-value" style="grid-column: 1" class:edit={setActive} on:click={editTemp}>
        {$numFmt(setpointT)}{tempUnit}
      </div>
      {#if $fixedSetpoints}
        <div></div>
      {:else}
        <button on:click={toggleActive} class="set-button" class:active={setActive}><EditIcon width="80%" /></button>
      {/if}
      {#if showHumidity}
        <div
          class="setpoint-value"
          class:edit={setActive}
          on:click={editHumidity}
          style:color={setpointH > 88 ? humidityWarnColor : undefined}
        >
          {$numFmt(setpointH)}%
        </div>
      {/if}

      <ToyotaButton
        show={setActive}
        bind:value={setpointT}
        min={TRange[0]}
        max={TRange[1]}
        on:numpad-click={editTemp}
        style="grid-column: 1"
      />

      <ToyotaButton
        show={showHumidity && setActive}
        bind:value={setpointH}
        min={HRange[0]}
        max={HRange[1]}
        on:numpad-click={editHumidity}
      />
    </div>
  {/if}

  <StatusPanel>
    <svelte:fragment slot="info">
      {#if $state?.auto?.next?.id}
        {$_("autostart")}:
        {$timeFmt($state.auto.next.time * 1000, { seconds: false })}
        <br />
        {$state.auto.next.id}
      {/if}
    </svelte:fragment>
    <svelte:fragment slot="buttons">
      {#if mode === "proofing" && $allowHeatBoost}
        <StatusPanelButton text="Heat Boost" on:click={toggleHeatBoost} active={$heatBoost === 1}>
          <HeatBoostIcon />
        </StatusPanelButton>
      {:else if mode === "storage"}
        <!--
        <StatusPanelButton text="Turbo Cool" on:click={toggleTurboCool} active={$turboCool === 1}>
          <TurboCoolIcon />
        </StatusPanelButton>
        -->
      {/if}
    </svelte:fragment>
  </StatusPanel>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .proofing {
    display: grid;
    grid-template-rows: 1fr auto;
    height: 100%;
    text-align: center;

    .grid {
      margin: 0 auto;
      flex-grow: 1;
      display: grid;
      grid-template-columns: 1fr 8rem 1fr;
      grid-template-rows: min-content min-content 10rem 12rem;
      align-items: center;
      isolation: isolate;

      &.single {
        padding-left: 12rem;
        grid-template-columns: 36rem min-content 0;
      }

      .cur-value {
        font-family: hmiFontBold;
        font-size: 13rem;
        min-width: 35rem; // Give room for a value like "-22°C"
        align-self: flex-end; //  FIXME: Is this even valid for grid?
      }

      .setpoint-value {
        font-family: hmiFontThin;
        font-size: 6rem;
        transition:
          transform ease var(--animation-duration),
          color ease var(--animation-duration),
          text-shadow ease var(--animation-duration);
        transform-origin: 50% 0;
        &.edit {
          transform: scale(1.2);
          color: $company;
          text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
        }
      }

      .set-button {
        --color: hsl(from white h s calc(l / 2));
        justify-self: center; // Center the button in the possible smaller grid cell
        border: 0.5rem solid var(--color);
        color: var(--color);
        background-color: transparent;
        border-radius: 50%;
        font-size: 3rem;
        width: 7rem;
        height: 7rem;
        grid-row: span 2;
        z-index: 2; // So we can click it event if setpoint value is covering it after scaling
        transition: box-shadow ease var(--animation-duration);

        display: grid;
        place-items: center;

        &:active {
          border-color: $company;
        }
        &.active {
          background-color: $company;
          color: $background;
          box-shadow: 0.4rem 0.4rem 0.8rem rgba(0, 0, 0, 0.5);
          border-color: $company;
        }
      }

      .separator {
        height: 0.2rem;
        grid-column: 1 / -1;
        background: linear-gradient(
          90deg,
          transparent 0%,
          #56585a 10%,
          #56585a 40%,
          transparent 50%,
          #56585a 60%,
          #56585a 90%,
          transparent 100%
        );
        &.half {
          grid-column: 1;
          background: linear-gradient(90deg, transparent 0%, #56585a 20%, #56585a 80%, transparent 100%);
        }
      }
    }
  }

  .defrost {
    position: absolute;
    z-index: -1;
    left: 80px;
    bottom: calc($status-height + 0px);
    width: 240px;
    height: 240px;
    scale: 1.5;
    opacity: 0.3;

    > img {
      position: absolute;
    }
  }

  .storage-status-content {
    height: 100%;
    display: grid;
    justify-content: start;
    align-content: center;
  }
</style>
