<script lang="ts">
  import AlarmList from "@/config/info/AlarmList.svelte";
  import hmi, { hasPendingAlarm, programs } from "@/hmi";
  import { dateFmt, secondsTicker, shortDayFmt, timeFmt } from "@/stores";
  import AlarmIcon from "@/svg/AlarmIcon.svelte";
  import Door from "@/svg/Door.svelte";
  import MotionSensorIcon from "@/svg/MotionSensorIcon.svelte";
  import UsbDriveIcon from "@/svg/USBDriveIcon.svelte";
  import { dateNow, showDialog } from "@/utils";

  export let title: string = undefined;
  export let icon: string;
  export let program: string = undefined;

  const doorClosed = hmi.getValueStore("cur.door_switch");
  const usbDetected = hmi.getValueStore("sys.usb_detected");
  const motionDetect = hmi.getValueStore("cur.pir_sensor");

  let clockRef: HTMLElement;
  let curDate: Date;
  let titleIsProgram = title === undefined;
  let programType: string;

  $: allPrograms = ($programs?.auto?.programs ?? []).concat($programs?.proofing?.programs ?? []).concat($programs?.storage?.programs ?? []);

  $: if ($programs) {
    if (titleIsProgram) {
      let p = allPrograms.find((p) => p.slot === program);
      if (p) {
        title = p.name;
        programType = p.type;
      } else {
        title = "?";
        programType = "";
      }
    }
  }

  $: if (clockRef) {
    $secondsTicker;
    curDate = dateNow();
  }

  function showAlarmList() {
    if (!$hasPendingAlarm) return;
    showDialog(AlarmList);
  }
</script>

<div class="frame">
  <div class="header">
    <!-- ICON AND TITLE -->
    <div class="flex-line">
      <img src={icon} />
      <div class="title">
        <span class="name">{title}</span>
        {#if program}
          <span class="program">{program}{programType ? "-" + programType : ""}</span>
        {/if}
      </div>
    </div>
    <!-- CENTER ICONS -->
    <div class="icons">
      <div class:hide={$doorClosed}>
        <Door height="4rem" />
      </div>
      {#if $motionDetect}
        <div>
          <MotionSensorIcon height="4rem" />
        </div>
      {/if}
      {#if $usbDetected}
        <div>
          <UsbDriveIcon height="4rem" />
        </div>
      {/if}
      <div class="blink clickable" class:hide={!$hasPendingAlarm} on:click|stopPropagation={showAlarmList} style:color="#ffcc4d">
        <AlarmIcon height="4rem" />
      </div>
    </div>
    <!-- CLOCK -->
    <div class="clock" bind:this={clockRef}>
      {$shortDayFmt(curDate)}
      {$dateFmt(curDate)}
      <br />
      {@html $timeFmt(curDate)}
    </div>
    <div class="divider" />
  </div>
  <div class="content">
    <slot />
  </div>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .frame {
    display: grid;
    font-family: hmiFontBold;
    height: 100%;
    grid-template-rows: auto 1fr;
    justify-content: stretch;
    align-items: center;
    gap: 2rem;
    position: relative;

    .header {
      margin-left: 2rem;
      margin-right: 2rem;
      display: grid;
      grid-template-columns: 1fr auto max-content;
      align-items: center;
      padding: 0.5rem 0;
      gap: 2rem;
      position: relative;
      line-height: 1.1;

      img {
        height: 4rem;
      }
      .title {
        font-size: 4rem;
        white-space: nowrap;
        display: flex;
        align-items: baseline;
        justify-content: end;
        gap: 1rem;

        .name {
          text-transform: uppercase;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-top: 0.5rem;
        }

        .program {
          font-family: hmiFontThin;
          font-size: 1.8rem;
        }
      }

      .icons {
        color: var(--clr-primary-dimmed);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        z-index: 1; // NOTE: This is needed to make the icons clickable

        > div {
          border-radius: 2px;
          transition: opacity 0.2s ease-in-out;
          &.clickable:active {
            outline: 0.25rem solid var(--clr-company);
            outline-offset: 0.25rem;
          }

          &.hide {
            animation: none !important; // Needed to stop blinking animation messing with opacity
            opacity: 0;
          }
        }
      }

      .clock {
        text-transform: capitalize;
        text-align: right;
        font-size: 1.8rem;
        font-family: hmiFont;
      }
    }

    .content {
      height: 100%;
    }

    .divider {
      position: absolute;
      width: 100%;
      height: 100%;
      border-bottom-style: solid;
      border-bottom-width: 2px;
      border-image: linear-gradient(90deg, $primary-dimmed, $primary-dimmed, rgba(0, 0, 0, 0)) 1 0;
    }
  }
</style>
