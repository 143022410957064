<script lang="ts">
  import Button from "@/lib/Button.svelte";
  import DurationInput from "@/lib/DurationInput.svelte";
  import Switch from "@/lib/Switch.svelte";
  import DurationIcon from "@/svg/DurationIcon.svelte";
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";
  import StorageSettings from "./_StorageSettings.svelte";
  import { hasUV, type AutoProgram } from "./auto";

  $$restProps; // Get rid of "<xxx> was created with unknown prop" warning

  export let data: AutoProgram;
  export let minTemp: number;
  export let maxTemp: number;
  export let storageProgramItems: ListItem[];

  const dispatch = createEventDispatcher();

  $: storage = data.storage2;

  function triggerReactivity() {
    storage = storage;
    dispatch("change");
  }
</script>

<div class="edit-container">
  <StorageSettings data={storage} {minTemp} {maxTemp} {storageProgramItems} on:change={triggerReactivity} />

  <label>{$_("programs.auto_choice.duration")}:</label>
  <div class="flex-line">
    <DurationInput bind:value={storage.duration} hideSecond on:change />
    <DurationIcon height="3rem" color="var(--clr-field-icon)" />
  </div>

  <label>{$_("Light")}:</label>
  <Switch bind:checked={storage.light} on:change />

  {#if $hasUV}
    <label>{$_("UV")}:</label>
    <Switch bind:checked={storage.uv} on:change />
  {/if}

  <hr />

  <div class="whole-row flex-center pull-right">
    <Button size="small" on:click={() => dispatch("remove")}>{$_("programs.auto.remove_storage_phase")}</Button>
  </div>
</div>

<style lang="scss">
  .edit-container {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 2rem;
    align-content: start;
    align-items: center;
  }
</style>
