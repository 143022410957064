<script lang="ts">
  import { getContext, onMount } from "svelte";
  import { _ } from "svelte-i18n";

  export let title: string;
  export let returnValue: any = null;

  let { done } = getContext<DialogContext>("dialog");
  let ref: HTMLDialogElement | null = null;

  export function close(result: any) {
    done(ref!, result);
  }

  $: if (ref) {
    // Store dialog's position so transform-origin can be calculated in CSS
    ref.style.setProperty("--dialog-left", ref.offsetLeft + "px");
    ref.style.setProperty("--dialog-top", ref.offsetTop + "px");
  }

  onMount(() => {
    ref?.showModal();
  });
</script>

<dialog
  bind:this={ref}
  on:cancel|preventDefault={() => close(null)}
  on:close={() => close(null)}
  on:keydown|preventDefault
  on:keyup|preventDefault
>
  <div class="title"><div>{title}</div></div>
  <div class="body">
    <slot />
  </div>
  <div class="footer">
    <slot name="actions">
      <div class="button-group space-between">
        <button type="button" on:click={() => close(null)}>{$_("cancel")}</button>
        <button type="button" disabled={returnValue === null} on:click={() => close(returnValue)}>{$_("ok")}</button>
      </div>
    </slot>
  </div>
</dialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  dialog {
    border-radius: calc(var(--height) / 8);
    border: 2px solid $clr-popup-border;

    font-size: var(--dialog-buttons-font-size);
    .title div {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 35rem;
    }
  }

  button {
    height: var(--keys-size);
    font-size: inherit;
  }
</style>
