<script lang="ts">
  export let on = false;
  export let opacity = 1;
  export let rgb = "50, 205, 50"; // limegreen
  export let size = "1.8rem";
</script>

<div class="led" class:on style:--color={rgb} style:--size={size} style:opacity />

<style lang="scss">
  .led {
    height: var(--size);
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    &.on {
      background-color: rgb(var(--color));
      border: 2px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 calc(var(--size) * 0.18) rgba(var(--color), 0.8), 0 0 calc(var(--size) * 0.28) rgba(var(--color), 0.8),
        0 0 calc(var(--size) * 0.55) rgba(var(--color), 0.4), 0 0 var(--size) rgba(var(--color), 0.5);
    }
  }
</style>
