<script lang="ts">
  import dialog from "@/dialog";
  import { is12HourClock } from "@/stores";
  import { durationString } from "@/utils";
  import { createEventDispatcher } from "svelte";

  export let label = "";
  export let value: number; // Seconds
  export let disabled = false;

  let ref: HTMLElement;
  let fire = createEventDispatcher();
  const id = Math.random().toString(36).slice(2, 9);

  async function editTime() {
    let v = await dialog.time("Time", value, false);
    if (v !== null) {
      value = v;
      fire("change", value);
    }
  }

  /**
   * Formats a duration in seconds into a string, appending AM/PM if needed.
   * NOTE: The returned string may contain html-tags.
   *
   * @param v - Duration in seconds
   * @returns Localized string representation of the duration
   */
  function timeString(v: number) {
    let s = durationString(v, false);
    if (!$is12HourClock) return s;
    if (Math.round(v / (60 * 60)) < 12) {
      return s + `<span class="cycle dim">AM</span>`;
    } else {
      return s + `<span class="cycle dim">PM</span>`;
    }
  }
</script>

<div class="time-input-container" bind:this={ref} class:disabled>
  {#if label}
    <label for={id}>{label}:</label>
  {/if}
  <div {id} tabindex="-1" class="time-input" on:click|stopPropagation={editTime}>
    <span>{@html timeString(value)}</span>
  </div>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .time-input-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .time-input {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 2px;
    align-items: center;
    transition: color 200ms ease;
    height: var(--controls-height);
    width: auto;

    padding: 0.5rem 1rem;
    border-radius: calc(4rem / 8);
    border: 2px solid $primary-dimmed;

    background-color: var(--clr-input-field-background);
    &:active {
      background-color: $primary-dimmed;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    :global(.cycle) {
      font-size: 70%;
    }
  }
</style>
