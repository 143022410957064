<script lang="ts">
  import hmi, { client } from "@/hmi";
  import { dateFmt, numFmt, timeFmt } from "@/stores";
  import { smoothRollingPlugin } from "@/uplot-plugins";
  import { convertRemToPixels, dateNow, trimStart } from "@/utils";
  import humanizeDuration from "humanize-duration";
  import _ from "lodash-es";
  import { createEventDispatcher, onMount } from "svelte";
  import { locale } from "svelte-i18n";
  import { cubicInOut, quintOut } from "svelte/easing";
  import { tweened } from "svelte/motion";
  import { get } from "svelte/store";
  import { scale } from "svelte/transition";
  import { default as uPlot } from "uplot";

  export let smoothScroll = false;

  const dispatch = createEventDispatcher();
  const ampsSetpoint = hmi.getValueStore("sys.amps_setpoint");

  let range = 10 * 60 * 1000;
  let ampGraphElement: HTMLDivElement;
  let plot: uPlot;
  let stoppers: Set<Function> = new Set();
  let destroyed = false;
  let maxAmpsTweened = tweened(4, { duration: 200, easing: cubicInOut });

  let timestamps: number[] = [];
  let values: number[] = [];
  let stats: DTO.Stats = {
    steampot: {
      clear_time: 0,
      op_secs: 0,
      op_cycles: 0,
      max_amps: 0,
      clean_cycles: 0,
      secs_since_clean: 0,
      water_cycles: 0,
      drain_cycles: 0,
    },
  };

  $: if (smoothScroll) requestAnimationFrame(nextFrame); // Start the redraw loop if smooth scrolling is enabled.

  onMount(() => {
    let purgeTimer = window.setInterval(purgeOldData, 1000 * 60); // Purge old data every minute.
    plot = new uPlot(buildOptions(ampGraphElement), [timestamps, values], ampGraphElement);
    initGraphDataStream("cur.steampot_amps");
    initStatsStream();
    requestAnimationFrame(nextFrame);

    return () => {
      destroyed = true;
      window.clearInterval(purgeTimer);
      for (const stop of stoppers) stop();
    };
  });

  function initGraphDataStream(id: string): void {
    // Clear the data object to ensure that any existing data is removed.
    timestamps.length = 0;
    values.length = 0;

    // Start the stream and push any incoming data into the data object.
    let [result, stop] = client.stream(
      "StreamLog",
      (v: number[]) => {
        let old = Math.floor(dateNow().getTime() - range);
        let wasEmpty = timestamps.length === 0;
        if (wasEmpty && v.length && v[0] * 1000 > old) {
          // Insert a dummy value to ensure that the graph starts at the left edge.
          // This is needed bacause amps log removes repeated values.
          timestamps.push(old - 1000);
          values.push(v.length ? v[1] : 0);
        }
        for (let i = 0; i < v.length; i += 2) {
          let ts = v[i] * 1000;
          timestamps.push(ts);
          values.push(v[i + 1]);
        }
        let max = 0;
        for (let i = 0; i < values.length; i++) {
          if (timestamps[i] < old) continue;
          if (values[i] > max) max = values[i];
        }
        maxAmpsTweened.set(Math.max(get(ampsSetpoint) + 0.5, max) + 0.5, { duration: wasEmpty ? 0 : 1000 });

        // Trigger reactivity.
        timestamps = timestamps;
        values = values;

        if (!smoothScroll) redraw();
      },
      id,
      Math.floor(Math.floor(Math.floor((dateNow().getTime() - range) / 1000)))
    );
    // If the stream fails, log an error and attempt to restart the stream.
    result.catch((e) => {
      console.error(`stream of ${id} failed: ${e}`);
      stoppers.delete(stop);
      window.setTimeout(() => {
        if (destroyed) return;
        initGraphDataStream(id);
      }, 1000);
    });
    if (stop) stoppers.add(stop);
  }

  function purgeOldData() {
    console.assert(timestamps.length === values.length);
    let old = dateNow().getTime() - range;
    let purgeCount = -5; // Keep 5 extra data points.
    for (let i = 0; i < timestamps.length && timestamps[i] < old; i++) purgeCount++;
    if (purgeCount > 1) {
      trimStart(timestamps, purgeCount);
      trimStart(values, purgeCount);
    }
  }

  function initStatsStream(): void {
    let [result, stop] = client.stream("StreamStats", (v: DTO.Stats) => {
      stats = _.merge(stats, v); // Reassign to trigger reactivity.
    });
    result.catch((e) => {
      console.error(`StreamStats failed: ${e}`);
      stoppers.delete(stop);
      window.setTimeout(() => {
        if (destroyed) return;
        initStatsStream();
      }, 1000);
    });
    if (stop) stoppers.add(stop);
  }

  function horizontalLinePlugin(opts: { value: () => number; color: string; width: number; dash: number[] }) {
    return {
      hooks: {
        draw: [
          (u: uPlot) => {
            const ctx = u.ctx;
            let { left, top, width, height } = u.bbox;
            ctx.save();
            let region = new Path2D();
            region.rect(left, top, width, height);
            ctx.clip(region);
            const y = u.valToPos(opts.value(), "amp", true);
            ctx.strokeStyle = opts.color;
            ctx.lineWidth = opts.width;
            ctx.setLineDash(opts.dash);
            ctx.beginPath();
            // Start drawing rounded down to the nearest minute so the dashed moves with the rest of the graph.
            ctx.moveTo(u.valToPos(Math.floor(u.scales.x.min / (1000 * 60)) * 1000 * 60, "x", true), y);
            ctx.lineTo(left + width, y);
            ctx.stroke();
            ctx.restore();
          },
        ],
      },
    };
  }

  function buildAmpAxis(font: string, side: number): uPlot.Axis {
    return {
      scale: "amp",
      side: side,
      font: font,
      stroke: "white",
      grid: { stroke: "rgb(50,50,50)", width: 2 },
      // border: { show: true, stroke: "rgb(50,50,50)", width: 2 },
      // ticks: { show: true, stroke: "rgb(50,50,50)", width: 2, size: 6 },
      values: (u, vals) => vals.map((v) => $numFmt(v, 1)),
    };
  }

  function buildOptions(container: HTMLElement): uPlot.Options {
    let fh = Math.ceil(convertRemToPixels(1.2));
    let font = `${fh}px hmiFont`;

    return {
      plugins: [
        smoothRollingPlugin(),
        horizontalLinePlugin({
          value: () => $ampsSetpoint,
          color: "rgba(255, 55, 255, 0.7)",
          width: 2,
          dash: [2 * window.devicePixelRatio, 4 * window.devicePixelRatio],
        }),
      ],
      width: container.clientWidth,
      height: container.clientHeight,
      pxAlign: 0,
      ms: 1,
      padding: [convertRemToPixels(1), convertRemToPixels(1), 0, convertRemToPixels(1.5)],
      legend: {
        show: false,
      },
      cursor: {
        show: false,
        drag: {
          setScale: false,
        },
      },
      scales: {
        x: { time: true },
        amp: {
          range: (u, min, max) => [0, get(maxAmpsTweened)],
        },
      },
      series: [
        { auto: false, sorted: 1 /* Ascending */ },
        { label: "A", scale: "amp", stroke: "rgb(255, 55, 255)", width: 2.5, points: { show: false } },
      ],
      axes: [
        {
          scale: "x",
          font: font,
          stroke: "white",
          grid: { stroke: "rgb(50,50,50)", width: 2 },
          values: (u, vals) => vals.map((v) => $timeFmt(v, { seconds: false })),
        },
        buildAmpAxis(font, 3), // left
        buildAmpAxis(font, 1), // right
      ],
    } as uPlot.Options;
  }

  let frameCounter = 0;

  function nextFrame() {
    if (destroyed) return;
    if (frameCounter % 2 === 0) redraw(); // Only redraw every other frame.
    if (smoothScroll) requestAnimationFrame(nextFrame);
    frameCounter++;
  }

  function redraw() {
    if (!plot) return;
    plot.batch(() => {
      // -1000 to let new values smoothly scroll in (beyound clip region).
      plot.setScale("x", { min: dateNow().getTime() - range - 1000, max: dateNow().getTime() - 1000 });
      plot.setData([timestamps, values], false);
    });
  }

  const shortEnglishHumanizer = humanizeDuration.humanizer({
    language: "shortEn",
    largest: 3,
    languages: {
      shortEn: {
        y: () => "y",
        mo: () => "mo",
        w: () => "w",
        d: () => "d",
        h: () => "h",
        m: () => "m",
        s: () => "s",
        ms: () => "ms",
      },
    },
    spacer: "",
  });

  let curLocale = $locale;
  $: if ($locale !== curLocale) {
    curLocale = $locale;
    redraw();
  }
</script>

<div
  class="steampot-details"
  transition:scale|local={{ duration: 200, easing: quintOut }}
  tabindex="-1"
  on:click={() => dispatch("dismiss")}
>
  <div class="stats">
    {#if stats}
      {@const t = stats.steampot.clear_time * 1000}
      <div style="grid-column: span 3">Stats since: {stats.steampot.clear_time ? $dateFmt(t) + " " + $timeFmt(t) : "-"}</div>
      <div>Steam cycles: {stats.steampot.op_cycles}</div>
      <div>Total runtime: {shortEnglishHumanizer(stats.steampot.op_secs * 1000)}</div>
      <div>Run since cleaning: {shortEnglishHumanizer(stats.steampot.secs_since_clean * 1000)}</div>
      <div>Cleaning cycles: {stats.steampot.clean_cycles}</div>
      <div>Amps record: {$numFmt(stats.steampot.max_amps, -1)} A</div>
      <div>Water cycles: {stats.steampot.water_cycles}</div>
      <div>Drain cycles: {stats.steampot.drain_cycles}</div>
      <div>Amps setpoint: {$numFmt($ampsSetpoint, -2)}A</div>
    {/if}
  </div>
  <div class="graph-container" bind:this={ampGraphElement} style:opacity={timestamps?.length ? 1 : 0} />
</div>

<style lang="scss">
  @import "../styles/_variables.scss";

  .steampot-details {
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #111; //$menu-background;
    border: 0.2rem solid $menu-background;
    box-shadow: 0 0 2rem 0 black;

    display: flex;
    flex-direction: column;

    width: calc(100% - 4rem); // Fixed so we prevent layout shifts.
    height: calc(100% - 13rem); // Fixed so we prevent layout shifts.

    .stats {
      display: grid;
      padding: 1rem 1.5rem;
      grid-template-columns: 30% 1fr 1fr;
      align-items: start;
      justify-content: space-between;
      gap: 0 1rem;
      line-height: 1.2;
    }

    .graph-container {
      flex-grow: 1;
      transition: opacity 0.3s ease;
    }
  }
</style>
