<script lang="ts">
  import hmi, { client, state } from "@/hmi";
  import { fullDateTimeFmt, numFmt, usesFarenheight } from "@/stores";
  import { CtoF } from "@/utils";
  import { _, json, locale } from "svelte-i18n";
  import ConfigDialog from "../ConfigDialog.svelte";

  const textTable = $json("$$alarms", $locale);

  let selectedIndex = -1;
  let lastSeen = hmi.lastSeenAlarm();
  let alarms: DTO.Alarm[] = [];
  client
    .invoke<DTO.Alarm[]>("ReadAlarms", 0, 1000)
    .then((a) => {
      alarms = a ?? [];
      // sort alarms so that alarms with 0 end time are at the top otherwise sort by start time
      alarms.sort((a, b) => {
        if ((a.end !== 0 && b.end !== 0) || (a.end === 0 && b.end === 0)) return b.start - a.start;
        if (a.end) return 1;
        return -1;
      });
      hmi.ackAlarm();
    })
    .catch(alert);

  function scaleTempForUser(t: number) {
    return $usesFarenheight ? CtoF(t) : t;
  }

  function getAlarmText(n: number) {
    return textTable[n.toFixed()] ?? textTable["*"];
  }
</script>

<ConfigDialog title={$_("menu.alarmlist")} helpId="ALARMLIST">
  <div class="alarm-list">
    <div class="scrollable">
      <table>
        <thead>
          <tr>
            <td>{$_("time")}</td>
            <td>#</td>
            <td>{$_("description")}</td>
            <td align="right">{$state.tempUnit}/%</td>
          </tr>
        </thead>
        {#each alarms as alarm, i}
          <tr
            class:selected={selectedIndex === i}
            on:click={() => (selectedIndex = i)}
            class:highlight={(!!lastSeen && alarm.start > lastSeen) || !alarm.end}
          >
            <td>{$fullDateTimeFmt(alarm.start * 1000)}</td>
            <td>{alarm.code}</td>
            <td>{getAlarmText(alarm.code)}</td>
            <td align="right">{$numFmt(scaleTempForUser(alarm.t), -1)} / {$numFmt(alarm.h)}</td>
          </tr>
        {/each}
      </table>
    </div>
    {#if selectedIndex >= 0}
      {@const a = alarms[selectedIndex]}
      <div class="details">
        <div>Details for alarm {a.code}</div>
        <div class="align-right">
          {#if a.end}
            <div>Ended: {$fullDateTimeFmt(a.end * 1000)}</div>
          {/if}
        </div>
      </div>
    {/if}
  </div>
</ConfigDialog>

<style lang="scss">
  @use "../../styles/variables.scss" as *;

  .alarm-list {
    display: grid;
    width: 100%;
    height: 100%;
    max-height: 100%;
    font-family: hmiFont;
    font-size: 2.2rem;
  }

  .scrollable {
    flex: 1;
    overflow-y: scroll;
    scrollbar-gutter: stable;
    width: 100%;
    height: 100%;
    max-height: 100%;
  }

  table {
    width: 100%;
    white-space: nowrap;
    table-layout: auto;
    border-collapse: collapse;
    line-height: 1.6;

    thead {
      font-family: hmiFontBold;
      color: $company;
    }

    tr {
      border-bottom: 1px solid $primary-dimmed;
      &.selected {
        background-color: $company;
        color: $background;
      }
      td {
        padding-left: $dialog-padding;
        &:nth-child(2) {
          text-align: right;
          padding-inline: 2rem;
        }
        &:nth-child(3) {
          width: 100%;
        }
        &:nth-child(4) {
          width: 100%;
          padding-inline: 1rem;
        }
      }

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .highlight {
    color: red !important;
    font-family: hmiFontBold;
  }

  .details {
    display: grid;
    grid-template-columns: 1fr auto;
    border-top: 0.2rem solid $status-background;
    background-color: $status-background;
    height: auto;
    padding: 1rem;
  }
</style>
