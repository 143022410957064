<script lang="ts">
  import { clamp, convertRemToPixels, pad2 } from "@/utils";
  import { onDestroy } from "svelte";
  import { tweened } from "svelte/motion";

  export let value: number;
  export let min: number;
  export let max: number;
  export let unit = "";
  export let disabled: boolean = false;

  const valuePerDeg = 0.05;
  let accelleration = tweened(1, { duration: 500 });
  let rawValue = value;
  let svgRef: SVGElement;
  let capturing = false;
  let a: number | undefined;
  let da: number | undefined;
  let dialAngel = 0;
  let preva: number | undefined = undefined;
  let sampleIntervalTimer: number | undefined;
  let deltaSample = 0;
  let deadzone: number = 0;

  $: if (Math.abs(value - rawValue) >= 1) rawValue = value; // Sync rawValue with value if value is changed externally (using numpad)
  $: deadzone = (svgRef?.clientWidth / 2) * 0.3 || 0;

  /**
   * Calculates the distance from the origin (0, 0) to the point (x, y) in the Cartesian plane.
   * @param x The x-coordinate of the point
   * @param y The y-coordinate of the point
   * @return The distance between the origin and the point
   */
  function distanceFromCenter(x: number, y: number): number {
    return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
  }

  /**
   * Calculates the angle (in degrees) from origin (0, 0) to the point (x, y) in the Cartesian plane.
   * @param x The x-coordinate of the point
   * @param y The y-coordinate of the point
   * @return The angle in degrees, between 0 and 360
   */
  function angleFromPoint(x: number, y: number): number {
    let rad = Math.atan2(y, x);
    a = (rad * 180) / Math.PI;
    if (a < 0) a += 360;
    return a;
  }

  function onPointerDown(e: PointerEvent): void {
    svgRef.setPointerCapture(e.pointerId);
    capturing = true;
    deltaSample = 0;
    accelleration.set(1, { duration: 0 });
    sampleIntervalTimer = window.setInterval(() => {
      if (deltaSample > 50) $accelleration += 0.5;
      else if ($accelleration > 1) $accelleration = 1;
      $accelleration = clamp($accelleration, 1, 6);
      deltaSample = 0;
    }, 200);
    onPointerMove(e);
  }

  function onPointerMove(e: PointerEvent): void {
    if (!capturing) return;
    let x = e.offsetX;
    let y = e.offsetY;
    const r = svgRef.clientWidth / 2;

    let dist = distanceFromCenter(x - r, y - r);
    if (dist < deadzone) {
      preva = undefined;
      return;
    }
    let a = angleFromPoint(x - r, y - r);
    if (preva !== undefined) {
      da = a - preva;
      if (da <= -180) da = 360 + da;
      else if (da >= 180) da = 360 - da;
      deltaSample += Math.abs(da);
      dialAngel += da;
      rawValue = clamp(rawValue + da * valuePerDeg * $accelleration, min, max);
      value = Math.floor(rawValue);
    }

    preva = a;
  }

  function onPointerUp(e: PointerEvent): void {
    svgRef.releasePointerCapture(e.pointerId);
    a = preva = da = undefined;
    capturing = false;
    if (sampleIntervalTimer !== undefined) {
      window.clearInterval(sampleIntervalTimer);
      sampleIntervalTimer = undefined;
    }
  }

  onDestroy(() => {
    if (sampleIntervalTimer !== undefined) window.clearInterval(sampleIntervalTimer);
  });
</script>

<div class="value">{value}{unit}</div>

<div class="rotary-input">
  <svg
    style="grid-row: 1/-1; grid-column: 1/-1"
    tabindex="-1"
    bind:this={svgRef}
    viewBox="0 0 300 300"
    on:pointerdown={onPointerDown}
    on:pointermove={onPointerMove}
    on:pointerup={onPointerUp}
    class:disabled
  >
    <circle cx="150" cy="150" r="130" />
    <circle cx="150" cy="150" r={deadzone} class="center-circle shadow" />
    <g transform="rotate({dialAngel}, 150, 150)">
      {#each new Array(8) as _, i}
        <line
          x1="150"
          y1="45"
          x2="150"
          y2={150 - deadzone - 10 - 15}
          stroke-width={convertRemToPixels(0.5)}
          stroke="gray"
          stroke-linecap="round"
          transform="rotate({i * 45}, 150, 150)"
        />
        <line
          x1="150"
          y1="52"
          x2="150"
          y2={150 - deadzone - 10 - 23}
          stroke-width={convertRemToPixels(0.3)}
          stroke="gray"
          stroke-linecap="round"
          transform="rotate({i * 45 + 22.5}, 150, 150)"
        />
      {/each}
    </g>
  </svg>
  <div style="grid-row: 1/-1; grid-column: 1/-1; z-index: 1">
    <slot />
  </div>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .rotary-input {
    display: grid;
    justify-items: center;
    align-items: center;
  }

  .shadow {
    filter: drop-shadow(0 0 10px rgb(0 0 0 / 0.6));
  }

  .value {
    width: 100%;
    text-align: center;
    color: $company;
    font-size: 2.6rem;
  }

  svg {
    pointer-events: all;
    flex-grow: 1;
    transform: rotate(-90deg);
    stroke-width: 1rem;
    fill: $background;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    circle {
      &:nth-child(1) {
        stroke: $primary-dimmed;
      }
      &:nth-child(2) {
        fill: $primary-dimmed;
        stroke: $primary-dimmed;
      }
      &:nth-child(3) {
        stroke-dasharray: 3 calc(360 - 3);
        stroke: $company;
        fill: transparent;
      }
    }

    .center-circle {
      pointer-events: all;
      fill: var(--background-color, $primary-dimmed);
      stroke: var(--background-color, $primary-dimmed);
    }
  }
</style>
