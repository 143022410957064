<script lang="ts">
  import { api } from "@/api";
  import { DEFAULT_DEBOUNCED_SAVE_MS } from "@/hmi";
  import CheckBox from "@/lib/CheckBox.svelte";
  import { pad3 } from "@/utils";
  import { debounce } from "lodash-es";
  import { onDestroy } from "svelte";
  import { json, locale } from "svelte-i18n";
  import ConfigDialog from "../ConfigDialog.svelte";

  type Item = { id: number; text: string; inhibit?: boolean };

  let settings: DTO.AlarmSettings;

  const debounceSave = debounce(() => {
    api.saveAlarmSettings({ ...settings, inhibit: alarms.filter((a) => a.inhibit).map((a) => a.id) });
  }, DEFAULT_DEBOUNCED_SAVE_MS);

  let alarms: Item[] = [];

  api.loadAlarmSettings().then((data) => {
    settings = data ?? ({} as DTO.AlarmSettings);
    alarms = Object.entries($json("$$alarms", $locale))
      .filter(([id]) => id !== "*")
      .map(([id, text]) => ({ id: +id, text, inhibit: settings?.inhibit?.includes(+id) || false })) as Item[];
  });

  onDestroy(() => {
    debounceSave.flush();
  });

  function onChange() {
    debounceSave();
  }
</script>

<ConfigDialog title="Alarm filter" helpId="ALARM_FILTER">
  <section>
    <div class="alarms">
      {#each alarms as alarm}
        <CheckBox bind:checked={alarm.inhibit} label={pad3(alarm.id) + " " + alarm.text} on:change={onChange} />
      {/each}
    </div>
  </section>
</ConfigDialog>

<style lang="scss">
  @use "../../styles/variables.scss" as *;

  section {
    padding: $dialog-padding;
    max-height: 100%;
    font-family: hmiFont;
    font-size: 2.2rem;
    position: relative;
    overflow-x: auto;

    .alarms {
      height: 100%;
      display: flex;
      flex-flow: column wrap;
      align-content: flex-start;
      gap: 1rem 4rem;
    }
  }
</style>
