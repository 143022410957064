<script lang="ts">
  import { api } from "@/api";
  import dialog from "@/dialog";
  import { state } from "@/hmi";
  import Button from "@/lib/Button.svelte";
  import ListInput from "@/lib/ListInput.svelte";
  import { fullScreenComponent } from "@/stores";
  import { onMount } from "svelte";

  let sources: ListItem[] = [{ text: "NONE", value: "" }];
  let updates: ListItem[] = [{ text: "NONE", value: "" }];
  let selectedSource: string = "";
  let selectedUpdate: any = -1;
  let busy = false;

  onMount(async () => {
    sources = (await api.findUpdateSources())?.map((x) => ({ text: x, value: x })) ?? [{ text: "None found!", value: "" }];
    if (sources && sources.length && sources[0].value) {
      selectedSource = sources[0].value;
      sourceChanged();
    }
  });

  function close() {
    $fullScreenComponent = null;
  }

  async function sourceChanged() {
    if (!selectedSource) return;
    api
      .getUpdatesInSource(selectedSource)
      .then((x) => {
        updates = x?.map((x) => ({ text: x, value: x }));
        if (updates?.length) selectedUpdate = updates[0].value;
      })
      .catch((e) => {
        updates = undefined;
      });
  }

  async function installClicked() {
    let result = await dialog.confirm(`Are you sure you want to install ${selectedUpdate}?`);
    if (!result) return;

    busy = true;
    await api.installUpdate(selectedSource, selectedUpdate as string);

    setTimeout(() => {
      close();
    }, 3000);
  }
</script>

<section>
  <p>This will update the application only. Settings/data are all preserved.</p>
  <br />
  <br />
  <div class="columns">
    <label>Current version:</label>
    <div>{$state.version}</div>

    <label>Source:</label>
    <ListInput width="50rem" items={sources} bind:selectedValue={selectedSource} disabled={busy} on:change={sourceChanged} />

    <label>Select new version:</label>
    <ListInput width="25rem" items={updates} bind:selectedValue={selectedUpdate} disabled={busy || !selectedSource} />
  </div>

  <br />
  <br />
  <div class="flex-line center">
    <Button on:click={close} disabled={busy}>Close</Button>
    <Button on:click={installClicked} disabled={busy || selectedUpdate === undefined || selectedUpdate === -1}>Install update</Button>
  </div>
</section>

<style lang="scss">
  section {
    --full-width: calc(100% + var(--menu-width));

    width: var(--full-width);
    height: 100%;
    font-size: 2.5rem;
  }

  .columns {
    label {
      white-space: nowrap;
    }
    display: grid;
    grid-template-columns: auto auto;
    gap: 2rem;
    align-items: center;
  }
</style>
