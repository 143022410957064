<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { durationString, pad2 } from "@/utils";
  import dialog from "@/dialog";

  export let label = "";
  export let value: number; // Seconds
  export let disabled = false;
  export let hideSecond = false;

  let ref: HTMLElement;
  let fire = createEventDispatcher();
  const id = Math.random().toString(36).slice(2, 9);

  async function editDuration() {
    let v = await dialog.time("Duration", value, true);
    if (v !== null) {
      value = v;
      fire("change", value);
    }
  }
</script>

<!--
  transition:slide={{ duration: 200, axis: "y", easing: quintOut }}
-->

<div class="duration-input-container" bind:this={ref} class:disabled>
  {#if label}
    <label for={id}>{label}:</label>
  {/if}
  <div {id} tabindex="-1" class="duration-input" on:click|stopPropagation={editDuration}>
    <span>{durationString(value)}</span>
  </div>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .duration-input-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .duration-input {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 2px;
    align-items: center;
    transition: color 200ms ease;
    height: var(--controls-height);

    padding: 0.5rem 1rem;
    border-radius: calc(4rem / 8);
    border: 2px solid $primary-dimmed;

    background-color: var(--clr-input-field-background);
    &:active {
      background-color: $primary-dimmed;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
