<script lang="ts">
  import "./ListInput.scss";

  import ListIcon from "@/svg/ListIcon.svelte";
  import { Events, eventBus, isInView, measureText } from "@/utils";
  import { createEventDispatcher, onDestroy } from "svelte";

  export let label = "";
  export let items: ListItem[];
  export let selectedText: string | undefined = undefined;
  export let selectedValue: any | undefined = undefined;
  export let width = "15rem";
  export let inline = false;
  export let disabled = false;

  let ref: HTMLElement;
  let refList: HTMLElement;
  let clientWidth: number;
  let fire = createEventDispatcher();
  const id = Math.random().toString(36).slice(2, 9);
  let selectedItem: ListItem | undefined;
  let popup: HTMLUListElement | undefined;

  $: if (items) {
    let item = items.find((i) => i.value === selectedValue);
    if (item && item !== selectedItem) {
      selectedText = item?.text;
      selectedItem = item;
    }
    if (!item && selectedText) {
      selectedItem = items.find((i) => i.text === selectedText);
      selectedValue = selectedItem?.value;
    }
  }

  onDestroy(() => {
    if (popup) popup.dispatchEvent(new Event("blur"));
  });

  function select(v: any | undefined) {
    if (v === selectedValue) return;
    let item = items.find((i) => i.value === v);
    if (!item) {
      selectedValue = undefined;
      selectedText = undefined;
      fire("change", null);
      return;
    }
    selectedValue = item.value;
    selectedText = item.text;
    fire("change", { selectedValue: item.value, selectedText: item.text });
  }

  function calculateNeededWidth(): number {
    console.assert(ref, "ref is not set");
    let strings = items.map((i) => i.text);
    let widths = measureText(window.getComputedStyle(ref).font, strings) as number[];
    return Math.max(ref.clientWidth, ...widths);
  }

  function absorb(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  // Return the first ancestor that is a dialog or the main tag.
  // This is needed because we cannot overlay the dialog with a popup.
  function findBestPopupAncestor(): HTMLElement | undefined {
    let el = ref;
    while (el) {
      if (el.tagName === "DIALOG") {
        return el;
      }
      el = el.parentElement;
    }
    return document.querySelector("main") ?? document.body;
  }

  function positionPopup(popup: HTMLElement) {
    const bodyRect = document.body.getBoundingClientRect();
    const refRect = ref.getBoundingClientRect();
    const popupRect = popup.getBoundingClientRect();
    const margin = 5;

    popup.style.minWidth = refRect.width + "px";

    // Position vertically

    let itemHeight = (popup.firstChild as HTMLDivElement).clientHeight + 2; /* border */
    let maxHeight = document.body.clientHeight - 2 * margin;
    maxHeight -= maxHeight % itemHeight;

    popup.style.maxHeight = maxHeight + "px";
    let top = refRect.top + refRect.height / 2 - popup.clientHeight / 2;
    if (top < 0) top = margin;
    else if (top + popup.clientHeight / 2 > maxHeight) top = 0;
    popup.style.top = `${top}px`;

    // Position horizontally

    popup.style.left = `${refRect.left}px`;
    let right = refRect.left + popupRect.width - bodyRect.left;
    if (right > document.body.clientWidth) {
      popup.style.left = refRect.right - popupRect.width + "px";
    }

    queueMicrotask(() => {
      let popupRect = popup.getBoundingClientRect();
      if (popupRect.top < 0) popup.style.top = `${(document.body.clientHeight - popupRect.height) / 2}px`;
      else if (popupRect.bottom > document.body.clientHeight)
        popup.style.top = `${document.body.clientHeight - (popupRect.height + margin)}px`;
    });
  }

  /**
   * Create the popup list
   */

  function createPopup() {
    const close = () => {
      eventBus.detach(Events.inputIdle, close);
      popup
        .animate([{ opacity: 0 }], {
          duration: 200,
          easing: "ease-in-out",
          fill: "forwards",
        })
        .finished.then(() => {
          refList = undefined;
          if (!popup) return;
          popup.removeEventListener("click", click);
          popup.removeEventListener("blur", blur);
          popup.removeEventListener("keydown", keydown);
          popup.remove();
          popup = undefined;
        });
    };
    const click = (e: PointerEvent) => {
      e.stopPropagation();
      let v = JSON.parse((e.target as HTMLElement).dataset.value);
      select(v);
      close();
    };

    const blur = (e: FocusEvent) => {
      // If focus is moving to a child of the list, don't close the list
      if (ref && e.relatedTarget !== ref && ref.contains(e.relatedTarget as Node)) return;
      close();
    };

    const keydown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        e.stopPropagation();
        e.preventDefault();
        close();
      }
    };

    popup = document.createElement("ul");
    popup.setAttribute("tabindex", "-1");
    popup.classList.add("list-input-global-popup", "li-scroll-snap");

    popup.addEventListener("click", click);
    popup.addEventListener("blur", blur);
    popup.addEventListener("keydown", keydown);

    for (let i = 0; i < items.length; i++) {
      let item = items[i];

      let li = document.createElement("li");
      li.classList.add("list-item");
      if (item.value === selectedValue) li.classList.add("selected");
      li.setAttribute("data-value", JSON.stringify(item.value));

      li.innerHTML = item.text;
      popup.appendChild(li);

      popup.animate([{ opacity: 1 }], {
        duration: 200,
        easing: "ease-in-out",
        fill: "forwards",
      });
    }

    let parent = findBestPopupAncestor();
    parent.appendChild(popup);

    eventBus.on(Events.inputIdle, close);

    positionPopup(popup);
    popup.focus();

    // Ensure that the selected item is visible
    let selected = popup.querySelector(".selected");
    if (selected && !isInView(selected)) selected.scrollIntoView({ behavior: "instant" });
  }
</script>

<!--
  transition:slide={{ duration: 200, axis: "y", easing: quintOut }}
-->

<div class="list-input-container" class:inline class:disabled bind:clientWidth>
  {#if label}
    <label for={id}>{label}:</label>
  {/if}
  <div
    bind:this={ref}
    {id}
    tabindex="-1"
    class="list-input"
    class:inline
    style:width
    on:click|stopPropagation={createPopup}
    on:pointerdown={absorb}
    on:pointerup={absorb}
  >
    <span>{@html selectedItem ? selectedItem.text : ""}</span>
    {#if !inline}
      <ListIcon height="2.5rem" />
    {/if}
  </div>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .list-input-container {
    --height: var(--controls-height);
    display: flex;
    align-items: center;
    gap: 1rem;

    &:not(.inline) {
      font-size: 2.2rem;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .list-input {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 2px;
    align-items: center;
    transition: color 200ms ease;
    background-color: var(--clr-input-field-background);

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:not(.inline) {
      height: var(--height);
      padding: 0.5rem 1rem;
      border-radius: calc(var(--height) / 8);
      border: 2px solid $primary-dimmed;

      &:active {
        background-color: lighten($background, 15%);
      }
    }

    :global(svg) {
      transition: color 200ms ease;
      color: $primary-dimmed;
    }

    &.inline:active {
      outline: 2px dotted $company;
    }
  }
</style>
