<script lang="ts">
  import Button from "@/lib/Button.svelte";
  import { _ } from "svelte-i18n";
  import Dialog from "./Dialog.svelte";

  export let title = $_("confirm_title");
  export let yes = $_("confirm_yes");
  export let no = $_("confirm_no");
  export let message: string;
  export let result: boolean | null = null;

  let dlg: Dialog;
</script>

<Dialog {title} returnValue={result} bind:this={dlg}>
  <div class="confirm-body first-upper">{@html message}</div>
  <div slot="actions" class="button-group space-between">
    <Button on:click={() => dlg.close(false)}>{no}</Button>
    <Button on:click={() => dlg.close(true)}>{yes}</Button>
  </div>
</Dialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .confirm-body {
    padding: $dialog-padding;
    text-align: center;
    max-width: 400px;
    font-family: hmiFont;
  }
</style>
