<script lang="ts">
  import ConfigDialog from "./ConfigDialog.svelte";

  export let file: string;
  export let title: string;
  export let message: string;
</script>

<ConfigDialog {title}>
  <div slot="title_extra" class="help-dialog-file">{file}</div>
  <div class="help-dialog-body">{@html message}</div>
</ConfigDialog>

<style lang="scss" global>
  @use "../styles/variables.scss" as *;

  .help-dialog-file {
    text-transform: none;
    font-size: 2rem;
    font-family: hmiFont;
    color: $primary-dimmed;
    margin-block: auto;
  }

  .help-dialog-body {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    padding: $dialog-padding $dialog-padding 0 $dialog-padding;
    font-family: hmiFont;
    font-size: 2rem;

    > * {
      all: initial;
      font-family: hmiFont;
      color: $primary;
      margin-bottom: 1rem;
      line-height: 1.2;
    }

    h1,
    h2,
    h3 {
      display: block;
      font-weight: bold;
      padding-block: 1rem;
      color: $company;
    }

    h1 {
      font-size: 180%;
    }
    h2 {
      font-size: 130%;
      border-bottom: 0.25rem solid $company;
    }
    h3 {
      font-size: 110%;
    }

    p {
      font-size: 100%;
      display: block;
      margin-bottom: 2rem;
    }

    strong {
      font-family: hmiFontBold;
    }

    em {
      font-style: italic;
    }

    blockquote {
      display: block;
      font-size: 100%;
      margin-top: 1rem;
      margin-bottom: 2rem;

      line-height: 1;

      background-color: $primary-dimmed;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;

      p {
        margin: 0;
      }
    }

    code {
      font-family: monospace;
      font-size: 2rem;
      color: $company;
    }

    hr {
      display: block;
      height: 0.1rem;
      border: 0;
      border-top: 0.25rem solid $primary-dimmed;
      margin: 1em 0;
      padding: 0;
    }

    ul,
    ol {
      font-size: 100%;
      display: block;
      margin-bottom: 2rem;
    }

    ul {
      margin-left: 2rem;
      input {
        margin-right: 1rem;
        transform: scale(2.4) translateY(-2px);
      }
    }

    ol {
      list-style-type: decimal;
      margin-left: 2rem;
    }

    li {
      display: list-item;
      margin-left: 2rem;
      margin-bottom: 0.5rem;
    }

    table {
      font-size: 100%;
      border-collapse: collapse;
      display: table;
      margin-left: 4rem;
      margin-bottom: 2rem;

      thead {
        font-family: hmiFontBold;
        tr {
          border-top: none;

          &:last-of-type {
            border-bottom: 0.25rem solid $primary;
          }
        }
      }

      tbody {
        tr + tr {
          border-top: 0.25rem solid $primary-dimmed;
        }
      }

      th,
      td {
        text-align: left;
        padding-block: 0.5rem;
      }
      th + th,
      td + td {
        padding-left: 2rem;
      }
    }
  }
</style>
