<script lang="ts" context="module">
  let selectedProp: string | undefined = undefined;
</script>

<script lang="ts">
  import { api } from "@/api";
  import dialog from "@/dialog";
  import { DEFAULT_DEBOUNCED_SAVE_MS, config, programs } from "@/hmi";
  import { usesFarenheight } from "@/stores";
  import { CtoF, FtoC, forFields } from "@/utils";
  import { debounce, isEqual } from "lodash-es";
  import { onDestroy, onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import { lookupHelp } from "./helpers";
  import _props from "./storage-props.json";

  import Editor from "@/config/property-editor/Editor.svelte";
  import ListInput from "@/lib/ListInput.svelte";

  const props = _props as PropConfig;

  let storage: DTO.StorageProgram;
  let lastSavedStorage: DTO.StorageProgram;
  let changeCounter = 0;
  let debounceSave = debounce(save, DEFAULT_DEBOUNCED_SAVE_MS);

  let currentProgram: string | undefined = undefined;
  let selectedProgram = $config.storage.program ?? "";
  $: programsSelection = $programs?.storage?.programs?.map((x) => {
    return {
      value: x.slot,
      text: x.slot + ": " + (x.name ?? ""),
    };
  });

  onMount(() => {
    if (!selectedProgram && programsSelection?.length) selectedProgram = programsSelection[0].value;
  });

  const help = (key: string, loc: string) => lookupHelp("storage", key, loc);

  let backups = new Map<string, DTO.StorageProgram>();
  let canRevert = false;
  const debounceUpdateRevert = debounce(() => {
    canRevert = storage && backups.has(currentProgram) && !isEqual(backups.get(currentProgram), storage);
  }, 100);

  $: if (changeCounter > 0) {
    debounceUpdateRevert();
    debounceSave();
  }

  // Ensure the selected program is valid
  $: if (programsSelection.findIndex((v) => v.value === selectedProgram) === -1 && programsSelection?.length)
    selectedProgram = programsSelection[0].value;

  $: if (selectedProgram && currentProgram !== selectedProgram) {
    currentProgram = selectedProgram;
    api
      .loadProgram<DTO.StorageProgram>("storage", selectedProgram)
      .then((p) => {
        storage = p;
        if (!storage) return;
        toUserTempUnit(storage);
        lastSavedStorage = structuredClone(storage);
        if (!backups.has(currentProgram)) backups.set(currentProgram, structuredClone(storage));
        ensureValid(storage);
        changeCounter++;
      })
      .catch((e) => {
        console.error("Failed to load storage program", e);
      });
  }

  onDestroy(() => {
    debounceSave.flush();
  });

  async function save() {
    if (!storage) return;
    if (isEqual(storage, lastSavedStorage)) return;
    let saveData = structuredClone(storage);
    toServerTempUnit(saveData);
    let ok = await api.saveProgram("storage", selectedProgram, saveData);
    if (ok) lastSavedStorage = structuredClone(storage);
  }

  async function revert() {
    console.assert(backups.has(currentProgram));
    if (!(await dialog.confirm("Are you sure you want to revert all changes?"))) return;
    storage = structuredClone(backups.get(currentProgram));
    changeCounter++;
  }

  $: validTypes = $config?.system?.accessiblePrograms.filter((x) => x.startsWith("STORAGE")).map((x) => x.slice(8));

  function ensureValid(ap: DTO.StorageProgram) {
    if (!validTypes?.length) return; // FIXME: Show error message?
    if (!validTypes.find((x) => x === ap.type)) {
      console.warn("Invalid type:", ap.type);
      ap.type = validTypes[0];
      debounceSave();
    }
  }

  // Fields that maybe needs to be converted to/from °F.
  const tempFields = ["soft_cooling_fan_high_speed_below", "under_floor_heat", "setpoints.t"];

  // Convert (if needed) from server temperture unit (°C) to user selected unit (°F or °C)
  // The result is rounded to the nearest integer.
  export function toUserTempUnit(ap: DTO.StorageProgram): void {
    if (!$usesFarenheight) return;
    forFields(ap, tempFields, (v: number) => Math.round(CtoF(v) * 10) / 10.0);
  }

  // Convert (if needed) from user temperture unit (°F or °C) to server unit (°C)
  // The result is rounded to the nearest integer.
  export function toServerTempUnit(ap: DTO.StorageProgram): void {
    if (!$usesFarenheight) return;
    forFields(ap, tempFields, (v: number) => Math.round(FtoC(v) * 10) / 10.0);
  }
</script>

{#if storage}
  <Editor
    title={$_("programs.storage.@title")}
    localePrefix="programs.storage"
    {props}
    data={storage}
    bind:selected={selectedProp}
    bind:changeCounter
    bind:programSlot={selectedProgram}
    {help}
    helpId="STORAGE_PROGRAM"
    {revert}
    {canRevert}
    type="storage"
  >
    <div class="flex-line" slot="before">
      {#if programsSelection?.length}
        <ListInput label="Program" width="28rem" items={programsSelection} bind:selectedValue={selectedProgram} />
      {/if}
    </div>
  </Editor>
{/if}
