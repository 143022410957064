<script lang="ts">
  import { decimalSeparator, is12HourClock } from "@/stores";
  import BackspaceIcon from "@/svg/BackspaceIcon.svelte";
  import { durationString } from "@/utils";
  import Dialog from "./Dialog.svelte";

  export let title: string;
  export let value: number;
  export let info: string | undefined = undefined;
  export let result: number | null = null;
  export let relative = false;
  export let min: number = 0;
  export let max: number = 24 * 60 * 60 - 60;

  let origValue = value;
  let input = "";
  let allowBackspace = false;
  let displayValue = "";
  let valid = false;
  let cycle = "AM";

  $: if (!relative && $is12HourClock) {
    min = Math.max(min, 1 * 60 * 60);
    max = Math.min(max, 13 * 60 * 60 - 60);
    if (Math.round(value / (60 * 60)) >= 12) {
      cycle = "PM";
    }
  }

  $: {
    let s = "0000".slice(0, 4 - input.length) + input;
    displayValue = `${s.slice(0, 2)}:${s.slice(2)}`;
  }

  function onClick(event: MouseEvent) {
    let el = event.target as HTMLButtonElement;
    if (!el) return;
    if (el.tagName !== "BUTTON") return;
    let fn = el.dataset.fn;
    if (fn) {
      switch (fn) {
        case "ampm":
          cycle = cycle === "AM" ? "PM" : "AM";
          break;
        case "del":
          if (input.length > 0) {
            input = input.slice(0, -1);
          }
          break;
      }
    } else {
      if (input.length >= 4) return;

      let text = el.textContent;
      if (text === $decimalSeparator && input.indexOf($decimalSeparator) !== -1) return;
      input += text;
    }

    if (input.length === 1) {
      allowBackspace = true;
    } else if (input.length === 0) allowBackspace = false;

    if (input === "-") {
      result = null;
      return;
    }

    let s = "0000".slice(0, 4 - input.length) + input;
    let h = parseInt(s.slice(0, 2));
    let m = parseInt(s.slice(2));
    valid = $is12HourClock && !relative ? h >= 1 && h <= 12 && m >= 0 && m < 60 : h >= 0 && h <= 23 && m >= 0 && m < 60;
    if (!valid) {
      result = null;
      return;
    }
    if ($is12HourClock && h === 12) h = 0;
    result = input.length ? h * 60 * 60 + m * 60 : origValue;
    if ($is12HourClock && !relative && cycle === "PM") result += 12 * 60 * 60;
  }
</script>

<Dialog {title} returnValue={result}>
  <div class="time-container" tabindex="-1">
    {#if info}
      <div class="info">{info}</div>
    {/if}
    <div class="display">
      <div class="range">
        <!-- {#if min !== undefined && max !== undefined}
          <div>Max: {durationString(max)}</div>
          <div>Min: {durationString(min)}</div>
        {/if} -->
      </div>
      {#if input}
        <div class="value">{@html displayValue}</div>
      {:else}
        <div class="value dim">{durationString(origValue)}</div>
      {/if}
      {#if $is12HourClock && !relative}
        <div class="cycle dim">{cycle}</div>
      {/if}
    </div>
    <div class="keys" on:click|preventDefault={onClick}>
      <button type="button">7</button>
      <button type="button">8</button>
      <button type="button">9</button>
      <button type="button" data-fn="del" disabled={!allowBackspace} style="padding: 5px"><BackspaceIcon height="3rem" /></button>
      <button type="button">4</button>
      <button type="button">5</button>
      <button type="button">6</button>
      <button type="button" style="grid-column: 1">1</button>
      <button type="button">2</button>
      <button type="button">3</button>
      <button type="button" style="grid-column: 1 / span 2">0</button>
      {#if !relative && $is12HourClock}
        <button type="button" data-fn="ampm" style="padding: 5px; font-size: 1.6rem">AM/PM</button>
      {/if}
    </div>
  </div>
</Dialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .time-container {
    display: flex;
    flex-direction: column;
    padding: $dialog-padding;
    font-family: hmiFont;
    min-width: 35rem;
  }

  .info {
    font-size: 70%;
    margin-inline: $dialog-padding;
    padding-bottom: 10px;
  }

  .display {
    display: grid;
    grid-template-columns: auto 1fr auto;
    margin-bottom: $dialog-padding;
    padding-inline: 1rem;
    background-color: $background;
    gap: 0.5rem;

    .range {
      margin-block: auto;
      font-family: hmiFont;
      font-size: 70%;
      text-align: center;
      line-height: 1.5;
      opacity: 0.7;
      :first-child {
        border-bottom: 1px solid $primary-dimmed;
      }
    }
    .value {
      font-family: hmiFontBold;
      font-size: 220%;
      text-align: right;
    }

    .cycle {
      width: 4rem;
      text-align: right;
      font-family: hmiFont;
      margin-top: auto;
      margin-bottom: 0.75rem;
    }
  }

  .keys {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--keys-gap);
    * {
      text-align: center;
      min-width: var(--keys-size);
      height: var(--keys-size);
      padding: $dialog-padding;
      font-size: inherit;
    }

    :global(button > *) {
      margin-inline: auto;
      pointer-events: none;
    }
  }
</style>
