<script lang="ts">
  import Button from "@/lib/Button.svelte";
  import { fullScreenComponent } from "@/stores";

  function close() {
    $fullScreenComponent = null;
  }
</script>

<section>
  <h1>First time setup</h1>

  <div class="buttons">
    <Button on:click={close}>Back</Button>
  </div>
</section>

<style lang="scss">
  section {
    --full-width: calc(100% + var(--menu-width));

    width: var(--full-width);
    height: 100%;
    font-size: 2.5rem;
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
</style>
