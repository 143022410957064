<!-- https://api.iconify.design/mingcute:sleep-line.svg -->
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...$$props}>
  <g fill="none" fill-rule="evenodd">
    <path
      d="M24 0v24H0V0zM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036c-.01-.003-.019 0-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z"
    />
    <path
      fill="currentColor"
      d="M12 4a1 1 0 0 0 1 1h4.586l-5.293 5.293A1 1 0 0 0 13 12h7a1 1 0 1 0 0-2h-4.586l5.293-5.293A1 1 0 0 0 20 3h-7a1 1 0 0 0-1 1m-8 6a1 1 0 1 1 0-2h5a1 1 0 0 1 .707 1.707L6.414 13H9a1 1 0 1 1 0 2H4a1 1 0 0 1-.707-1.707L6.586 10zm8 7a1 1 0 1 1 0-2h4a1 1 0 0 1 .707 1.707L14.414 19H16a1 1 0 1 1 0 2h-4a1 1 0 0 1-.707-1.707L13.586 17z"
    />
  </g>
</svg>
