<script lang="ts">
  import dialog from "@/dialog";
  import { client } from "@/hmi";
  import { fullDateTimeFmt } from "@/stores";
  import { isArray } from "lodash-es";
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import ConfigDialog from "../ConfigDialog.svelte";

  let selectedIndex = -1;
  let events: DTO.Event[] = [];
  let dlg: ConfigDialog;

  onMount(() => {
    let [result, stop] = client.stream<DTO.Event[] | DTO.Event>(
      "StreamEvents",
      (v) => {
        if (isArray(v)) {
          // NOTE: Reverse the array so events with the same timestamp appear in correct order
          events = v.reverse();
        } else if (v) {
          events.push(v);
          events = events; // Trigger update
        }
      },
      0 // Start from the beginning
    );
    result.catch((e) => {
      dialog.message($_("logs") + " " + $_("error"), e.message);
      dlg.close();
    });

    return stop;
  });
</script>

<ConfigDialog bind:this={dlg} title={$_("logs")}>
  <div class="logs-list">
    <div class="scrollable">
      <table>
        <thead>
          <tr>
            <td>Timestamp</td>
            <td>Type</td>
            <td>Text</td>
          </tr>
        </thead>
        {#each { length: events.length } as _, index}
          <!-- Show list in reverse (newest on top) -->
          {@const i = events.length - 1 - index}
          {@const event = events[i]}
          <tr class:selected={selectedIndex === i} on:click={() => (selectedIndex = i)}>
            <td>{$fullDateTimeFmt(event.ts * 1000)}</td>
            <td>{event.ty}</td>
            <td>{event.d || ""}</td>
          </tr>
        {/each}
      </table>
    </div>
  </div>
</ConfigDialog>

<style lang="scss">
  @use "../../styles/variables.scss" as *;

  .logs-list {
    width: 100%;
    height: 100%;
    max-height: 100%;
    font-family: hmiFont;
    font-size: 2.2rem;
  }

  .scrollable {
    flex: 1;
    overflow-y: scroll;
    scrollbar-gutter: stable;
    width: 100%;
    height: 100%;
    max-height: 100%;
  }

  table {
    width: 100%;
    white-space: nowrap;
    table-layout: auto;
    border-collapse: collapse;

    thead {
      font-family: hmiFontBold;
      color: $company;
    }

    tr {
      border-bottom: 1px solid $primary-dimmed;
      &.selected {
        background-color: $primary-dimmed;
      }
      td {
        padding-left: $dialog-padding;
        &:nth-child(2) {
          padding-left: 2rem;
          padding-right: 2rem;
          &::first-letter {
            text-transform: uppercase;
          }
        }
        &:last-child {
          white-space: normal;
          width: 100%;
          padding-right: $dialog-padding;
        }
      }
    }
  }
</style>
