<script lang="ts">
  import { api } from "@/api";
  import hmi, { config } from "@/hmi";
  import SteamPotStatusPage from "@/lib/SteamPotStatusPage.svelte";
  import TrendGraph from "@/modes/TrendGraph.svelte";
  import { proofingProgram, usesFarenheight } from "@/stores";
  import AirTreatmentPage from "./AirTreatmentPage.svelte";
  import Frame from "./Frame.svelte";
  import Swiper from "./Swiper.svelte";
  import TempAndHumidityPage from "./TempAndHumidityPage.svelte";

  const s_setpointT = hmi.getValueStore("set.proofing.T");
  const s_setpointH = hmi.getValueStore("set.proofing.H");

  function onUpdateSetpoint(e: CustomEvent<{ setpointT: number; setpointH: number }>) {
    if ($s_setpointT === e.detail.setpointT && $s_setpointH === e.detail.setpointH) return;
    s_setpointT.set(e.detail.setpointT);
    s_setpointH.set(e.detail.setpointH);
    api.storeSetpointsInProgram($proofingProgram, e.detail.setpointT, e.detail.setpointH);
  }

  function alwaysCelcius(v: number): number {
    if (!$usesFarenheight) return v;
    return (v - 32) / 1.8; // F -> C
  }
</script>

<div class="swiper-pagination pagination-proofing" />

<Frame icon="/img/menu-ikon-raskning.svg" program={$proofingProgram}>
  <section>
    <Swiper paginationClass="pagination-proofing" virtual let:curSlide>
      <swiper-slide>
        <TempAndHumidityPage
          mode="proofing"
          setpointT={$s_setpointT || 0}
          setpointH={$s_setpointH || 0}
          TRange={$config?.proofing.Trange}
          HRange={$config?.proofing.Hrange}
          on:update={onUpdateSetpoint}
        />
      </swiper-slide>
      <swiper-slide>
        <AirTreatmentPage slideShown={curSlide === 1} />
      </swiper-slide>
      <swiper-slide>
        <TrendGraph mode="proofing" {curSlide} />
      </swiper-slide>
      <swiper-slide>
        <SteamPotStatusPage slideShown={curSlide === 3} />
      </swiper-slide>
    </Swiper>
  </section>
</Frame>

<style lang="scss">
  section {
    height: 100%;
  }
</style>
