<script lang="ts">
  import defs from "@/config/equipment/equipment-defs.json";
  import hmi, { client } from "@/hmi";
  import Led from "@/lib/Led.svelte";
  import { numFmt } from "@/stores";
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import type { Unsubscriber } from "svelte/store";
  import ConfigDialog from "../ConfigDialog.svelte";

  type Digital = { label?: string; id: string };
  type Analog = { type?: string; label?: string; unit?: string; id: string };

  const flexIOInfo = hmi.getObjectStore<DTO.FlexIOInfo>("FlexIOInfo");

  let config: DTO.EquipmentConfig;
  let inputs: Digital[];
  let outputs: Digital[];
  let analogs: Analog[];
  let inputBits = 0;
  let outputBits = 0;
  let analogValues = new Array<number>(10);
  let rawValues = new Array<number>(10);

  onMount(() => {
    const unsub: Unsubscriber[] = [];

    client
      .invoke<DTO.EquipmentConfig>("Load", "equipment")
      .then((x) => {
        config = x;

        inputs = buildDigital("DI", unsub, (bit, value) => {
          if (value) inputBits |= 1 << bit;
          else inputBits &= ~(1 << bit);
        });
        outputs = buildDigital("DO", unsub, (bit, value) => {
          if (value) outputBits |= 1 << bit;
          else outputBits &= ~(1 << bit);
        });
        analogs = buildAnalog(unsub, (n, value, isRaw) => {
          if (isRaw) {
            rawValues[n] = value;
            rawValues = rawValues; // Trigger update
          } else {
            analogValues[n] = value;
            analogValues = analogValues; // Trigger update
          }
        });
      })
      .catch((e) => {
        alert(e);
      });

    return () => {
      unsub.forEach((x) => x());
    };
  });

  function makeLabel(id: string) {
    if (id.includes(".")) {
      let s = id.split(".");
      return $_(`defs.${s[0]}`) + " " + Number.parseInt(s[1]);
    }
    return $_(`defs.${id}`);
  }

  function buildDigital(type: string, unsub: Unsubscriber[], set: (n: number, value: boolean) => void): Digital[] {
    let a: Digital[] = [];
    for (let i = 0; i < 10; i++) {
      const mapping = config.mappings?.find((x) => x.type == type && x.num == i + 1);
      if (!mapping) {
        a.push({ id: `unused-${type}-${i}` });
        continue;
      }
      let s = mapping.id.split(".");
      const id = s[0];
      let seq = s[1];
      const def = mapping ? defs.find((x) => x.id === id) : undefined;
      if (def) {
        let hmi_id = def.hmi_id;
        if (seq && hmi_id) {
          hmi_id = `${def.hmi_id}.${seq}`;
        }
        let e = { label: makeLabel(mapping.id), id: mapping.id };
        if (hmi_id) {
          const store = hmi.getValueStore(hmi_id);
          if (!store) {
            console.warn(`No store for ${hmi_id}`);
            continue;
          }
          let ic = i;
          unsub.push(
            store.subscribe((value) => {
              set(ic, !!value);
            })
          );
        } else {
          e.label = "*" + def.text;
        }
        a.push(e);
      } else {
        a.push({ id: `unused-${type}-${i}` });
      }
    }
    return a;
  }

  function buildAnalog(unsub: Unsubscriber[], set: (n: number, value: number, isRaw: boolean) => void): Analog[] {
    const types = ["PT", "PT", "PT", "AI", "AI", "AI", "AO", "AO"];
    let a: Analog[] = [];
    let prevType = "";
    let i = 0;
    let n = 0;
    const postLoop = () => {
      if (i == 2 || i == 6) {
        i++;
        a.push({ id: `unused-${types[i]}-${i}` });
      }
      i++;
      n++;
    };
    for (let type of types) {
      if (type != prevType) {
        n = 0;
        prevType = type;
      }
      const mapping = config.mappings?.find((x) => x.type == type && x.num == n + 1);
      if (!mapping) {
        a.push({ type: `${type} ${n + 1}`, id: `unused-${type}-${i}` });
        postLoop();
        continue;
      }
      let s = mapping.id.split(".");
      const id = s[0];
      let seq = s[1];
      const def = mapping ? defs.find((x) => x.id === id) : undefined;
      if (def) {
        let hmi_id = def.hmi_id;
        if (seq && hmi_id) {
          hmi_id = `${def.hmi_id}.${seq}`;
        }
        let e = {
          type: `${type} ${n + 1}`,
          unit: def.unit,
          label: makeLabel(mapping.id),
          id: mapping.id,
        };
        if (hmi_id) {
          const store = hmi.getValueStore(hmi_id);
          if (!store) {
            console.warn(`No store for ${hmi_id}`);
            continue;
          }
          let ic = i;
          unsub.push(
            store.subscribe((value) => {
              set(ic, value, false);
            })
          );

          const rawStore = hmi_id ? hmi.getValueStore(hmi_id + "_raw") : undefined;
          if (rawStore) {
            unsub.push(
              rawStore.subscribe((value) => {
                set(ic, value, true);
              })
            );
          }
        } else {
          e.label = "--- " + def.text;
        }
        a.push(e);
      } else {
        a.push({ type: `${type} ${n + 1}`, id: `unused-${type}-${i}` });
      }

      postLoop();
    }
    return a;
  }

  function ionum(n: number) {
    return n < 10 ? `<span class="space">0</span>` + n : n.toString();
  }
</script>

<ConfigDialog title={$_("menu.io_state")} helpId="IOSTATE_INFO">
  <div class="io-state">
    {#if config}
      <div class="digital">
        <div class="header" style="grid-column: span 2">{$_("iostate.outputs")}</div>
        <div class="header pad-l">{$_("iostate.equipment")}</div>

        {#each outputs as led, i (led.id)}
          {@const opacity = led.label ? 1 : 0.3}
          <div style:opacity>{@html ionum(i + 1)}</div>
          <Led on={!!(outputBits & (1 << i))} />
          <div class="pad-l" style:opacity>{led.label || ""}</div>
        {/each}
      </div>

      <div class="digital">
        <div class="header" style="grid-column: span 2">{$_("iostate.inputs")}</div>
        <div class="header pad-l">{$_("iostate.equipment")}</div>

        {#each inputs as e, i (e.id)}
          {@const opacity = e.label ? 1 : 0.3}
          <div style:opacity>{@html ionum(i + 1)}</div>
          <Led rgb="65, 139, 243" on={!!(inputBits & (1 << i))} />
          <div class="pad-l" style:opacity>{e.label || ""}</div>
        {/each}
      </div>

      <div class="analog">
        <div class="header" style="grid-column: span 2">{$_("iostate.analog")}</div>
        <div class="header" style:grid-column="span 2"></div>
        <div class="header">{$_("iostate.equipment")}</div>

        {#each analogs as e, i (e.id)}
          {@const opacity = e.label ? 1 : 0.3}
          <div style:opacity>{e.type || ""}</div>
          {#if analogValues[i] === null}
            <div />
            <div />
          {:else}
            <div class="text-right" style:opacity>{$numFmt(analogValues[i], 1) || ""}</div>
            <div class="thin">{e.unit || ""}</div>
          {/if}
          <div class="text-right thin" style:opacity={opacity / 2}>{rawValues[i] !== undefined ? $numFmt(rawValues[i], 0) : ""}</div>
          <div style:opacity>{e.label || ""}</div>
        {/each}
      </div>

      {#if $flexIOInfo}
        <div class="flexio-info">
          <div>FlexIO version: {$flexIOInfo.version.toFixed(1)}</div>
          <div>FlexIO state: {$flexIOInfo.state.toString(2)}</div>
        </div>
      {/if}
    {/if}
  </div>
</ConfigDialog>

<style lang="scss">
  @use "../../styles/variables.scss" as *;

  .io-state {
    max-height: 100%;
    height: 100%;
    font-family: hmiFont;
    font-size: 1.8rem;
    display: grid;
    padding: 1rem;
    grid-template-columns: auto auto auto;
    align-content: start;
    gap: 1.5rem;
  }

  .header {
    margin-bottom: 2rem;
    font-family: hmiFontBold;
  }

  .pad-l {
    padding-left: 0.75rem;
  }

  .thin {
    font-family: hmiFontThin;
    font-size: 80%;
  }

  .digital,
  .analog {
    display: grid;
    align-content: start;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 2rem;
    border-radius: 1rem;
    > div {
      line-height: 2.2rem;
      min-height: 2.2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .digital {
    grid-template-columns: auto auto 1fr;
    gap: 1rem 0.5rem;
  }

  .analog {
    grid-template-columns: auto minmax(4.6rem, 5rem) auto 3.8rem 1fr;
    gap: 1rem;
  }

  .flexio-info {
    grid-column: 3;
    font-size: 1.8rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 1rem;
    border-radius: 1rem;
    margin-top: 2rem;
  }

  :global(.space) {
    opacity: 0;
  }
</style>
