<script lang="ts">
  import NumberInput from "@/lib/NumberInput.svelte";
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";
  import type { DefrostProgram } from "./defrost";

  $$restProps; // Get rid of "<xxx> was created with unknown prop" warning

  export let data: DefrostProgram;

  const dispatch = createEventDispatcher();

  $: dripTime = data.dripTime;
</script>

<div class="edit-container">
  <div class="column">
    <label>{$_("$$defrost.drain_heat")}:</label>
    <div class="flex-line">
      <NumberInput
        label={$_("$$defrost.drain_heat")}
        width="18rem"
        incdec
        step={1}
        min={0}
        max={100}
        bind:value={dripTime.drainHeat}
        hidelabel
        on:change
      />
      <span>%</span>
    </div>

    <label>{$_("$$defrost.drip_tray_heat")}:</label>
    <div class="flex-line">
      <NumberInput
        label={$_("$$defrost.drip_tray_heat")}
        width="18rem"
        incdec
        step={1}
        min={0}
        max={100}
        bind:value={dripTime.dripTrayHeat}
        hidelabel
        on:change
      />
      <span>%</span>
    </div>

    <hr />

    <label>{$_("$$defrost.time")}:</label>
    <div class="flex-line">
      <NumberInput
        label={$_("$$defrost.time")}
        width="18rem"
        incdec
        step={1}
        min={0}
        max={60}
        digit
        bind:value={dripTime.time}
        hidelabel
        on:change
      />
      <span>{$_("$$short.minute")}</span>
    </div>
  </div>
</div>

<style lang="scss">
  label {
    white-space: nowrap;
  }

  .column {
    height: 100%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 2rem;
    align-content: start;
    align-items: center;
  }
</style>
