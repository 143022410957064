<script lang="ts">
  import { debounce, upperFirst } from "lodash-es";
  import { onDestroy } from "svelte";
  import { _ } from "svelte-i18n";

  import { api, type ProgramTypeNames } from "@/api";
  import dialog from "@/dialog";
  import { DEFAULT_DEBOUNCED_SAVE_MS, programs } from "@/hmi";
  import { GridListView } from "@/ui";
  import { dateNow, formatDateTime, showDialog } from "@/utils";

  import Dialog from "@/dialog/Dialog.svelte";
  import Button from "@/lib/Button.svelte";
  import CopyProgramDialog from "./CopyProgramDialog.svelte";
  import NewProgramDialog from "./NewProgramDialog.svelte";
  import ProgramDetails from "./ProgramDetails.svelte";

  import CopyIcon from "@/svg/CopyIcon.svelte";
  import PlusIcon from "@/svg/PlusIcon.svelte";
  import TrashIcon from "@/svg/TrashIcon.svelte";

  export let type: ProgramTypeNames;
  export let selected: string = undefined;

  const debouncedSave = debounce(save, DEFAULT_DEBOUNCED_SAVE_MS);

  let dlg: Dialog;
  let selectedItem: ListItem = undefined;
  let result: boolean | null = null;
  let programItems: ListItem[] = [];
  let selectedProgramData: DTO.GenericProgram = undefined;
  let loadedProgram: string = undefined;

  programItems = $programs[type]?.programs?.map((x) => ({ value: x.slot, text: x.name })) ?? [];
  if (selected) selectedItem = programItems.find((x) => x.value === selected);
  if (!selectedItem && programItems.length > 0) {
    select(programItems[0].value);
  }
  if (selectedItem) {
    select(selectedItem.value);
  }

  onDestroy(() => {
    debouncedSave.flush();
  });

  function select(program: string) {
    selectedItem = programItems.find((x) => x.value === program);
    if (!selectedItem) return;
    api.loadProgram<DTO.GenericProgram>(type, selectedItem.value).then((p) => {
      selectedProgramData = p;
      loadedProgram = selectedItem.value;
    });
  }

  async function addNew() {
    debouncedSave.flush(); // Save any pending changes

    try {
      // Let user select a preset and a target program slot
      let r = (await showDialog(NewProgramDialog, { type, title: `${$_("new_")} ${$_("program")}`, programsList: programItems })) as {
        preset: string;
        targetProgram: string;
      };
      if (!r) return;

      if (r.preset === "_") r.preset = "";
      let p = await api.newProgram(type, r.preset);
      p.name = r.preset !== "_" ? r.preset : "";
      if (r.preset) {
        p.based_on = r.preset + " (preset)";
      }

      // Insert the preset into the target program slot and save
      // FIXME: For now we just create a copy of defaultAutoProgram
      //let p = await client.invoke<AutoProgram>("LoadAutoPreset", r.preset);
      //p.based_on = r.preset;

      let ok = await api.saveProgram(type, r.targetProgram, p);
      if (!ok) return;

      let item = { value: r.targetProgram, text: r.preset };
      programItems = [...programItems, item];
      loadedProgram = item.value;
      selectedItem = programItems.find((x) => x.value === loadedProgram);
      console.assert(selectedItem !== undefined, "selectedItem is undefined");
      selectedProgramData = p;
    } catch (e) {
      dialog.message($_("error"), e.message);
    }
  }

  async function deleteProgram() {
    if (!(await dialog.confirm(`${$_("delete")} ${$_("program")}<br/>${selectedItem.text}?`))) return;
    let ok = await api.deleteProgram(type, selectedItem.value);
    if (!ok) return;
    programItems = programItems.filter((x) => x.value !== selectedItem.value);
    loadedProgram = undefined;
    select(undefined);
  }

  async function copyProgram() {
    debouncedSave.flush(); // Save any pending changes

    // Let user select a target program slot
    let r = (await showDialog(CopyProgramDialog, { type, title: `${$_("copy")} ${selectedItem.text}`, programsList: programItems })) as {
      targetProgram: string;
    };
    if (!r) return;

    // Load the selected program and save it to the target slot
    let p = await api.loadProgram<DTO.GenericProgram>(type, selectedItem.value);
    p.name = "";
    p.comments = upperFirst($_("copy_of")) + " " + selectedItem.text + " " + formatDateTime(dateNow());
    let ok = await api.saveProgram(type, r.targetProgram, p);
    if (!ok) return;

    let item = { value: r.targetProgram, text: p.name };
    programItems = [...programItems, item];
    selectedItem = item; // This will trigger a reload of the program data
    selectedProgramData = p;
    loadedProgram = selectedItem.value;
  }

  function onChange() {
    debouncedSave();
    selectedProgramData = selectedProgramData; // Force update
  }

  function onNameChange(e: CustomEvent<{ slot: string; name: string }>) {
    let { slot, name } = e.detail;
    let item = programItems.find((x) => x.value === slot);
    if (!item) return;
    item.text = name;
    programItems = programItems; // Trigger reactivity
    onChange();
  }

  function save() {
    if (!loadedProgram || !selectedProgramData) return;
    api.saveProgram(type, loadedProgram, selectedProgramData);
  }

  function onItemSelect(e: CustomEvent<ListItem>) {
    debouncedSave.flush(); // Save any pending changes
    select(selectedItem?.value);
  }
</script>

<Dialog title={$_("program_manager") + " (" + type + ")"} returnValue={result} bind:this={dlg}>
  <div class="body">
    <div class="programs">
      <h1>{$_("program")}:</h1>
      <GridListView
        items={programItems}
        bind:selected={selectedItem}
        on:select={onItemSelect}
        rowSelected={(x) => selectedItem && x?.value === selectedItem.value}
      >
        <svelte:fragment let:item>
          {item.value}: {item.text}
        </svelte:fragment>
      </GridListView>
    </div>
    <div class="divider" />
    <div class="details">
      {#if selectedItem && selectedProgramData}
        <ProgramDetails slot={selectedItem.value} data={selectedProgramData} on:change={onChange} on:nameChange={onNameChange} />
      {/if}
    </div>
  </div>
  <div slot="actions" class="button-group pull-right">
    <div class="button-group" style:--text-color="var(--clr-primary)">
      <Button naked on:click={addNew}><PlusIcon /></Button>
      <Button naked disabled={!selectedItem || programItems.length >= 99} on:click={copyProgram}><CopyIcon /></Button>
      <Button naked disabled={!selectedItem || programItems.length < 2} on:click={deleteProgram}><TrashIcon /></Button>
    </div>
    <Button on:click={() => dlg.close({ lastSelectedSlot: selectedItem?.value })}><span class="first-upper">{$_("close")}</span></Button>
  </div>
</Dialog>

<style lang="scss">
  @use "../../../styles/variables.scss" as *;

  .body {
    padding: $dialog-padding;
    font-family: hmiFont;
    display: grid;
    grid-template-columns: 30rem auto 35rem;
    gap: 1rem;

    .programs {
      display: grid;
      grid-template-rows: auto max(32rem) auto;
      gap: 1rem;
    }

    .divider {
      border-right: 1px solid $primary-dimmed;
    }
  }
</style>
