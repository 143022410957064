<script lang="ts">
  import ListIcon from "@/svg/ListIcon.svelte";
  import { Events, eventBus, measureText } from "@/utils";
  import { createEventDispatcher, onMount } from "svelte";
  import { quintOut } from "svelte/easing";
  import { fade } from "svelte/transition";
  import CheckBox from "./CheckBox.svelte";

  export let label = "";
  export let value: number;
  export let items: ListItem[];
  export let width = "15rem";
  export let inline = false;
  export let disabled = false;

  let ref: HTMLElement;
  let refList: HTMLElement;
  let fire = createEventDispatcher();
  const id = Math.random().toString(36).slice(2, 9);
  let showList = false;
  let popupPlacement: string;
  let listWidth: number;

  $: if (refList) {
    ensureItemsOnScreen();
  }

  onMount(() => {
    const closePopup = () => {
      showList = false;
    };
    eventBus.on(Events.inputIdle, closePopup);
    return () => {
      eventBus.detach(Events.inputIdle, closePopup);
    };
  });

  function onListItemKeyDown(e: KeyboardEvent) {
    if (e.key === "Escape") {
      showList = false;
    }
  }

  function onBlur(e: FocusEvent) {
    // If focus is moving to a child of the list, don't close the list
    if (e.relatedTarget !== ref && ref.contains(e.relatedTarget as Node)) return;
    showList = false;
  }

  function adjustItemsListHeight() {
    if (!refList?.firstChild) return;

    //let parentHeight = ref.offsetParent?.clientHeight ?? 0;
    let parentHeight = document.body.clientHeight ?? 0;
    let itemHeight = (refList.firstChild as HTMLDivElement).clientHeight + 2;
    let top = refList.offsetParent?.getBoundingClientRect().bottom ?? 0;
    let availableHeight = parentHeight - top;
    let displayItems = Math.min(refList.children.length, Math.max(2, Math.floor(availableHeight / itemHeight)));

    // Can we fit all or at least 2 list items below?
    if (availableHeight >= itemHeight * Math.min(2, displayItems)) {
      // ...yes, we can. Place below and down
      refList.style.top = "100%";
      refList.style.bottom = "auto";
      refList.style.maxHeight = `${displayItems * itemHeight}px`;
      popupPlacement = "below";
    } else {
      // ...no. Place popup over and up
      availableHeight = ref.offsetParent.clientTop - refList.offsetParent?.getBoundingClientRect().top;
      displayItems = Math.min(refList.children.length, Math.max(2, Math.floor(availableHeight / itemHeight)));
      let maxItems = Math.floor(availableHeight / itemHeight);
      refList.style.top = "auto";
      refList.style.bottom = "0";
      refList.style.maxHeight = `${maxItems * itemHeight}px`;
      popupPlacement = "above";
    }
  }

  function ensureItemsOnScreen() {
    let w = ref.offsetParent.clientWidth;
    let lr = refList.getBoundingClientRect();
    if (lr.x + lr.width > w + 15) {
      refList.style.left = "unset";
      refList.style.right = "-2px";
    }
  }

  function calculateNeededWidth(): number {
    console.assert(ref, "ref is not set");
    let strings = items.map((i) => i.text);
    let widths = measureText(window.getComputedStyle(ref).font, strings) as number[];
    return Math.max(ref.clientWidth, ...widths);
  }

  function ensureSelectedItemVisible() {
    let selected = refList?.querySelector(".selected");
    if (selected) selected.scrollIntoView({ behavior: "instant", block: "nearest" });
  }

  $: if (refList) {
    ensureSelectedItemVisible();
    adjustItemsListHeight();
  }

  function absorb(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  function onItemCheckChanged(on: boolean, item: ListItem) {
    if (on) {
      if (item.exclusive) value = item.value;
      else if (item.group) {
        // Uncheck all items in the group and check the clicked item
        let groupItems = items.filter((i) => i.group === item.group);
        let groupValue = groupItems.reduce((acc, i) => acc | i.value, 0);
        value &= ~groupValue;
        value |= item.value;
      } else value |= item.value;
    } else {
      if (item.exclusive) value = 0;
      else value &= ~item.value;
    }
    fire("change", value);
  }

  $: exclusiveTextLine = items.find((i) => i.exclusive && value === i.value)?.text;

  $: textLine = items
    .filter((x) => !x.exclusive)
    .filter((i) => (value & i.value) === i.value)
    .map((i) => i.text)
    .join(", ");

  // Return true if item value is exclusive and matches the current value exactly or if the item value is included in the current value
  function isChecked(item: ListItem) {
    if (item.exclusive) return value === item.value;
    return (value & item.value) === item.value;
  }
</script>

<!--
  transition:slide={{ duration: 200, axis: "y", easing: quintOut }}
-->

<div bind:this={ref} class="list-input-container" class:inline class:disabled>
  {#if label}
    <label for={id}>{label}:</label>
  {/if}
  <div
    {id}
    tabindex="-1"
    class="list-input"
    class:pos-above={showList && popupPlacement === "above"}
    class:pos-below={showList && popupPlacement === "below"}
    class:inline
    style:width
    on:click|stopPropagation={() => (showList = !showList)}
    on:pointerdown={absorb}
    on:pointerup={absorb}
    class:popup-active={showList}
  >
    <span>{exclusiveTextLine ?? textLine}</span>
    {#if !inline}
      <ListIcon size="80%" />
    {/if}

    {#if showList}
      <ul
        bind:this={refList}
        bind:clientWidth={listWidth}
        class="list-items li-scroll-align"
        tabindex="-1"
        autofocus
        on:keydown={onListItemKeyDown}
        on:blur={onBlur}
        transition:fade={{ duration: 200, easing: quintOut }}
      >
        {#each items as item}
          <li class="list-item" on:click={absorb} on:click={(e) => onItemCheckChanged(!isChecked(item), item)}>
            {#key value}
              <CheckBox
                checked={isChecked(item)}
                label={item.text}
                on:change={(e) => onItemCheckChanged(e.detail, item)}
                --text-color={item.exclusive ? "var(--clr-company)" : undefined}
              />
            {/key}
          </li>
        {/each}
      </ul>
    {/if}
  </div>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .list-input-container {
    --height: var(--controls-height);
    display: flex;
    align-items: center;
    gap: 1rem;

    &:not(.inline) {
      font-size: 2.2rem;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .list-input {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 2px;
    align-items: center;
    transition: color 200ms ease;

    background-color: var(--clr-input-field-background);

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:not(.inline) {
      height: var(--height);
      padding: 0.5rem 1rem;
      border-radius: calc(var(--height) / 8);
      border: 2px solid $primary-dimmed;
    }

    :global(svg) {
      transition: color 200ms ease;
      color: $primary-dimmed;
    }

    &:not(.inline) {
      &:active,
      &.popup-active {
        // border: 2px solid $company;
        :global(svg) {
          color: $primary;
        }
      }
      &.popup-active {
        color: $primary-dimmed;
        &.pos-below {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
        &.pos-above {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
    &.inline {
      &:active,
      &.popup-active {
        outline: 2px dotted $company;
      }
    }
  }

  .list-items {
    position: absolute;
    left: -2px;

    font-size: 2.6rem;

    min-width: calc(100% + 4px);
    max-width: 50vw;
    width: auto;
    height: auto;

    z-index: 10000;

    color: $primary;
    background-color: $menu-background;
    max-height: 10rem;
    overflow-y: auto;

    border: 2px solid $primary-dimmed;
    // box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.3);

    li {
      padding: 1rem;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      & + li {
        border-top: 2px solid $primary-dimmed;
      }
    }
  }
</style>
