<script lang="ts">
  import { secondsTicker, timezone } from "@/stores";
  import { dateNow, getMonthNames } from "@/utils";
  import moment from "moment-timezone";
  import { locale } from "svelte-i18n";
  import ListWheel from "./ListWheel.svelte";
  import NumberWheel from "./NumberWheel.svelte";

  export let date: moment.Moment;
  export let changing = false;

  let hour: number;
  let minute: number;
  let second: number;
  let year: number;
  let month: number;
  let day: number;

  let is24HourClock: boolean;
  let isDayFirst: boolean;
  let am_pm: number;
  let monthNames: string[];

  let timeDivider = ":";
  let dateDivider = "";

  $: if ($locale) monthNames = getMonthNames($locale, "short");
  $: if ($timezone) changing = false;
  $: if ($locale) {
    // Determine if the locale uses 24 hour time and if the day comes before the month
    // NOTE: I don't know how to to this with moment.js, so we have to use the Intl API

    let localeName = $locale;
    if (!localeName || localeName === "DEV") localeName = "en";
    let parts = Intl.DateTimeFormat(localeName, {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      day: "numeric",
      month: "numeric",
      year: "numeric",
    }).formatToParts(dateNow());

    is24HourClock = parts.findIndex((p) => p.type === "dayPeriod") === -1;
    isDayFirst = parts.findIndex((p) => p.type === "day") < parts.findIndex((p) => p.type === "month");
  }

  let ref: HTMLElement;
  let blinkAnimation: Animation;

  $: if (ref) {
    $secondsTicker;
    // NOTE: Animating CSS variables is not supported in firefox as of 2021-10-13!
    blinkAnimation?.finish();
    blinkAnimation = ref.animate([{ "--clock-div-opacity": "1" }, { "--clock-div-opacity": "0" }], {
      duration: 1000,
      iterations: 1,
    });
  }

  $: if (!changing) {
    // Extract the values
    hour = date.hour();
    if (!is24HourClock) hour = hour % 12 || 12;
    minute = date.minute();
    second = date.second();
    year = date.year();
    month = date.month();
    day = date.date();
    am_pm = date.format("A") === "PM" ? 1 : 0;
  } else {
    // Update the date with the new values
    let h = hour;
    if (!is24HourClock) {
      if (h === 12) h = 0;
      if (am_pm === 1) h += 12;
    }
    // Make sure the day is valid for the month
    day = Math.min(day, moment.tz({ year, month, day: 1 }, $timezone).daysInMonth());
    // Update the date with the new values
    date = moment.tz({ hour: h, minute, second, year, month, day }, $timezone);
  }
</script>

<div class="input-container" bind:this={ref}>
  <div class="part">
    <div>TIME</div>
    <div class="wheels">
      <!-- This key forces the components to re-render when the timezone changes -->
      {#key $timezone}
        <NumberWheel bind:value={hour} min={is24HourClock ? 0 : 1} max={is24HourClock ? 23 : 12} bind:changing wrap />
        <div class="divider" class:show={changing}>{timeDivider}</div>
        <NumberWheel bind:value={minute} min={0} max={59} bind:changing wrap />
        <div class="divider" class:show={changing}>{timeDivider}</div>
        <NumberWheel bind:value={second} min={0} max={59} bind:changing wrap />
        {#if !is24HourClock}
          <span class="divider" />
          <ListWheel bind:selected={am_pm} values={["AM", "PM"]} bind:changing />
        {/if}
      {/key}
    </div>
  </div>
  <div class="part">
    <div>DATE</div>
    <div class="wheels">
      <!-- This key forces the components to re-render when the timezone changes -->
      {#key $timezone}
        <!-- Month and day are reversed in some locales -->
        {#if isDayFirst}
          <NumberWheel bind:value={day} min={1} max={date.daysInMonth()} bind:changing wrap />
          <span class="divider">{dateDivider}</span>
          <ListWheel bind:selected={month} values={monthNames} bind:changing />
          <span class="divider">{dateDivider}</span>
          <NumberWheel bind:value={year} min={2022} max={2100} bind:changing />
        {:else}
          <!-- <NumberWheel bind:value={month} min={1} max={12} bind:changing /> -->
          <ListWheel bind:selected={month} values={monthNames} bind:changing />
          <span class="divider">{dateDivider}</span>
          <NumberWheel bind:value={day} min={1} max={date.daysInMonth()} bind:changing wrap />
          <span class="divider">{dateDivider}</span>
          <NumberWheel bind:value={year} min={2022} max={2100} bind:changing />
        {/if}
      {/key}
    </div>
  </div>
</div>

<div class="locale">{$locale}</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .input-container {
    display: flex;
    flex-direction: row;
    gap: 30px;

    .part {
      text-align: center;
      color: $company;
      font-family: hmiFontBold;
      .wheels {
        color: $primary;
        display: flex;
        flex-direction: row;
        padding: 10px;
        position: relative;
      }
    }

    .divider {
      font-family: hmiFontBold;
      opacity: 0;
      width: 20px;
      margin: auto 0;
      overflow: visible;
      opacity: var(--clock-div-opacity, 1);
      &.show {
        opacity: 1;
      }
    }
  }

  .locale {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 1.6rem;
    opacity: 0.5;
  }
</style>
