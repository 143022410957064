<script lang="ts">
  import { api } from "@/api";
  import { uiPrompt } from "@/hmi";
  import Button from "@/lib/Button.svelte";
  import { dateNow } from "@/utils";
  import { onDestroy } from "svelte";
  import { _ } from "svelte-i18n";
  import StartAutoProgramPrompt from "./StartAutoProgramPrompt.svelte";

  export let promptOpen = false;

  let uiPromptTimer: number | null = null;
  let countdownValue = 0;

  onDestroy(killTimer);

  function sendUIPromptResult(choice: number) {
    if (!$uiPrompt?.length) return;
    let p = $uiPrompt[0];
    api.postUIPromptResult(p.group, p.id, choice);
    killTimer();
  }

  function killTimer() {
    if (uiPromptTimer) {
      clearInterval(uiPromptTimer);
      uiPromptTimer = null;
      countdownValue = 0;
    }
  }

  $: {
    $uiPrompt;
    killTimer();
    promptOpen = !!$uiPrompt?.length;
  }

  function timerCallbask() {
    countdownValue--;
    if (countdownValue <= 0) {
      killTimer();
    }
  }

  function formatDuration(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 24) return ">24h";
    let result: string[] = [];
    if (hours > 0) result.push(hours + "h");
    if (minutes > 0) result.push(minutes + "m");
    if (remainingSeconds > 0 || result.length === 0) result.push(remainingSeconds + "s");
    return result.join(" ");
  }

  function replaceBracketsWithArgs(str: string, args: any[]) {
    let i = 0;
    return str.replace(/{(.*?)}/g, (r) => {
      if (!args || i >= args.length) return "?" + i++;
      let arg = args[i++];
      switch (r) {
        default:
          return arg;
        case "{:duration}":
          return formatDuration(arg);
      }
    });
  }

  $: if ($uiPrompt?.length) {
    let p = $uiPrompt[0];
    if (p.due) {
      countdownValue = Math.floor(p.due - dateNow().getTime() / 1000);
      uiPromptTimer = window.setInterval(timerCallbask, 1000);
    }
  }
</script>

{#if $uiPrompt?.length}
  {@const p = $uiPrompt[0]}
  {#if p.id === "update_complete"}
    <div class="popup prompt">
      <p>
        {@html replaceBracketsWithArgs($_("prompts.update_complete"), p.args)}
      </p>
      <div class="button-group center">
        <Button
          minWidth="13rem"
          on:click={() => {
            sendUIPromptResult(0);
            location.reload();
          }}
        >
          {$_("ok")}
          {#if countdownValue}
            ({countdownValue})
          {/if}
        </Button>
      </div>
    </div>
  {:else if p.id === "start_auto_program"}
    <StartAutoProgramPrompt prompt={p} />
  {:else}
    <div class="popup prompt">
      <p>
        {@html replaceBracketsWithArgs($_(p.id), p.args)}
      </p>
      {#if p.choices}
        <div class="button-group center">
          {#each p.choices as choice, i}
            <Button minWidth="13rem" on:click={() => sendUIPromptResult(i)}>
              {$_(choice)}
              {#if countdownValue && i === p.default}
                ({countdownValue})
              {/if}
            </Button>
          {/each}
        </div>
      {/if}
    </div>
  {/if}
{/if}

<style>
  p {
    text-wrap: balance;
  }
</style>
