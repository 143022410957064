<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let checked: boolean = false;
  export let disabled: boolean = false;
  export let height: string = "calc(var(--controls-height) * 0.8)";
  export let label: string = undefined;
  export let labelSide: "left" | "right" = "right";

  const dispatch = createEventDispatcher();

  function onClick() {
    if (!disabled) {
      checked = !checked;
      dispatch("change", checked);
    }
  }
</script>

<div class="wrapper" style="--height: {height}" on:click|stopPropagation={onClick} class:checked class:disabled tabindex="-1">
  {#if label && labelSide === "left"}
    <span class="label clickable">{label}</span>
  {/if}

  <!-- https://api.iconify.design/mingcute:close-fill.svg -->
  <svg class="clickable checkmark" viewBox="0 0 24 24">
    {#if checked}
      <path
        fill="currentColor"
        d="m12 14.121l5.303 5.304a1.5 1.5 0 0 0 2.122-2.122L14.12 12l5.304-5.303a1.5 1.5 0 1 0-2.122-2.121L12 9.879L6.697 4.576a1.5 1.5 0 1 0-2.122 2.12L9.88 12l-5.304 5.303a1.5 1.5 0 1 0 2.122 2.122L12 14.12Z"
      />
    {/if}
  </svg>
  {#if label && labelSide === "right"}
    <span class="label">{label}</span>
  {/if}
  <slot />
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .wrapper {
    display: inline-flex;
    vertical-align: middle; // Override default baseline alignment for inline-flex
    gap: 1rem;
    align-items: center;
    height: var(--height);
    width: fit-content;

    &:active svg {
      background-color: $primary-dimmed;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    // Limit height of images in the slot
    // NOTE: Use :is to work around ":global(...) must contain a single selector" error
    :global(:is(img, svg)) {
      max-height: 90%;
    }

    svg {
      background-color: var(--clr-input-field-background);
      max-height: 100%;
      border-radius: calc(var(--height) / 6);
      border: 2px solid $primary-dimmed;
      width: var(--height);
      min-width: var(--height);
      display: inline;
      pointer-events: none;
    }

    .label {
      color: var(--text-color, $primary);
      white-space: nowrap;
    }
  }
</style>
