<script lang="ts">
  import { api } from "@/api";
  import ConfigDialog from "@/config/ConfigDialog.svelte";
  import dialog from "@/dialog";
  import hmi, { client, programs } from "@/hmi";
  import CheckBox from "@/lib/CheckBox.svelte";
  import ListInput from "@/lib/ListInput.svelte";
  import TimeInput from "@/lib/TimeInput.svelte";
  import { dateFmt } from "@/stores";
  import SkipIcon from "@/svg/SkipIcon.svelte";
  import { Events, dateNow, eventBus, getDayNames } from "@/utils";
  import { debounce, isEqual, upperFirst } from "lodash-es";
  import { onDestroy } from "svelte";
  import { _, locale } from "svelte-i18n";

  const autoStatus = hmi.getObjectStore<DTO.AutoStatus>("AutoStatus");

  let debouncedSave = debounce(save, 200);
  let activeProgramIndex = -1;

  onDestroy(() => {
    debouncedSave.flush();
    eventBus.emit(Events.reloadAutoProgram);
  });

  let canRevert = false;
  let dataBackup: DTO.SimpleSchedule = undefined;
  const debounceUpdateRevert = debounce(() => {
    canRevert = dataBackup && !isEqual(dataBackup, data);
  }, 100);

  $: dayNames = getDayNames($locale).map((d) => upperFirst(d));
  $: autoPrograms = [{ value: "", text: "OFF" }].concat(
    $programs?.auto?.programs?.map((p) => ({ value: p.slot, text: `${p.slot}: ${p.name}` })) ?? []
  );

  type LineData = {
    data: DTO.SimpleSchedule["days"][0];
    dayName: string;
    today?: boolean;
    active?: boolean;
    date: string;
    skipClass?: string;
    week?: number;
  };

  let data: DTO.SimpleSchedule = undefined;

  $: {
    $autoStatus;
    data = data; // Trigger reactivity
  }

  api.findAutoProgramCandidate().then((candidate) => {
    activeProgramIndex = candidate?.day;
    if (activeProgramIndex === undefined) activeProgramIndex = -1;
  });

  client
    .invoke<DTO.SimpleSchedule>("Load", "simple-schedule")
    .then((v) => {
      data = v;
    })
    .catch((e) => {
      alert(e);
    })
    .finally(() => {
      if (!data) data = {} as any;
      if (!data.days) data.days = [];
      while (data.days.length < 7) {
        data.days.push({
          type: "auto",
          program: "",
          time: 3 * 60 * 60,
          skip: false,
        });
      }
      data.days.length = 7; // Ensure length is 7 days
      dataBackup = structuredClone(data);
    });

  onDestroy(debouncedSave.flush);

  /**
   * Saves the schedule data to persistent storage.
   *
   * Uses the client API to invoke the "Save" method,
   * passing the "simple-schedule" key and current data.
   *
   * Handles any errors by showing an alert.
   */
  function save() {
    client
      .invoke("Save", "simple-schedule", data)
      .catch((e) => {
        alert(e);
      })
      .then(() => {
        api.findAutoProgramCandidate().then((candidate) => {
          activeProgramIndex = candidate?.day;
          if (activeProgramIndex === undefined) activeProgramIndex = -1;
        });
      });
  }

  /**
   * Called when any schedule data changes.
   *
   * Debounces calls to update the revert state and save the data.
   * Also triggers reactivity by assigning data to itself.
   */
  function onChange() {
    debounceUpdateRevert();
    debouncedSave();
    data = data; // Trigger reactivity
  }

  /**
   * Reverts the schedule data to the previously saved state.
   *
   * Checks if there is a data backup, confirms with the user,
   * then copies the backup data to the current data.
   *
   * Calls debouncedSave() after reverting to persist the changes.
   */
  async function revert() {
    if (!dataBackup) return;
    if (!(await dialog.confirm("Are you sure you want to revert all changes?"))) return;
    data = structuredClone(dataBackup);
    canRevert = false;
    debouncedSave();
  }

  /**
   * Sets the time value for all days in the schedule data.
   *
   * Opens a time picker dialog, and if a time is selected,
   * loops through each day in the schedule data and sets
   * the time property to the selected time.
   *
   * Calls onChange() after updating to trigger reactivity.
   */
  async function onSetTimeForAll() {
    let result = await dialog.time($_("programs.auto_schedule.time_for_all_days"), data.days[0].time, false);
    if (result !== null) {
      for (let day of data.days) {
        day.time = result;
      }
      onChange();
    }
  }

  async function onSetProgramForAll() {
    let result = await dialog.listSelect(
      $_("programs.auto_schedule.program_for_all_days"),
      autoPrograms.map((p) => p.text)
    );
    if (result !== null) {
      let slot = autoPrograms[result].value;
      for (let day of data.days) {
        day.program = slot;
      }
      onChange();
    }
  }

  function getStartOfWeek() {
    const d = dateNow();
    // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = d.getDay();

    // Calculate the difference to the previous Monday
    const diffToMonday = (dayOfWeek + 6) % 7;

    // Subtract the difference from the current date
    d.setDate(d.getDate() - diffToMonday);
    return d;
  }

  /**
   * Builds an array of LineData objects for each day of the week from the given DTO.SimpleSchedule.
   *
   * Loops through the schedule's days, generating a LineData object for each day with the schedule data,
   * day name, formatted date, and flags for today and active day. Handles wrapping to next week.
   *
   * @param schedule - The DTO.SimpleSchedule to generate line data for
   * @returns An array of LineData objects, one for each day of the week
   */
  function buildLines(schedule: DTO.SimpleSchedule): LineData[] {
    let date = getStartOfWeek();
    let lines = new Array<LineData>(7);
    let day = date.getDay();
    const today = day;
    let skipping = false;
    for (let i = 0; i < 7; i++) {
      let line: LineData = {
        data: schedule.days[day],
        dayName: dayNames[day],
        date: $dateFmt(date),
        today: i === 0,
      };

      if (line.today || day === 1) {
        line.week = getWeekNumber(date);
      }

      if (schedule.days[day].skip) {
        line.skipClass = "skip";
        skipping = true;
      } else {
        let nextDay = (day + 1) % 7;
        if (nextDay != 1 && schedule.days[nextDay].skip && nextDay !== today && !skipping) {
          line.skipClass = "skip-start";
          skipping = true;
        } else if (skipping) {
          if (nextDay != 1 && schedule.days[nextDay].skip) {
            line.skipClass = "skip-end-start";
          } else {
            line.skipClass = "skip-end ";
            skipping = false;
          }
        }
      }
      lines[day] = line;
      date.setDate(date.getDate() + 1); // move to next day
      day = (day + 1) % 7;
    }
    return [...lines.slice(1), lines[0]]; // Move Sunday to end
  }

  function getWeekNumber(d: Date) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - d.getUTCDay());
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    return Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
  }
</script>

<svelte:head>
  <link rel="preload" href="/img/schedule/skip-end-start.png" as="image" />
  <link rel="preload" href="/img/schedule/skip-end.png" as="image" />
  <link rel="preload" href="/img/schedule/skip-start.png" as="image" />
  <link rel="preload" href="/img/schedule/skip.png" as="image" />
</svelte:head>

<ConfigDialog title={$_("programs.auto_schedule.title")} helpId="AUTO_SCHEDULE" {revert} {canRevert}>
  <section>
    {#if data?.days}
      <h1>{$_("programs.auto_schedule.day")}</h1>
      <button on:click={onSetProgramForAll}>{$_("programs.auto_schedule.program")}</button>
      <button on:click={onSetTimeForAll}>{$_("programs.auto_schedule.time")}</button>
      <h1>{$_("programs.auto_schedule.date")}</h1>
      <h1><SkipIcon /></h1>
      <hr />
      {#each buildLines(data) as line, i}
        {#if line.today && i !== 0}
          <hr style:border-bottom-width="5px" />
        {/if}
        <div
          class="day-name"
          class:active={i === (activeProgramIndex || 7) - 1}
          class:dim={line.data.skip || !line.data.program}
          data-week={line.week || ""}
        >
          {line.dayName}
        </div>
        <ListInput
          items={autoPrograms}
          bind:selectedValue={line.data.program}
          width="32rem"
          disabled={line.data.skip}
          on:change={onChange}
        />
        <TimeInput bind:value={line.data.time} on:change={onChange} disabled={!line.data.program || line.data.skip} />
        <div class:active={line.active} class:dim={line.data.skip || !line.data.program}>{line.date}</div>
        <div class="skipper {line.skipClass ?? ''}">
          <CheckBox bind:checked={line.data.skip} on:change={onChange} disabled={!line.data.program} />
        </div>
      {/each}
    {/if}
  </section>
</ConfigDialog>

<style lang="scss">
  @use "../../../styles/variables.scss" as *;

  section {
    --clr-input-field-background: #{$background};

    width: 100%;
    height: 100%;
    font-size: 2.6rem;
    font-family: hmiFont;
    padding: 2rem 1rem 1rem 1rem;

    position: relative;
    display: grid;
    gap: 1rem 2rem;
    align-content: start;
    align-items: center;
    justify-content: center;
    grid-template-columns: auto auto auto auto auto;

    h1,
    button {
      font-family: hmiFontBold;
      width: min-content;
      font-size: inherit;
      line-height: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      min-width: 100%;
      overflow: visible; // Prevent text from being clipped at bottom
    }
    h1 {
      color: var(--clr-company);
    }
    button {
      padding: 0.25rem 1rem;
      text-align: left;
    }

    hr {
      grid-column: 1/-1;
    }

    .active {
      position: relative;
      text-decoration: underline;
      &::after {
        content: ">";
        position: absolute;
        left: -2rem;
      }
    }

    .day-name {
      position: relative;

      &::before {
        content: attr(data-week);
        position: absolute;
        right: calc(100% + 3rem);
        line-height: 1;
        font-family: hmiFontBold;
        font-style: italic;
        font-size: 1.2em;
        color: var(--clr-primary);
        opacity: 0.5;
      }
    }

    :global(.skipper) {
      position: relative;
    }
    :global(.skipper::after) {
      content: "";
      position: absolute;
      opacity: 0.5;
      top: 0;
      left: 110%;
      width: 150%;
      height: 150%;
      aspect-ratio: 1;
      transform: translateY(-20%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: visible;
    }

    :global(.skip-start)::after {
      background-image: url("/img/schedule/skip-start.png");
    }
    :global(.skip)::after {
      background-image: url("/img/schedule/skip.png");
    }
    :global(.skip-end-start)::after {
      background-image: url("/img/schedule/skip-end-start.png");
    }
    :global(.skip-end)::after {
      background-image: url("/img/schedule/skip-end.png");
    }
  }
</style>
