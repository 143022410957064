<script lang="ts">
  import { quintOut } from "svelte/easing";
  import { fly } from "svelte/transition";

  export let trigger = "";
  export let top: string = "auto";
  export let right: string = "auto";
  export let bottom: string = "auto";
  export let left: string = "auto";
  export let show = false;

  let ref: HTMLElement;

  let flyX = 0;
  let flyY = 0;

  if (left !== "auto" && right === "auto") flyX = -100;
  if (left === "auto" && right !== "auto") flyX = 100;

  function maybeDismiss(e: FocusEvent) {
    if (trigger && (e.relatedTarget as Element)?.classList.contains(trigger)) return;
    if (!ref.contains(e.relatedTarget as Node)) show = false;
  }
</script>

{#if show}
  <section
    bind:this={ref}
    tabindex="-1"
    autofocus
    on:focusout={maybeDismiss}
    transition:fly|local={{ duration: 200, easing: quintOut, x: flyX, y: flyY }}
    style:inset={`${top} ${right} ${bottom} ${left}`}
  >
    <slot />
  </section>
{/if}

<style lang="scss">
  @use "../styles/variables.scss" as *;

  section {
    z-index: 10;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $menu-background;
    border: 2px solid $primary-dimmed;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 1);
  }
</style>
