<script lang="ts">
  import dialog from "@/dialog";
  import Button from "@/lib/Button.svelte";
  import GridListView from "@/ui/GridListView.svelte";
  import { durationString } from "@/utils";
  import { union, upperFirst } from "lodash-es";
  import { createEventDispatcher } from "svelte";
  import { json, locale } from "svelte-i18n";

  export let value: number[];
  export let prop: PropItem;
  export let localePrefix: string | undefined = undefined;

  let locText = $json(localePrefix, $locale) ?? {};
  function T(key: string) {
    return locText[key] ?? key;
  }

  const dispatch = createEventDispatcher();

  let selected: number = undefined;

  $: dispatch("change", value);

  if (!value && prop.default !== undefined) {
    value = structuredClone(prop.default);
  }

  /**
   * Adds a time value to the defrost schedule.
   */
  async function add(): Promise<void> {
    let n = await dialog.time(T("time"), 0, false);
    if (n === null) return;
    // Remove duplicates and sort
    value = union([...value, n]).sort((a, b) => a - b);
    selected = n;
  }

  /**
   * Removes the selected item from the value array.
   */
  function remove(): void {
    value = value.filter((n) => n !== selected);
    selected = undefined;
  }
</script>

<section>
  {#if value}
    <GridListView items={value} bind:selected rowSelected={(n) => n === selected}>
      <svelte:fragment let:item>
        {durationString(item, true)}
      </svelte:fragment>
    </GridListView>
    <div class="commands">
      <Button on:click={add} disabled={value.length >= 10}>{upperFirst(T("add"))}</Button>
      <Button on:click={remove} disabled={selected === undefined || !value.includes(selected)}>{upperFirst(T("remove"))}</Button>
    </div>
  {/if}
</section>

<style lang="scss">
  section {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 2rem 1rem;
    justify-items: center;
  }

  .commands {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
</style>
