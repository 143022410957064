<script lang="ts">
  import { beforeUpdate, createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";

  import TextInput from "@/lib/TextInput.svelte";
  import type { GenericProgram } from "./manager";

  export let slot: string;
  export let data: GenericProgram;

  const dispatch = createEventDispatcher();

  beforeUpdate(() => {
    if (data) {
      data.name = data.name ?? "";
      data.comments = data.comments ?? "";
    }
  });

  function onNameChange() {
    dispatch("nameChange", { slot, name: data.name });
  }
</script>

<section>
  <div class="static first-upper">{$_("program")}: {slot}</div>
  <div class="static first-upper">{$_("based_on")}: {data.based_on ?? "-"}</div>

  <label>{$_("name")}:</label>
  <TextInput description="{$_('program')} {$_('name')}" bind:value={data.name} on:change={onNameChange} width="100%" initEmpty />

  <label>{$_("comments")}:</label>
  <TextInput description="{$_('program')} {$_('comments')}" lines={4} bind:value={data.comments} on:change width="100%" />
</section>

<style lang="scss">
  @use "../../../styles/variables.scss" as *;

  section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .static {
    opacity: 0.8;
    font-size: 80%;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  label {
    margin-top: 1rem;
    font-weight: bold;
  }
</style>
