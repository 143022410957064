<script lang="ts">
  import { autorepeat } from "@/actions";
  import { _ } from "svelte-i18n";
  import Dialog from "./Dialog.svelte";
  import keymap from "./keymap.json";

  export let title: string;
  export let value: string;
  export let info: string | undefined;
  export let max: number | undefined;

  let dlg: Dialog;
  let keysRef: HTMLDivElement | undefined;

  let result = value;
  let input: HTMLInputElement | undefined;
  let origValue = value + "_";
  let allowBackspace = value?.length > 0;
  let shift = !value;

  function onClick(event: MouseEvent) {
    if (!input || !event.target) return;
    let el = event.target as HTMLButtonElement;
    if (!el) return;
    if (el.tagName !== "BUTTON") return;
    let fn = el.getAttribute("data-fn");

    let s = input.value.slice(0, -1);

    if (fn) {
      switch (fn) {
        case "space":
          if (max !== undefined && input.value.length >= max) return;
          if (s.length !== 0) s += " ";
          break;
        case "del":
          if (s.length > 0) {
            s = s.slice(0, -1);
          }
          if (s.length === 0) {
            shift = true;
          }
          break;
        case "shift":
          shift = !shift;
          break;
      }
    } else {
      if (max !== undefined && s.length >= max) return;
      let value = el.textContent?.trim(); // For some reason we need to trim off whitespace on intl chars. Don't known why!
      s += value;
      shift = false;
    }

    if (s.length > 0) {
      allowBackspace = true;
    } else if (s.length === 0) {
      allowBackspace = false;
    }

    result = s;
    input.value = s + "_";
  }

  $: adjustCase = (c: string) => {
    if (shift) return c;
    return c.toLocaleLowerCase();
  };

  function onKeyDown(e: KeyboardEvent) {
    if (e.key === "Shift") {
      shift = true;
    } else if (e.key === "Backspace") {
      if (allowBackspace) {
        result = result.slice(0, -1);
        input.value = result + "_";
      }
    } else if (e.key === "Enter") {
      dlg.close(result);
    } else if (e.key === "Escape") {
      dlg.close(null);
    } else if (e.key.length === 1 && isKeyAllowed(e.key)) {
      if (max !== undefined && result.length >= max) return;
      if (e.key === " " && result.length === 0) return;
      result += e.key;
      input.value = result + "_";
    }
  }

  function onKeyUp(e: KeyboardEvent) {
    if (e.key === "Shift") {
      shift = false;
    }
  }

  function isKeyAllowed(key: string) {
    key = key.toLocaleUpperCase();
    return !!keysRef.querySelector(`button[data-key="${key}"]`);
  }
</script>

<Dialog bind:this={dlg} {title} returnValue={result} on:keydown={onKeyDown} on:keyup={onKeyUp}>
  <div class="keyboard-body">
    {#if info}
      <div class="info">{info}</div>
    {/if}
    <input readonly class="input" value={origValue} bind:this={input} disabled />
    <div class="keys" on:click|preventDefault={onClick} bind:this={keysRef}>
      <button type="button" style:visibility="hidden"></button>
      {#each [1, 2, 3, 4, 5, 6, 7, 8, 9, 0] as key}
        <button data-key={key}>{key}</button>
      {/each}
      {#each keymap as key}
        {#if key.startsWith("__")}
          {@const sp = `keyboard.spec_key_${key.slice(-1)}`}
          <button type="button" data-key={$_(sp)} style="visibility: {$_(sp) === ' ' ? 'hidden' : 'visible'}">
            {adjustCase($_(sp))}
          </button>
        {:else}
          <button data-key={key}>{adjustCase(key)}</button>
        {/if}
      {/each}
      <button type="button" data-fn="shift" style:grid-column="1 / span 2">Caps</button>
      <button type="button" data-key=" " data-fn="space" style:grid-column="3 / span 7">&#9251</button>
      <button type="button" data-fn="del" use:autorepeat disabled={!allowBackspace} style:grid-column="10 / span 2"> &#x232B </button>
    </div>
  </div>
</Dialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .keyboard-body {
    text-align: center;
    overflow: hidden;
    padding: $dialog-padding;
  }

  .info {
    font-family: hmiFont;
    font-size: 80%;
    //margin-inline: $dialog-padding;
    width: calc(11 * var(--keys-size) + 10 * var(--keys-gap)); // FIXME: Wow this is ugly
    padding-bottom: 1rem;
  }

  .input {
    width: 100%;
    padding: 0px;
    height: 4rem;
    text-align: center;
    opacity: 1;
    font-size: inherit;
  }

  .keys {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    gap: var(--keys-gap);
    * {
      text-align: center;
      min-width: var(--keys-size);
      min-height: var(--keys-size);
      margin-bottom: 0;
      padding: 10px;
      font-size: inherit;
    }
  }
</style>
